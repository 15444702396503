﻿/// <reference path="../main.scss" />

// ARROWS
.btn-arrows {
    display: flex;

    > * + * {
        margin-left: $spacer-med;
    }

    .swiper-button-lock {
        display: none;
    }

    .btn-arrow {
        position: relative;
        left: 0;
        right: 0;
        transform: translateY(0);
        border-radius: 0;
        background: $black;
        opacity: 1;
        height: $spacer-large;
        width: $spacer-large;
        background-image: url('../static/images/svgs/arrow-square-light.svg');

        &:hover, &:active, &:focus {
            background-image: url('../static/images/svgs/arrow-square-redwhite.svg');
        }

        &-prev {
            transform: rotate(180deg);
        }

        @include media(mobile) {
            height: $spacer * 4;
            width: $spacer * 4;
        }

        &[disabled], &[aria-disabled=true] {
            opacity: 0.4;
            cursor: not-allowed;
            pointer-events: none;
        }
    }
}

.theme-light {
    .btn-arrows .btn-arrow {
        background: $offWhite;
        background-image: url('../static/images/svgs/arrow-square-dark.svg');

        &:hover, &:active, &:focus {
            background-image: url('../static/images/svgs/arrow-square-redblack.svg');
        }
    }
}

.theme-dark {
    .btn-arrows .btn-arrow {
        background: $black;
        background-image: url('../static/images/svgs/arrow-square-light.svg');

        &:hover, &:active, &:focus {
            background-image: url('../static/images/svgs/arrow-square-redwhite.svg');
        }
    }
}