/// <reference path="../../main.scss" />

// ------------- GLOBAL FORM ELEMENT STYLES ------------- //

%input,
select,
input[type="text"],
input[type="email"],
input[type="password"],
textarea {
    outline: 0 !important; //overriding browser insistence on outline for dropdowns
    @extend %font-primary;
    padding: $spacer-primary/2;
    font-size: 1rem; //overriding bootstrap
    max-width: $max-width-form;
    width: 100%;
    border: 2px solid $black;
    background-color: $lightGray;
    border-radius: 0;
    line-height: 1.5rem;
    appearance: none;
    transition: background-color .4s, color .4s, border-color .4s, font-size .4s;
    display: block;
    min-height: 61px; //min-height of input with placeholder text, for those w/o placeholder text
    margin: $spacer-small 0 $spacer-primary 0;

    &:hover {
        background-color: $offWhite;
    }

    &:focus {
        background-color: $offWhite;
        box-shadow: none;
        border-color: inherit;

        &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
            color: transparent;
        }

        &::-moz-placeholder { /* Firefox 19+ */
            color: transparent;
        }

        &:-ms-input-placeholder { /* IE 10+ */
            color: transparent;
        }

        &:-moz-placeholder { /* Firefox 18- */
            color: transparent;
        }
    }

    &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        color: $black;
    }

    &::-moz-placeholder { /* Firefox 19+ */
        color: $black;
    }

    &:-ms-input-placeholder { /* IE 10+ */
        color: $black;
    }

    &:-moz-placeholder { /* Firefox 18- */
        color: $black;
    }
}

label, .label {
    @extend %font-primary-bold;
    max-width: $max-width-form;
}

input[type=button],
input[type=submit] {
    border-radius: 0;
    -webkit-appearance: none;
}

.form-field {
    margin-bottom: $spacer-primary;

    input {
        margin-bottom: 0;
    }
    .field-validation-error {
        color: $red-validation;
    }
}
.tag {
    @extend %heading-accent;
}

// ------------- CHECKBOX AND RADIO ------------- //

.checkbox-container,
.radio-container,
%input-container { //both checkbox and radio inputs
    margin-bottom: $spacer-primary; //changed from padding to margin to take advantage of margin collapsing for when the containers are also p tags, see style guide
    label {
        display: inline-flex;
        cursor: pointer;
        @extend %font-primary;
        line-height: 1.5rem;

        &:before {
            content: '';
            margin-top: 4px;
            min-width: $spacer-small*4;
            height: $spacer-small*4;
            border: 2px solid $black;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            margin-right: $spacer-primary/2;
            transition: background-color.4s;
        }
    }

    input {
        display: none;

        &:checked {
            + label, + input[type="hidden"] + label {
                &:before {
                    background-color: $black;
                    color: $offWhite;
                }
            }
        }
    }
}

.checkbox-container,
%checkbox-input { //checkbox inputs only
    input:checked {
        + label:before, + input[type="hidden"] + label:before {
            background-image: url('../static/images/svgs/checkmark-light.svg');
        }
    }
}

.radio-container { //radio inputs only
    label:before {
        border-radius: 50%;
    }

    input:checked {
        + label:before {
            content: "";
            background: $offWhite url('../static/images/svgs/bullet-circle-light.svg') no-repeat center;
            background-size: 50%;
        }
    }
}


// ------------- SELECT / DROPDOWN ------------- //

.select-container { //selects need this container to maintain custom styles
    position: relative;
    display: inline-block;
    max-width: $max-width-form;
    width: 100%;
    margin: $spacer-small 0 $spacer-primary 0;

    select {
        cursor: pointer;
        user-select: none;
        margin-top: 0;

        option {
            background-color: $offWhite;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    &.containsLabel {
        &:after {
            max-height: 61px;
        }
    }

    &:after {
        content: '';
        background: url('../static/images/svgs/dropdown-arrow.svg') no-repeat center;
        background-size: 40%;
        position: absolute;
        background-color: $lightGray;
        border: 2px solid $black;
        right: 0;
        width: $spacer*6; //height of select
        display: flex;
        justify-content: center;
        align-items: center;
        bottom: 0;
        height: 100%;
        pointer-events: none;
        transition: background-color .4s;
    }

    &.selected,
    &:hover {
        select {
            background-color: $offWhite;
        }
    }

    &.selected {
        &:after {
            background-color: $black;
            background-image: url('../static/images/svgs/dropdown-arrow-light-rotate.svg');
        }
    }
}

// ------------- REQUIRED / OPTIONAL MESSAGING ------------- //

.label-optional {
    display: flex;
    justify-content: space-between;
    max-width: $max-width-form;

    &:after {
        content: '(Optional)';
        font-style: italic;
        font-weight: 400;
        padding-right: 2px;
    }

    &-hide {
        &:after {
            content: none;
        }
    }
}



// ------------- VALIDATION ------------- //

.input-validation,
%input-validation {
    background-color: $red-validation;
    opacity: 0;
    visibility: hidden;
    color: $offWhite;
    max-width: $max-width-form;
    width: 100%;
    padding: $spacer-small $spacer-primary/2;
    transition: background-color .4s;
    display: flex;
    transition: opacity .4s;
    position: absolute;
    top: 0; //fixing horizontal scroll
    left: 0; //fixing horizontal scroll
    align-items: center;

    &-icon {
        background-image: url('../static/images/svgs/x-error-light.svg');
        background-repeat: no-repeat;
        width: $spacer-primary/2;
        height: $spacer-primary/2;
    }

    p {
        margin-left: $spacer-primary/2;
        font-size: .8rem;
        margin-bottom: 0;
    }
}

input[type="email"].invalid {
    + .input-validation {
        opacity: 1;
        visibility: visible;
        position: relative;
        margin-bottom: $spacer-primary;
        margin-top: -$spacer-primary - 2; //-2 to cover bottom border 2px high
    }
}

input[type="email"]:valid {
    + .input-validation {
        opacity: 0;
        visibility: hidden;
        position: absolute;
    }
}

.form-validation,
%form-validation {
    max-width: $max-width-form;
    width: 100%;
    display: flex;
    margin-bottom: $spacer-primary;
    align-items: center;
    @extend %font-primary-bold;

    &-icon {
        height: $spacer-primary/2;
        background-repeat: no-repeat;
        width: $spacer-primary;
        user-select: none;

        &.success {
            background-image: url('../static/images/svgs/checkmark.svg');

            + p {
                border-left: 2px solid $green-validation;
                color: $green-validation;
            }
        }

        &.error {
            background-image: url('../static/images/svgs/x-error.svg');

            + p {
                border-left: 2px solid $red-validation;
                color: $red-validation;
            }
        }
    }

    p {
        padding-left: $spacer-primary/2;
        margin-left: $spacer-primary/2;
        margin-bottom: 0;
    }
}



// ------------- MEDIA QUERIES FOR GLOBAL FORM ELEMENT STYLES ------------- //

@include media(small) {
    select,
    input[type="text"],
    input[type="email"],
    input[type="password"],
    textarea,
    .select-container,
    .form-validation,
    %form-validation,
    .label-optional {
        max-width: 100%;
    }
}
