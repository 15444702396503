/// <reference path="../../main.scss" />
$gated-content-gradient-size: 75px;

.article-page {

    &-inner {
        padding-bottom: $spacer-large;
        border-bottom: 1px solid $black;

        @include media(tablet) {
            padding-bottom: $spacer-primary;
        }
    }


    &-title {
        @extend %h1;
        @extend %heading-accent;
        margin: $spacer 0;
        max-width: none;
    }

    &-header-image {
        margin-top: $spacer-primary;
        text-align: center;

        .caption {
            margin-top: $spacer;
            margin-bottom: 0;
            max-width: none;
        }

        img {
            width: 100%;
        }
    }
}
