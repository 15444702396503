/// <reference path="../main">

.article-image {
    margin: 0 auto;
    display: grid;
    gap: $spacer-primary;
    grid-template-columns: 1fr;

    &-fullwidth img, &-fullbleed img {
        min-width: 100%;
    }

    &-inline {
        max-width: $max-width-content;
        place-items: center;
    }

    &-fiftyfifty {
        @extend %gutter-padding;
        display: grid;
        grid-template-columns: repeat(2, 1fr);

        @include media(tablet-larger) {
            grid-template-columns: 1fr;
        }
    }

    &-inner {
        text-align: center;
        
        .caption {
            margin-top: $spacer;
            margin-bottom: 0;
            max-width: none;

        }
    }
}
