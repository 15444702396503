﻿/// <reference path="../main">

.sticky-fiftyfifty {
    display: flex;
    margin: $spacer-body-responsive 0;

    @include media(tablet) {
        flex-direction: column;
    }

    &-content {
        width: calc(50% + #{$spacer-primary});
        margin-left: $spacer-primary;

        @include media(tablet) {
            width: 100%;
            margin-left: 0;
            margin-top: $spacer-body / 3;
        }
    }

    &-image {
        width: calc(50% - #{$spacer-primary});
        align-content: stretch;

        @include media(tablet) {
            width: 100%;
        }
    }
}
