﻿//styles for membership signup page, containing a form and a listing of membership levels

.membershipRegistration {
    margin-top: $spacer-large;

    &-item + .membershipRegistration-item {
        margin-top: $spacer-body-responsive;
    }

    input[type=submit].membershipRegistration-button {
        display: none;

        &.active {
            margin-top: $spacer-large;
            display: block;
        }
    }

    &-giftmembership-info {
        margin: $spacer-primary 0;
    }

    .cbGiftMembership {
        display: flex;
        padding-bottom: 0;
        margin-top: $spacer-primary;
    }

    span.giftMembership {
        @extend %font-primary-bold;

        &[style="display: none;"] {
            + .select-container {
                display: none;
            }
        }
    }

    span.h5-paragraph-heading {
        display: flex;
        margin-top: $spacer-large;

        &:empty {
            display: none;
        }
    }

    .membership-price {
        &-label {
            @extend %font-primary-bold;

            p {
                margin-bottom: 0;
            }
        }
    }

    .thumbnails li {
        max-width: 100%;
    }

    .thumbnails,
    &-form {
        margin: 0;

        > li {
            text-indent: 0;

            + li {
                padding-top: 0;
            }

            &:before {
                content: none;
            }
        }
    }

    .btn {
        margin-top: $spacer-primary;
    }
    //accordion
    .benefits {
        .span12 {
            border-top: 4px solid $black;
            padding: $spacer-primary 0;
            margin-bottom: 0;
            width: 100%;

            &:last-of-type {
                border-bottom: 4px solid $black;
            }
        }
    }

    .levelTitle {
        margin-bottom: 0;
        cursor: pointer;
        position: relative;
        display: flex;
        align-items: center;

        span {
            @extend %h6;
            @extend %font-primary-bold;
            margin-bottom: 0;
        }

        .donationTitle {
            color: $primary-color;
        }

        &-price {
            color: $gray;

            &:before {
                content: '$';
                margin-left: $spacer;
                margin-right: -$spacer/2;
            }
        }

        &:after {
            content: '';
            background-image: url('../static/images/svgs/plus.svg');
            position: absolute;
            right: 0;
            font-size: 3rem;
            transition: transform .3s;
            width: $spacer-primary;
            height: $spacer-primary;
            background-size: contain;
            background-repeat: no-repeat;
        }

        &:hover {
            &:after {
                transform: rotate(180deg);
            }
        }

        + .donorInfo {
            max-height: 0;
            overflow: hidden;
            position: absolute;
            margin-top: $spacer-primary;
        }
    }

    .levelTitle.show {
        &:after {
            content: '';
            background-image: url('../static/images/svgs/minus.svg');
            transform: none;
            height: $spacer;
        }

        + .donorInfo {
            transition: 4s;
            position: relative;
            max-height: 5000px;
        }
    }
}

@include media(medium) {
    .membershipRegistration {
        &-item + .membershipRegistration-item {
            margin-top: $spacer-large;
        }
    }
}

//Ace overrides
.ace-page > .callout-fullwidth {
    margin-left: -$spacer-body;
    margin-right: -$spacer-body;
	margin-bottom: $spacer-large;
}

@include media(x-large) {
    .ace-page > .callout-fullwidth {
        margin-left: -$spacer-body-responsive;
        margin-right: -$spacer-body-responsive;
		margin-bottom: $spacer-primary;
    }
}

@include media(tablet) {
    .ace-page > .callout-fullwidth {
		margin-bottom: 0;
    }
}

@include media(small) {
    .ace-page > .callout-fullwidth {
        margin-left: -$spacer*2;
        margin-right: -$spacer*2;
    }
}
