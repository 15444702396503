﻿.credits-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;

    .credits-balance {
        margin: $spacer-med 0;
    }

    .credits-cta {
        margin-bottom: $spacer-primary;
    }
}
