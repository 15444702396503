﻿/// <reference path="../main">

// STYLES
.alert {
    padding-top: $spacer-primary / 2;
    padding-bottom: $spacer-primary / 2;

    p {
        margin-bottom: 0;
        max-width: none;
    }
}

