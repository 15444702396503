﻿/// <reference path="../../main.scss" />

.season-overview {

    &-loading {
        text-align: center;
        position: absolute;
        left: 50%;
        top: 25%;
        transform: translate(-50%, -50%);
        p {
            margin-bottom: 0;
        }
    }
}
