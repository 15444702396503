/// <reference path="../main">

.otc-listing-block {
    margin-top: $spacer-large;
    padding-top: $spacer-large;
    border-top: 1px solid $black;

    &-grid {
        display: grid;
        grid-template-columns: repeat(4, minmax(0, 1fr));
        gap: $spacer-primary;
        padding-top: $spacer-md;

        @include media(medium) {
            grid-template-columns: repeat(2, minmax(0, 1fr));
        }

        @include media(small) {
            grid-template-columns: 1fr;
        }
    }

    &-stack {
        .otc-card {
            display: grid;
            grid-template-columns: 1fr;
            gap: $spacer-primary;

            @include media-min(tablet) {
                grid-template-columns: 1fr .4fr;

                &-image {
                    order: 1;
                }
            }

            & + .otc-card {
                margin-top: $spacer-primary;
                padding-top: $spacer-primary;
                border-top: 1px solid $black;
            }

            &-content {
                margin: 0;
            }
        }
    }
}
