/// <reference path="../../main.scss" />
$gated-content-gradient-size: 75px;

.gated-content {

    &-section {
        @extend %gutter-padding;
        background: $white;
        padding-bottom: $spacer-large;
        position: relative;
        // Overlap paragraph content
        margin-top: -$spacer-large;

        // Gradient overlay effect
        &::before {
            content: '';
            position: absolute;
            top: -$gated-content-gradient-size;
            height: $gated-content-gradient-size;
            width: 100%;
            left: 0;
            background: linear-gradient(0deg, rgb(255, 255, 255) 33%, rgba(255,255,255,0.1) 100%);
        }
    }

    &-inner {
        border: 2px solid $red;
        padding: $spacer-primary;
    }

    &-container {
        display: grid;
        grid-template-columns: minmax(300px, .5fr) 1fr;
        gap: $spacer-primary;

        @include media(medium) {
            grid-template-columns: 1fr;
            margin-bottom: $spacer-primary; 
        }
    }


    &-title {
        @extend %h4-page-subheading;
        @extend %heading-accent;
        max-width: $max-width-content;
    }

    &-info {
        &-description {
            margin: $spacer-md 0;
        }
    }

    &-callouts {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: $spacer-md;

        @include media(small) {
            grid-template-columns: 1fr;
        }

        p {
            margin: 0;
        }

        &-item {
            box-shadow: $card-box-shadow;
            padding: $spacer-md;
            align-self: stretch;

            &-price, &-discount {
                margin: 0;
            }

            &-price-container {
                margin: $spacer-md 0;
            }

            &-discount {
                color: $gray-light;
                text-decoration: line-through;
            }
        }
    }
}
