/// <reference path="../../main.scss" />

.otc-listing {

    &-wrapper {
        display: grid;
        grid-template-columns: minmax(min-content, 300px) 1fr;
        min-height: 900px;
        @include media($otc-main-breakpoint) {
            grid-template-columns: 1fr;
        }
    }

    &-results, &-results p {
        max-width: none;
        margin: 0;
        padding: 0;
    }

    &-categories {
        @extend %list-reset;
        display: flex;
        gap: $spacer;
        flex-wrap: wrap;

        &-tag {
            @extend %list-item-reset;

            a {
                @extend %otc-links;
            }
        }
    }

    &-loader {
        position: relative;
        display: flex;
        align-items: center;
        flex-direction: column;
    }
}
