﻿/// <reference path="../main">
$accordion-icon-size: 1.75rem;

// STRUCTURE
.accordion {

    &-panel {
        overflow: hidden;
        transition: $transition height;

        &[aria-hidden="true"] {
            height: 0;
        }
    }
}


// STYLES
.accordion {
    max-width: $max-width-content;

    &-block {
        padding-top: $spacer-body-responsive;
        padding-bottom: $spacer-body-responsive;
    }

    &-heading {
        margin-bottom: $spacer-rem;
        @extend %h3-page-subheading;
        color: $black;
    }

    &-inner {
        margin: 0;
        padding: 0;
        max-width: none;
    }

    &-trigger {
        @extend %un-button;
        display: block;
        width: 100%;
        padding-top: $block-spacer;
        padding-bottom: $block-spacer;

        @include media(small) {
            padding-top: $spacer-rem;
            padding-bottom: $spacer-rem;
        }
    }

    &-item {
        border-top: 1px solid;
        // Override project specific styling
        text-indent: 0;
        padding-top: 0;

        &:before {
            content: none;
        }


        &:last-child {
            border-bottom: 1px solid $black;
        }

        &, &:last-child {
            border-color: $black;
        }
    }

    &-panel {
        &-content {
            padding-bottom: $spacer-med;
            color: $black;
        }
    }

    &-heading, &-panel-content, &-trigger {
        color: $black;
    }
}


// ARROW/INDICATOR
.accordion {
    &-trigger {
        position: relative;
        padding-right: $accordion-icon-size * 3;
        color: $black;

        &::after {
            content: '';
            display: block;
            position: absolute;
            right: $spacer-rem;
            top: 50%;
            transform: translateY(-50%) rotate(0deg);
            transition: $transition transform;
            background-image: url('../static/images/svgs/plus.svg');
            background-repeat: no-repeat;
            background-position: center center;
            background-size: contain;
            font-size: 3rem;
            width: $spacer-primary;
            height: $spacer-primary;
        }

        &[aria-expanded="true"] {
            &::after {
                background-image: url('../static/images/svgs/minus.svg');
            }
        }
    }
}

// theme
@include dark-theme() {

    .accordion {

        &-item {
            &:last-child {
                border-bottom: 1px solid $offWhite;
            }

            &, &:last-child {
                border-color: $offWhite;
            }
        }

        &-trigger {

            &::after {
                background-image: url('../static/images/svgs/plus-inverted.svg');
            }

            &[aria-expanded="true"] {
                &::after {
                    background-image: url('../static/images/svgs/minus-inverted.svg');
                }
            }
        }

        &-panel {
            &-content {
                color: $offWhite;
            }
        }

        &-heading, &-panel-content, &-trigger {
            color: $offWhite;
        }
    }
}
