﻿/// <reference path="../../main">

// Membership Cart Display
.ace-page {
    .contribCart {
        .membership-level {
            &-heading {
                &.perfRow {
                    border-bottom: none !important;
                }
            }

            &-mobile-text {
                margin-top: .5rem;
            }

            &-details {

                &.perfRow {
                    display: block;
                    padding-bottom: 20px;

                    td {
                        width: 100%;
                    }
                }

                &-title {
                    margin-bottom: .5rem;
                    color: $red;
                    font-weight: bold;
                    text-transform: uppercase;
                }

                &-message {

                    p {
                        margin-bottom: .5rem;

                        &:last-child {
                            margin-bottom: 0;
                        }
                    }

                    a, p > a {
                        @extend %primary-link;
                        text-transform: none !important;
                        font-weight: bold !important;
                    }
                }
            }
        }
    }
}

.ace-page {
    #cartItemDisplay {
        border-bottom: none;

        ul, ol {
            max-width: 100%;
            margin: 0;
        }

        + h5.paragraph-heading {
            margin-top: $spacer-large;
        }

        .remove {
            @extend %remove-link;
        }
    }

    .packageCart {
        display: block;

        tbody {
            display: block;
        }
    }

    #cartActions {
        border-top: none;
        z-index: 10;
        position: relative;
        padding-top: 0;
        margin-top: $spacer-large;

        ul, ol {
            max-width: 100%;
            margin: 0;
        }

        li {

            > label {
                max-width: 75%;
            }

            &.total {
                border-top: 4px solid $black;
                margin-top: $spacer-primary;
                padding-top: $spacer-primary;
            }

            + li {
                margin-top: $spacer-primary/2;
            }

            &:nth-child(even) {
                background-color: transparent;
            }
        }

        .applied-credits {
            color: $red;
        }
    }

    .checkoutBtns {
        padding-top: 0;
        padding-bottom: 0;
        text-align: left;
        margin-top: $spacer-primary;
        display: flex;
        align-items: flex-end;

        .btn {
            margin-right: $spacer-primary;
        }

        p {
            margin-bottom: 0;
            min-height: 39px; //to account for hover effect on anchor when no button is present, doesn't shift page. 39 = height of p plus padding on hover.
            display: flex;
            align-items: flex-end;
        }
    }

    #cartContainer {
        .purchaseHeader h2 {
            @extend %h1-page-heading;
        }

        h5.paragraph-heading:empty {
            display: none;
        }

        #cartParkingArea {
            p {
                max-width: 100%;
            }
        }

        .membership-upsell {
            margin-bottom: $spacer-large;

            &-discount {
                margin-bottom: $spacer*2;
                display: block;
                @extend %font-primary-bold;

                &-price {
                    color: $primary-color;
                }
            }
        }
    }

    .price-null {
        text-decoration: line-through;
    }

    .price-new {
        color: $primary-color;
    }

    .contribCart,
    .giftCertCart,
    #cartItemDisplay {
        .headerTr,
        .perfRow,
        .merchRow,
        .body,
        .headerLine {
            display: flex;
            border-bottom: 1px solid $gray;

            > * {
                width: 25%;
                flex-basis: 25%;
                padding: $spacer $spacer*2 $spacer 0;
                text-align: left;
            }

            &-two {
                width: 50%;
                flex-basis: 50%;
                padding: 0;

                ul {
                    display: flex;
                    justify-content: space-between;

                    > * {
                        width: 50%;
                        flex-basis: 50%;
                        padding: $spacer $spacer*2 $spacer 0;

                        &:last-child {
                            padding-right: 0;
                        }
                    }
                }
            }
        }

        a {
            @extend %modal-link;
        }
    }
    /*  .giftCertCart + .contribCart,
    .contribCart + .contribCart,
    .giftCertCart + .giftCertCart,
    .cartItemDisplayItem + .cartItemDisplayItem {
        margin-top: $spacer-primary;
    }*/

    .cart-item-container + .cart-item-container,
    .cart-item-container + .contribCart,
    .cart-item-container + .giftCertCart {
        margin-top: $spacer-primary;
    }

    .giftCertCart {
        .headerTr {
            > * {
                text-align: left;
                width: 100%;
                flex-basis: 100%;
            }
        }
    }

    .donate-title,
    .contrib-title,
    .perf-title,
    .merch-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 4px solid $black;

        &-text {
            @extend %h6-content-heading;
            margin-bottom: 0;
        }
    }

    .donate-title {
            padding: $spacer-rem 0;
        
        p {
        margin: 0;
        }
    }

    .singleTicketWrapper .headerLine,
    .packageCart th {
        color: $gray;
        @extend %font-primary-bold;
    }

    .packageArea.cart-mobile {
        display: none;
    }

    .cart-item-container {
        .remove {
            @extend %remove-link;
        }

        & + .cart-item-container {
            margin: 0;
            padding: 0;
            margin-top: $spacer-primary;
        }
    }

    .cart-totals {
        margin-top: $spacer-rem;
        padding-top: $spacer-rem;
        border-top: 2px solid $black;
    }
}

.waived-fees-label {
    font-weight: bold;
    color: $red;
}

.waived-fees {
    color: $red;
    text-decoration: line-through;
}

.member-discount {
    color: $red;
    font-weight: bold;
}

@include media(medium-larger) {
    .ace-page {
        .checkoutBtns {
            flex-direction: column;
            align-items: flex-start;

            .btn {
                margin-bottom: $spacer-primary/2;
            }
        }
    }
}

@include media(tablet) { //mobile cart change
    .ace-page {

        .packageArea {
            display: none;
        }

        .packageArea.cart-mobile {
            display: block;
            border-bottom: 1px solid $gray;
            padding-bottom: $spacer;

            table,
            ul {
                padding-bottom: $spacer;
                padding-top: $spacer;
            }

            tr,
            li {
                display: block;
            }

            .header {
                font-weight: 700;
                color: $gray;
            }

            .singleTicketWrapper {
                margin-bottom: 0;
            }
        }

        #cartActions {
            width: 100%;
        }

        .checkoutBtns {
            align-items: center;

            .btn {
                margin-right: 0;
            }

            a {
                width: 100%;
            }
        }
    }
}
// Promo Code
.cart-promo-code {
    margin-bottom: $spacer-large;

    &-header {
        font-weight: bold;
        margin-bottom: $spacer;
    }

    label {
        font-weight: normal;
    }

    &-input {
        display: flex;
        gap: $spacer;

        @include media(small) {
            flex-direction: column;
        }

        input {
            margin: 0;
            white-space: normal !important;
            line-height: normal !important;
        }
    }

    &-error, &-success {
        margin-top: $spacer/2;
    }

    &-error {
        color: $red-validation;
    }

    &-success {
        color: $green-validation;
    }
}
