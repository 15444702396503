﻿.overview-block {
    padding-top: $spacer-body-responsive;
    padding-bottom: $spacer-body-responsive;

    &-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    &-heading {
        width: 100%;
        @extend %h1-page-heading;
        @extend %h2;
        margin-bottom: $spacer-primary;
    }

    &-item {
        width: 50%;
        flex-basis: 50%;

        &-text,
        &-text-more,
        &-button {
            margin-right: $spacer-body;
        }

        &-text-more {
            max-height: 0;
            overflow: hidden;
            position: absolute;
        }

        &-button {
            @extend %font-primary-bold;
        }
    }

    &-excerpt {
        padding-left: $spacer-large;
    }
}

.overview-block.show-more {
    .overview-block-item-text-more {
        transition: 1s;
        position: relative;
        max-height: 1000px;
    }
}

@include media(large) {
    .overview-block {
        &-item {
            &-text,
            &-text-more,
            &-button {
                margin-right: $spacer-large;
            }
        }
    }
}

@include media(medium) {
    .overview-block {
        padding-top: $spacer-large;
        padding-bottom: $spacer-large;

        &-item {
            &-text,
            &-text-more,
            &-button {
                margin-right: $spacer-primary;
            }
        }

        &-excerpt {
            padding-left: $spacer-primary;
        }
    }
}

@include media(tablet) {
    .overview-block {
        &-container {
            display: block;
        }

        &-item {
            width: 100%;
            flex-basis: 100%;

            &-text,
            &-text-more,
            &-button {
                margin-right: 0;
            }

            &:first-of-type {
                margin-bottom: $spacer-large;
            }
        }

        &-excerpt {
            padding-left: 0;
        }
    }
}
