﻿//styles for pdp, edp

.details-page {
    &-banner {
        position: relative;

        &-image {
            min-height: 20vw;

            img {
                width: 100%;
            }

            &-background {
                min-height: $spacer-primary;

                + .details-page-banner-text-container {
                    position: relative;
                }
            }
        }

        &-text-container {
            position: absolute;
            bottom: -$spacer*2;
        }

        &-heading,
        &-subheading,
        &-secondaryheading {
            background-color: $offWhite;
            color: $black;
            box-shadow: 30px 0 0 #fefefe, -30px 0 0 #fefefe;
            margin-bottom: 0;
        }

        &-heading {
            //@extend %h1-page-heading;
            //@extend %h3-page-subheading;
            @extend %h2-section-heading;
            max-width: 80vw;
            padding-top: $spacer-primary/2;
        }

        &-subheading {
            @extend %h6;
            display: inline-block;
            text-transform: capitalize;
            padding-top: $spacer*2;
            transform: translateY(10px);
            font-style: italic;
        }

        &-secondaryheading {
            @extend %h2-section-heading;
            padding-bottom: $spacer-primary/2;
            font-weight: normal;
            text-transform: none;

            p {
                margin-bottom: inherit !important;
                b, strong, em, i {
                    font-size: inherit;
                }
            }
        }
    }

    &-details {
        margin-top: $spacer-primary;

        + .nospace {
            margin-top: $spacer-large;
        }

        &-series,
        &-series span {
            color: $primary-color;
            @extend %h5-paragraph-heading;
            font-weight: 400;

            span {
                margin-right: $spacer;
            }
        }

        &-container {
            display: flex;
            align-items: center;
            margin-bottom: $spacer-primary/2;
        }

        &-date,
        &-place {
            margin-bottom: 0;
        }

        &-date {
            @extend %h6-primary;

            + .details-page-details-place {
                &:before {
                    content: "|";
                    padding-left: $spacer;
                    padding-right: $spacer;
                }
            }
        }

        &-place {
            text-transform: capitalize;
            @extend %font-primary-bold;
        }

        &-price,
        &-icons,
        &-cta {
            margin-bottom: $spacer-primary/2;
        }

        &-place,
        &-price,
        &-icons {
            &:empty {
                display: none;
            }
        }

        &-icons {
            display: flex;
            flex-wrap: wrap;

            &-item {
                display: flex;
                align-items: center;
                margin-bottom: 0;
                margin-right: $spacer-primary;
            }
        }

        &-price {
            text-transform: capitalize;
        }

        &-genres {
            font-style: italic;

            span {
                font-style: italic;
                @extend %font-primary-bold;
            }
        }

        *:last-child {
            margin-bottom: 0;
        }
    }

    &-buttongroup {
        flex-wrap: wrap;
        margin-top: $spacer-body-responsive;
        /*margin-left: -$spacer-primary/2;
        margin-right: -$spacer-primary/2;*/
        /*width: calc(100% + 30px);*/
		width: 100%;
        overflow-x: hidden;

        &:not(.full-width) { //override specificity for non-fullwidth styles
            display: flex;
        }

        + .nospace {
            margin-top: $spacer-large;
        }

        &-item {
            width: calc(20% - 30px);
            min-width: 250px; //when item stops looking good
            box-shadow: 0px 2px 5px 1px rgba(0,0,0,0.05);
            padding: $spacer-large $spacer-primary $spacer-primary;
            margin: 0 $spacer-primary/2 $spacer-primary;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: space-between;
			transform: translateX(-$spacer-primary/2);

            &-date {
                text-transform: uppercase;

                span {
                    font-size: inherit;
                    line-height: inherit;

                    &:first-of-type {
                        &:after {
                            content: ', ';
                        }
                    }
                }
            }

            &-date,
            &-time {
                @extend %font-primary-bold;
            }

            &-time,
            &-price {
                margin-bottom: $spacer/2;
            }

            &-button {
                margin-top: $spacer-primary;
                width: 100%;

                & + & {
                    margin-top: $spacer-primary/2;
                }
            }

            &:hover {
                box-shadow: 0px 2px 10px 1px rgba(0,0,0,0.1);
            }

            &.soldout {
                opacity: .7;

                &:hover {
                    box-shadow: 0px 2px 5px 1px rgba(0,0,0,0.05);
                }

                .event-tag.tag-soldout {
                    color: $gray;
                    margin-bottom: 0;

                    &:before {
                        content: none;
                    }
                }

				.event-tag:not(.tag-soldout){
					opacity: .5;
				}
            }
        }
    }
    &-past-production {
        margin-top: $spacer-primary;
    }
}

    @include media(medium) {
        .details-page {
            &-banner {
                &-image-background {
                    min-height: $spacer-body-responsive;
                }

                &-text-container {
                    position: relative;
                    background-color: $offWhite;
                    bottom: 0;
                }

                &-subheading {
                    position: absolute;
                    transform: translateY(-40px);
                }
            }

            &-details {
                margin-top: $spacer;
            }

            &-buttongroup {
                margin-left: -$spacer-primary;
                margin-right: -$spacer-primary;
                width: calc(100% + 30px);

                + .nospace {
                    margin-top: $spacer-primary;
                }

                &-item {
                    padding: 0;
                    box-shadow: none;
                    margin: 0 $spacer-primary $spacer-large;
                    min-width: 150px;
                    width: calc(25% - 60px);
					transform: translateX(0);

                    &-button {
                        margin-top: $spacer;
                    }

                    &:hover {
                        box-shadow: none;
                    }

                    &.soldout {
                        &:hover {
                            box-shadow: none;
                        }
                    }
                }
            }
        }
    }

    @include media(tablet) {
        .details-page {
            &-details {
                &-container {
                    display: block;
                }

                &-date {
                    + .details-page-details-place {
                        margin-top: $spacer-primary/2;

                        &:before {
                            content: none;
                            padding: 0;
                        }
                    }
                }
            }

            &-buttongroup {
                &-item {
                    &-date {
                        span {
                            display: flex;
                            flex-direction: column;

                            &:first-of-type {
                                &:after {
                                    content: none;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    @include media(small) {
        .details-page {
            &-banner {
                &-heading {
                    padding-top: $spacer-primary;
                }

                &-subheading {
                    padding-top: $spacer-primary/2;
                    transform: translateY(-30px);
                }
            }

            &-details {
                + .nospace {
                    margin-top: $spacer-large;
                }

                &-series span {
                    margin-right: $spacer/2;
                }

                &-icons {
                    display: block;

                    &-item {
                        + .details-page-details-icons-item {
                            margin-left: 0;
                        }
                    }
                }
            }

            &-buttongroup {
                margin: $spacer*4 auto 0 auto;
                justify-content: space-between;
                width: 100%;

                &-item {
                    margin: $spacer*2 0;
                    min-width: 0;
                    width: calc(50% - 10px);

                    &-button {
                        margin-top: $spacer/2;
                    }
                }
            }
        }
    }
