﻿.mini-calendar {
    &-grid {
        &.loading {
            visibility: hidden;
        }
    }

    .current-month {
        flex-grow: 1;
        text-align: center;
        margin-bottom: 0;
        @extend %h6-primary;
    }

    &-nav {
        padding: $spacer-primary/2;
        display: flex;
        align-items: center;

        &-arrow-left,
        &-arrow-right {
            width: $spacer*2;
            height: $spacer-primary/2;
            background-size: contain;
            background-repeat: no-repeat;
            background-image: url('../static/images/svgs/dropdown-arrow-light-rotate.svg');

            &:active,
            &:focus,
            &:hover {
                background-size: contain;
                background-repeat: no-repeat;
                background-image: url('../static/images/svgs/dropdown-arrow-light-rotate.svg');
            }
        }

        &-arrow-left {
            transform: rotate(270deg);

            &:hover {
                transform: scale(1.1) rotate(270deg);
            }

            &:active,
            &:focus {
                transform: scale(.9) rotate(270deg);
            }
        }

        &-arrow-right {
            transform: rotate(90deg);

            &:hover {
                transform: scale(1.1) rotate(90deg);
            }

            &:active,
            &:focus {
                transform: scale(.9) rotate(90deg);
            }
        }
    }

    &-week {
        display: flex;
    }

    &-day {
        height: $spacer*4;
        width: $spacer-primary*2;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;


        &.wrapped {
            visibility: hidden;
        }

        &.past,
        &:not(.clickable) {
            cursor: default;
            color: $gray;
        }

        &.header,
        &.today {
            color: $offWhite;
        }

        &.header,
        p {
            font-size: .9rem;
            line-height: 1;
        }

        &.header {
            @extend %font-primary-bold;
        }

        &.today {
            color: $offWhite;
            background-color: $primary-color;

            p {
                font-size: .8rem;
                line-height: 1;
            }
        }

        &.clickable {
            cursor: pointer;
            transition: border-color .6s;

            &:hover {
                border: 2px solid $primary-color;
            }
        }
    }

    p {
        margin-bottom: 0;
    }

    .ng-hide:not(.ng-hide-animate) {
        display: inherit !important;
        visibility: hidden;
        pointer-events: none;
    }
}
