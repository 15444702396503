﻿.isiOS {
    .callout-half {
        display: block;

        &-text-container {
            display: block;
        }

        img {
            width: 100%;
        }
    }

    .callout-quarter {
        display: block;

        &-image-container {
            .callout-quarter-subheading {
                position: absolute;
            }

            + .callout-quarter-text-container {
                height: auto;
            }
        }
    }

    .callout-quarter:not(.callout-quarter-image) {
        .button-primary,
        .button-secondary {
            position: relative;
            transform: translateY(-100%);
        }
    }
}

@include media(small) {
    .isiOS {
        .callout-quarter {
            &-text-container {
                display: block;
            }
        }

        .callout-quarter:not(.callout-quarter-image) {
            .button-primary,
            .button-secondary {
                /*transform: translateY(calc(-100% - 20px));*/
				transform: none;
            }
        }
    }
}
