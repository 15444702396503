﻿.ace-page {
    .printTicketsList {
        margin: 0;
        display: inline-flex;
        flex-direction: column;

        &-item {
            display: flex;
            align-items: flex-start;
            border: 2px solid $primary-color;
            padding: $spacer-primary;
            list-style-type: none;
            text-indent: 0;

            span:not(.printTicketsList-item-seat) {
                display: block;
            }

            &:before {
                content: none;
            }

            &-title {
                font-weight: bold;
                @extend h6;
            }

            img {
                max-width: 200px;
                margin-left: $spacer-primary;
            }

            + * {
                margin-top: $spacer-primary;
            }
        }
    }
}

@media screen and (max-width: $large-media-query-size) {
    .ace-page {
        .printTicketsList {
            margin-top: $spacer-primary;
        }
    }
}

@media screen and (max-width: $small-media-query-size) {
    .ace-page {
        .printTicketsList {
            &-item {
                display: block;
                padding: $spacer*2;

                img {
                    max-width: 100%;
                    margin-left: 0;
                    margin-top: $spacer-primary;
                }
            }
        }
    }
}

@media print {
    .ace-page {
        .printTicketsList {
            &-item {
                page-break-after: always;
                page-break-inside: avoid;
                
                img {
                    max-width: 200px !important; //overriding bootstrap important
                }       
            }
        }
    }
}
