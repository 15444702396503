﻿/// <reference path="../main">

/// <reference path="../../../main">
$button-size: 3rem;

.callout-carousel {

    .callout-fullwidth {

        .button {
            max-width: calc(100% - 40px);
            width: auto;
        }
    }

    .callout-fullwidth-bg {
        position: relative;
        overflow: hidden;
        height: 0;
        width: 100%;
        padding-bottom: (8 / 19) * 100%;

        img {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            object-position: center center;
            object-fit: cover;
            width: 100%;
            height: 100%;
        }
    }
}

// ARROWS
.callout-carousel {
    &-btn {
        &-next,
        &-prev {
            position: absolute;
            display: flex;
            top: auto;
            bottom: 0;
            z-index: 10;
            color: white;
            cursor: pointer;
            // tweak padding to alter arrow size
            padding: 0.9rem;
            background-image: url('../static/images/svgs/arrow-square-redblack.svg');
            width: $spacer-primary;
            height: $spacer-primary;


            &:hover, &:active, &:focus {
                background-image: url('../static/images/svgs/arrow-square-redwhite.svg');
            }

            @include media(small) {
                width: 1.5rem;
                height: 1.5rem;
            }
        }

        &-next {
            right: 0;
        }

        &-prev {
            transform: rotate(180deg);
            left: 0;
        }
    }
}
