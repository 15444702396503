﻿/// <reference path="../../main.scss" />

.my-account-landing .sectionnav {
    margin-bottom: 0;
}

.sectionnav { //used on account pages, ie. profile, ticket history, member benefits, etc
    display: flex;
    align-items: center;
    margin: $spacer-large 0;
    position: relative;

    &-mobile {
        display: none;
    }

    &:before {
        content: '';
        background-color: $primary-color;
        position: absolute;
        left: calc((100vw - 100%) / -2); // up to left edge of screen
        top: 0;
        bottom: 0;
        right: 0;
        width: 100vw;
    }

    &-item {
        z-index: 1;
        position: relative;

        &:not(:first-child) {
            &:before {
                content: '';
                border-left: 2px solid $offWhite;
                height: 1rem;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                left: -1px;
            }
        }

        .active,
        &-link {

            @extend %nav-item;
            color: $offWhite;
            border: none;
            padding: 0 $spacer*2;
            display: block;
            transition: background .3s, color .3s;
            height: $spacer-primary*2;
            display: flex;
            align-items: center;
            text-align: center;
            width: calc(100% + 3px);
            margin-left: -1px;

            &:hover,
            &:focus,
            &:active {
                padding-top: 0;
            }

            &:hover {
                background: $offWhite;
                color: $primary-color;
            }

            &:focus {
                background-color: $primary-color;
                color: $offWhite;
            }

            &:active {
                background-color: $offWhite;
                color: $black;
            }
        }
    }

    .active {
        background-color: $offWhite;
        color: $primary-color;

        &:active,
        &:focus,
        &:hover {
            &:before {
                border: none;
            }

            + .sectionnav-item {
                &:before {
                    border: none;
                }
            }
        }
    }
}


@include media(tablet) {
    .sectionnav {
        &-item {
            &-link {
                font-size: .8rem;
            }
        }
    }
}

@include media(tablet-larger) {

    .my-account-landing {
        padding-bottom: $spacer-primary;

        .sectionnav, .sectionnav-mobile {
            margin-bottom: 0;
        }
    }

    .sectionnav {
        display: none;

        &-mobile {
            display: block;
        }
    }
}
