/// CSS transitions
/// @access public
/// @name transition
/// @param {duration} $time - Duration of transition
/// @param {string} $property ["all"] - CSS property to which transition effect should be applied
/// @param {string} $timing ["ease-in"] - Timing function of transition
/// @param {duration} $delay [0] - Duration of delay of transition
/// @example
///   @include transition($time: .2s, $property: "opacity, background-color", $timing: "linear");
/// @group Miscellaneous

@mixin transition($time, $property: all, $timing: ease-in, $delay: 0) {
    @if ($delay != 0) {
        transition: $property $time $timing $delay;
    }
    @else {
        transition: $property $time $timing;
    }
}


/// CSS animations
/// @access public
/// @name animation
/// @param {string} $name - Name of keyframe animation
/// @param {numeral} $iteration [1] - Number of times an animation should run
/// @param {string} $timing ["cubic-bezier(0.22, 0.44, 0, 1)"] - Timing function of transition
/// @param {duration} $duration [1s] - Duration of animation
/// @param {string} $fill-mode ["none"] - Fill mode of animation
/// @param {duration} $delay [0] - Duration of delay of animation
/// @example
///   @include animation($name: "fade-in", $iteration: infinite, $fill-mode: "forwards");
/// @group Miscellaneous

@mixin animation($name, $iteration: 1, $timing: cubic-bezier(0.22, 0.44, 0, 1), $duration: 1s, $fill-mode: none, $delay: 0) {
    animation-name: $name;
    animation-iteration-count: $iteration;
    animation-timing-function: $timing;
    animation-duration: $duration;

    @if ($fill-mode != "none") {
        animation-fill-mode: $fill-mode;
    }

    @if ($delay != 0) {
        animation-delay: $delay;
    }
}


/// Inline breakpoints
/// @access public
/// @name media
/// @content true
/// @param {string | value} $breakpoint - Value of breakpoint; accepts string values defined in breakpoints map
/// @example
/// header {
///   @include media(small) {
///        height: auto;
///   }
/// }
/// @group Miscellaneous
@mixin media($breakpoint, $breakpoints-map: $breakpoints) {

    @if map-has-key($breakpoints-map, $breakpoint) {
        $breakpoint: map-get($breakpoints-map, $breakpoint);

        @media (max-width: $breakpoint) {
            @content;
        }
    }

    @else {
        @media (max-width:$breakpoint) {
            @content;
        }
    }
}

@mixin media-min($breakpoint, $breakpoints-map: $breakpoints) {

    @if map-has-key($breakpoints-map, $breakpoint) {
        $breakpoint: map-get($breakpoints-map, $breakpoint);

        @media (min-width: $breakpoint) {
            @content;
        }
    }

    @else {
        @media (min-width:$breakpoint) {
            @content;
        }
    }
}

@mixin media-range($breakpoint-min, $breakpoint-max, $breakpoints-map: $breakpoints) {

    @if map-has-key($breakpoints-map, $breakpoint-min) {
        $breakpoint-min: map-get($breakpoints-map, $breakpoint-min);
        $breakpoint-max: map-get($breakpoints-map, $breakpoint-max);

        @media (min-width: $breakpoint-min) and (max-width: $breakpoint-max) {
            @content;
        }
    }

    @else {
        @media (min-width: $breakpoint-min) and (max-width: $breakpoint-max) {
            @content;
        }
    }
}

/// Ellipsis multiple line element
/// @access public
/// @name multiline-ellipsis
/// @param {numberal} $number - Number of lines to show before ellipsis
/// @group Miscellaneous

@mixin multiline-ellipsis($number) {
    display: -webkit-box;
    -webkit-line-clamp: $number;
    text-overflow: ellipsis;
    overflow: hidden;
    -webkit-box-orient: vertical;
}


/// Override styles specifically for EPiServer edit mode
/// @access public
/// @name epimode
/// @content true
/// @example
///   @include epimode {
///   	header {
///        height: 800px;
///     }
///   }
/// @group Miscellaneous

@mixin epimode {
    .epi-editmode {
        @content;
    }
}

/// Loader Mixin
@mixin loader($border-size: 10px, $main-color: red, $accent-color: black, $size: 120px) {
    border: $border-size solid $main-color;
    border-top: $border-size solid $accent-color;
    border-radius: 50%;
    width: $size;
    height: $size;
    animation: spin 2s linear infinite;
}
