﻿/// <reference path="../../main.scss" />

.fixed-mini-cart {
    position: sticky;
    bottom: 0;
    width: 100%;
    padding: $spacer-primary 0;
    z-index: 10;
    background: $black;
    color: $offWhite;


    &-inner {
        @extend %gutter-padding;
        display: flex;
    }

    &-header {
        @extend %h4;
        @extend %heading-accent;
        flex-grow: 1;
        max-width: none;
    }

    &-footer {
        text-align: right;

        @include media(tablet) {
            width: 100%;
            text-align: left;
        }
    }

    &-content {
        display: flex;
        align-items: center;
        align-content: center;
        width: 100%;
        flex-wrap: wrap;

        p {
            margin: 0;
        }
    }

    &-total {
        &, span {
            @extend %h6;
            @extend %heading-accent;
        }
    }

    .tax-message {
        @extend %small;
        font-style: italic;
    }


    &-submit {
        margin-left: $spacer-primary;

        @include media(tablet) {
            margin-left: 0;
            margin-top: $spacer-med;

            &, button {
                width: 100%;
            }
        }
    }

    [aria-hidden=true] {
        display: none;
    }
}
