/// <reference path="../../main.scss" />
// ------------- BUTTON STYLES ------------- //

%button,
.button {
    display: inline-block;
    @extend %font-primary-bold;
    color: $offWhite;
    line-height: 110%;
    transition: color .4s, background-color .4s;
    letter-spacing: 1px;
    text-transform: uppercase;
    padding: 11px 26px; // mockup states 15px 30px, however mockup doesn't include 4px borders in that calculation
    cursor: pointer;
    user-select: none;
    outline: 0;
    text-decoration: none;
    border: 4px solid $primary-color;
    background-color: $primary-color;
    text-align: center;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    max-width: 100%;

    &:hover,
    &:focus,
    &:active {
        padding-top: 11px;
    }

    &:hover {
        color: $primary-color;
        background-color: $offWhite;
        border-color: $primary-color;
        text-decoration: none; //override for some bootstrap buttons
    }

    &:active {
        border-color: $black;
        color: $offWhite;
        background-color: $black;
    }

    &.disabled,
    &[disabled],
    &[disabled=disabled] {
        opacity: .25;
        pointer-events: none;
    }
}

//To create a secondary button you must also include the .button class. This way other styles throughout the sass will properly apply.
//Example:
//<button class="button button-secondary">Button Secondary</div>

%button-secondary,
.button-secondary {
    @extend %button;
    background-color: $offWhite;
    color: $primary-color;

    &:hover {
        background-color: $primary-color;
        color: $offWhite;
    }

    &:active {
        background-color: $black;
    }
}


.button-outline {
    @extend %button;   
    @extend %button-outline;
}


@include media(small) {
    %button,
    .button {
        width: 100%;
        padding: 11px $spacer; //RE 11px: mockup states 15px, however mockup doesn't include 4px borders in that calculation
        line-height: 1.4rem;
    }
}

%un-button {
    background-color: transparent;
    border: 0;
    padding: 0;
    margin: 0;
    cursor: pointer;
    display: inline;
    text-align: left;

    &:hover,
    &:focus {
        background-color: transparent;
    }

    &[disabled],
    &[aria-disabled="true"] {
        background-color: transparent;
        color: $primary-color;
        cursor: not-allowed;
    }
}
