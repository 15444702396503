﻿.fiftyfifty-quote {
    position: relative;
    padding: $spacer-body;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &:before {
        content: '';
        background-image: url('../static/images/line-pattern-b-w-transparent_16x16.png');
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }

    &:only-of-type {
        height: 100%;
    }

    &-text {
        position: relative;
        margin-bottom: $spacer*2;
        @extend %h4-primary;
        text-transform: none;

        &:before {
            content: "\201c";
            left: -$spacer-primary;
            position: absolute;
        }

        &:after {
            content: "\201d";
            margin-left: $spacer;
        }
    }

    &-attr {
        display: flex;
        @extend %h6;
        text-transform: uppercase;

        &:before {
            content: '';
            border-top: 4px solid $offWhite;
            width: $spacer-primary*2;
            display: block;
            margin-right: $spacer;
            margin-top: $spacer;
        }
    }
}

@include media(large) {
    .fiftyfifty-quote {
        padding: $spacer-body-responsive;
    }
}

@include media(small) {
    .fiftyfifty-quote {
        padding: $spacer-large $spacer*2 $spacer-primary;

        &-text {
            &:before,
            &:after {
                content: none;
            }
        }

        &-attr {
            &:before {
                width: $spacer-primary;
                border-width: 2px;
            }
        }
    }
}
