﻿/// <reference path="../../main">
.ace-page {
    max-width: 100%;
    width: 100%;

    #confirmationContainer,
    #paymentContainer,
    #considerDonation,
    #cartActions,
    #cartItemDisplay,
    #ticketHistoryDisplay,
    #registerControl,
    .changeForm,
    .register-update-address,
    .loginForm { //removing bullet points on li tags
        li {
            list-style-type: none;
            text-indent: 0;

            + li {
                padding-top: 0;
            }

            &:before {
                content: none;
            }
        }
    }
    //ace input max-widths
    .loginContainer,
    .ace-profile,
    .register-container,
    #paymentContainer,
    .changeForm {
        .validation-container,
        textarea,
        .select-container,
        label,
        input:not([type=submit]) {
            max-width: $max-width-form-ace;
            min-width: $max-width-form-ace;
        }
    }

    input[type=submit], //buttons
    .submit a,
    .btn {
        @extend %button;
        box-shadow: none; //this and below - overrides for bootstrap
        border-radius: 0 !important; //overriding bootstrap
        font-size: 1rem; //overriding bootstrap
        background: $primary-color;
        text-shadow: none;
        margin-left: 0;
        white-space: pre-wrap;
    }

    .checkList ul li { //checkbox styles
        @extend %input-container;
        @extend %checkbox-input;
    }

    .validation-container { // inline validation
        .errorMsg {
            @extend %input-validation;
            opacity: 1;
            visibility: visible;
            padding-left: $spacer*4;
            position: relative;
            top: -$spacer-primary;

            &:before {
                content: '';
                background-image: url('../static/images/svgs/x-error-light.svg');
                background-repeat: no-repeat;
                width: $spacer-primary/2;
                height: $spacer-primary/2;
                position: absolute;
                left: $spacer;
                top: 50%;
                transform: translateY(-50%);
            }
        }
    }

    .successBox,
    .errorBox { //form validation
        @extend %form-validation;
        max-width: $max-width-form-ace;
        margin-top: $spacer-primary;

        ul {
            padding-left: $spacer-primary/2;
            margin-left: $spacer-primary/2;
            margin-bottom: 0;

            li {
                text-indent: 0;

                + li {
                    padding-top: $spacer-primary/2;
                }

                &:before {
                    content: none;
                }
            }
        }

        &:before {
            content: '';
            height: $spacer-primary/2;
            min-width: $spacer-primary/2;
            background-repeat: no-repeat;
        }
    }

    .errorBox {
        color: $red-validation;

        p,
        ul {
            border-left: 2px solid $red-validation;
        }

        &:before {
            background-image: url('../static/images/svgs/x-error.svg');
        }
    }

    .successBox {
        color: $green-validation;

        p,
        ul {
            border-left: 2px solid $green-validation;
        }

        &:before {
            background-image: url('../static/images/svgs/checkmark.svg');
            margin-right: $spacer-primary/2;
        }
    }

    .bgOverlay { //for ACE page modals
        background-color: rgba(0,0,0,.8);
        width: 100vw !important; //override ace inline style, was more wide than 100vw
        &:after {
            content: '';
            top: $spacer*6;
            right: $spacer*6;
            position: absolute;
            background: url('../static/images/svgs/x-error-light.svg');
            width: $spacer-primary;
            height: $spacer-primary;
            transition: background-color .4s;
            cursor: pointer;
        }
    }

    .selectNewSectionContainer, //modals
    .simpleModal {
        background-color: $offWhite;
        padding: $spacer*6;
        max-width: 90%;
        top: $spacer-large !important; //overrides for ACE inline styles
        bottom: $spacer-large !important;
        overflow-y: auto;

        .btn {
            margin-top: $spacer-primary/2;
            margin-right: $spacer-primary;

            &:last-child {
                margin-right: 0;
            }
        }
    }

    .oldAddress + .newAddress {
        margin-top: $spacer-primary;
    }

    label[for=wantsPermanentAccount] {
        display: none;
    }

    .label-required {
        &:after {
            content: '*';
            color: $red;
        }
    }

    .profile-container, //column containers
    .register-container {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        width: 100%;
    }

    .ace-register, //columns
    .ace-profile {
        .span6 {
            width: 45%;
        }
    }

    .input-message { //below inputs, like on phone input, for additional messaging
        display: inline-block;
        margin-top: -$spacer-primary;
        padding: 3px $spacer $spacer 0;
        max-width: $max-width-form-ace;
        position: relative;
        @extend %small;
    }
    //loading overlay
    .loadingContainer.bgOverlay {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        color: $offWhite;
        z-index: 10;
        text-align: center;
        flex-direction: column;
        justify-content: center;

        .loadingSpinner {
            margin: $spacer-primary auto 0 auto;
        }

        .loadingMessage {
            padding: 0 $spacer*2;
        }

        &:after {
            content: none;
        }
    }
}

@include media(tablet-larger) {
    .ace-page {
        .ace-register,
        .ace-profile {
            .span6 {
                width: 100%;
            }
        }

        .bgOverlay { //for ACE page modals
            &:after {
                top: $spacer*2;
                right: $spacer-primary;
                width: $spacer*2;
                height: $spacer*2;
            }
        }

        .selectNewSectionContainer,
        .simpleModal {
            padding: $spacer*4;
            bottom: $spacer*2 !important;
        }
    }
}

@include media(tablet) {
    .ace-page {
        .bgOverlay { //for ACE page modals
            .btn {
                margin-right: $spacer;
            }
        }
    }
}

@include media(small) {
    .ace-page {
        .bgOverlay { //for ACE page modals
            &:after {
                right: $spacer-primary/2;
            }
        }

        input[type=submit], //buttons
        .submit a,
        .btn {
            //overriding above override for bootstrap
            font-size: 0.9rem;
        }

        .selectNewSectionContainer,
        .simpleModal {
            padding: $spacer*2;

            .btn {
                width: 100%;
                margin-right: 0;
            }

            input {
                width: 100%;
                max-width: 100%;
            }
        }
        //ace input max-widths
        .changeForm,
        #loginContainer,
        .ace-profile,
        .register-container,
        #paymentContainer {
            .successBox,
            .errorBox,
            .validation-container,
            textarea,
            .select-container,
            label,
            input:not([type=submit]) {
                max-width: 100%;
                min-width: 0;
            }
        }
    }
}
