﻿.callout-half {
    height: 100%;
    display: flex;
    flex-direction: column;
    background: $offWhite;
    color: $black;

    &-image {
        width: 100%;

        .callout-half-text-container {
            position: relative;
            flex-grow: 1;

            &:before {
                content: '';
                background-image: url('../static/images/line-pattern-b-w-transparent_16x16.png');
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                z-index: 0;
                pointer-events: none;
            }
        }

        &-container {
            position: relative;
        }

        &-text {
            position: absolute;
            bottom: 0;
        }

        > img {
            @media all and (min-width: 768px) and (max-width: 12660px) {
                height: auto;
                width: 100%;
            }
        }
    }

    &-heading {
        @extend %h3-page-subheading;
        margin-bottom: $spacer-primary;
    }

    &-subheading {
        @extend %font-primary;
    }

    &-text-container {
        padding: $spacer-body $spacer-body-responsive;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;


        > * {
            max-width: 100%; //bug fix for ie
        }
    }
}

.fiftyfifty-callout-quarter {
    .callout-half {
        height: auto;
        min-height: 100vh;
    }
}

@media (min-width: 1281px) { //large screens only
    .callout-half {
        &.fixed {
            position: fixed;
            width: 50%;
        }

        &.fixed.bottom {
            position: fixed;
        }

        &.bottom {
            bottom: 0;
            position: absolute;
        }

        &.top {
            top: 0;
        }

        &-desc {
            margin-bottom: 45px;
        }
    }
}

@include media(large) {
    .callout-half {
        &-image {
            .callout-half-text-container {
                padding: $spacer-large $spacer-primary;
            }
        }

        &-heading {
            margin-bottom: $spacer-primary/2;
        }

        &-text-container {
            padding: $spacer-body $spacer-primary;
        }
    }

    .fiftyfifty-callout-quarter {
        .callout-half {
            min-height: initial;
        }
    }
}

@include media(small) {
    .callout-half {
        display: block;

        &-image .callout-half-text-container,
        &-text-container {
            padding: $spacer-large $spacer*2;
        }
    }
}
