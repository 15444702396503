﻿

.callout-fullwidth {
    height: 100%;
    display: block;
    position: relative;
    overflow: hidden;
    line-height: .5;
    // TODO: Look into if this is neede
    @include media(large) {
        height: auto;
        background: #FFF;
    }

    &-bg {
        height: 100%;

        @include media(large) {
            height: 40vw;
        }

        img {
            height: auto;
            width: 100%;
        }
    }

    &-inner {
        z-index: 1;
        padding-bottom: $spacer-large;
        max-width: 50%;
        transform: translate(1px);
        position: absolute;
        bottom: 20%;
        padding-bottom: 0;
        padding-right: 0 !important;

        @include media(large) {
            position: relative;
            background: #fff;
        }

        @include media(large) {
            position: relative;
            padding: $spacer-primary 0;
            max-width: none;
        }

        @include media(tablet) {
            bottom: 0;
            padding-bottom: $spacer-primary;
        }
    }

    &-video {
        position: relative;
        min-height: 100%;
        width: 100%;
        height: 100%;
        object-fit: cover;
        top: 0;
        background-size: cover;
        background-repeat: no-repeat;

        &-container {
            width: 100%;
            height: 100%;
            overflow: hidden;

            @include media-min(large) {
                max-height: 100vh;
            }
        }

        *::-webkit-media-controls-start-playback-button {
            display: none !important;
            -webkit-appearance: none;
        }
        //youtube styles
        .video-background {
            /*position: absolute;*/
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            transition: opacity .4s;
            position: relative;
            min-height: 75vh;

            @include media(small) {
                min-height: 35vh;
            }

            @include media-min(small) {
                min-height: 65vh;
            }

            @include media-min(large) {
                min-height: 100vh;
            }

            &.videoHasEnded {
                opacity: 0;
                cursor: pointer;
                pointer-events: all;
            }
        }

        .video-foreground,
        .video-background iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }

        @media (min-aspect-ratio: 16/9) {
            .video-foreground {
                height: 300%;
                top: -100%;
            }
        }

        @media (max-aspect-ratio: 16/9) {
            .video-foreground {
                width: 300%;
                left: -100%;
                //height: 150%;
                @include media(small) {
                    height: 100%;
                }
            }
        }
    }
    //end youtube styles
    &-subheading {
        @include media-min(x-large) {
            transform: translateY(-$spacer);
        }

        @include media-min(large) {
            transform: translateY(0);
        }

        span {
            font-style: italic;
            margin-bottom: 0;
            text-transform: capitalize;
            padding: $spacer-primary $spacer-primary $spacer $spacer-primary;

            @include media(small) {
                padding: $spacer $spacer-primary/2 $spacer/2 $spacer-primary/2;
            }
        }
    }

    &-heading {
        @include media-min(large) {
            line-height: 3.25rem;
        }

        @include media-min(x-large) {
            *line-height: 4.25rem;
        }

        span {
            @extend %h1-page-heading;
            padding: 0 $spacer-primary;

            @include media(large) {
                font-size: 2.39rem;
                line-height: 2.51rem;
            }

            @include media-min(large) {
                font-size: 3.2rem;
                line-height: 3.5rem;
            }

            @include media-min(x-large) {
                font-size: 4.5rem;
                line-height: 4.85rem;
            }
        }
    }

    + .button {
        margin-top: $spacer*4;
    }


    &-desc {
        span {
            @extend %h5-primary;
            padding: 4px $spacer-primary;


            @include media(large) {
                font-size: 1.17rem;
                line-height: 1.29rem;
            }
        }
    }

    &-cta {
        margin-top: $spacer*7;
    }

    &-date {
        transform: translateY(10px);

        span {
            @extend %h6;
            @extend %heading-primary;
            font-size: 100%;
            padding: $spacer $spacer-primary $spacer-primary;

            @include media-range(large, x-large) {
                padding-top: 20px;
            }
        }

        + .button {
            margin-top: $spacer-body-responsive;
        }
    }
    /*--TBD: Determine if this is necessary--*/
    /*.button {
        background-color: $primary-color;
        border-color: $primary-color;

        &:hover {
            color: $black;
        }

        &:active,
        &:focus {
            color: $offWhite;
        }
    }*/

    &-subheading,
    &-heading,
    &-desc,
    &-date {
        margin: 0;

        span {
            color: $black;
            background-color: $offWhite;
            box-decoration-break: clone;
        }
    }
}

@include media(large) {
    .callout-fullwidth {

        &-inner {
            max-width: 100%;

            &.full-width-inner {
                padding-left: 0;
                padding-right: 0;
            }
        }

        &-subheading {
            transform: translateY(0);
        }

        &-date {
            transform: translateY(5px);

            + .callout-fullwidth-cta {
                margin-top: 40px;
            }
        }
    }
}

@include media(large) {
    .callout-fullwidth {


        &-inner {
            padding-bottom: $spacer-primary;
            max-width: 100%;
            width: 100%;
            background-color: $offWhite;

            &.full-width-inner {
                padding-left: 0;
                padding-right: 0;
            }
        }

        &-subheading {
            transform: translateY(0); //align subheading text to top of 'inner' box
        }

        &-heading,
        &-desc,
        &-date {
            transform: translate(0);

            span {
                margin: 0;
                display: block;
            }
        }

        .button {
            display: block;
            width: calc(100% - 60px);
            margin-left: $spacer-primary;
            margin-right: $spacer-primary;
        }
    }
}

@include media(tablet) {
    .callout-fullwidth {

        &-video,
        .video-background {
            position: relative;
            object-fit: contain;
        }


        + .overview-block {
            padding-top: 0;
        }

        .button {
            display: block;
            width: calc(100% - 60px);
        }
    }
}

@include media(small) {
    .callout-fullwidth {


        &-inner {


            &.full-width-inner {
                padding-left: 0;
                padding-right: 0;
            }
        }

        &-heading,
        &-desc,
        &-date {
            span {
                padding: 0 $spacer*2;
            }
        }

        .button {
            width: calc(100% - 40px);
            margin-left: $spacer*2;
            margin-right: $spacer*2;
        }
    }
}


.callout-fullwidth-date {
    transform: translateY(10px);
    margin-bottom: 40px;
}
