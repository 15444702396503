﻿/// <reference path="../../main.scss" />

.renewals {

    @extend %vertical-block-padding;

    &-intro {
        @extend %vertical-block-padding;
        @extend %gutter-padding;


        @include media-min(small) {
            display: flex;
        }
    }

    &-media {
        @extend %one-third-column;
        margin-right: $spacer-primary;

        @include media(tablet-larger) {
            min-width: 50%;
        }

        @include media(small) {
            width: 100%;
            margin-bottom: $spacer-primary;
        }
    }

    &-content {
        @extend %one-third-column;
    }

    &-info {
        @include media-min(tablet-larger) {
            display: flex;
        }
    }

    &-header {
        @extend %h4;
        @extend %heading-accent;
    }

    &-ctas {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        * + * {
            margin-top: $spacer-primary;
        }

        &-link {
            padding-top: 6px;
        }
    }

    &-upsell {
        max-width: $max-width-form;
        padding-right: $spacer-med;
    }
}
