﻿/// <reference path="../../main.scss" />
.gallery-app {
    padding-top: $spacer-body-responsive;
    padding-bottom: $spacer-body-responsive * 2;
    padding-right: 0 !important;
    min-height: 50vh;
    padding-left: $spacer-body-responsive;
    padding-right: $spacer-body-responsive;
    overflow: hidden;

    @include media(large) {
        padding-left: $spacer-primary;
        padding-right: $spacer-primary;
    }

    @include media(small) {
        padding-left: $spacer-rem;
        padding-right: $spacer-rem;
    }

    &-border {
        @extend %gutter-padding;
        padding-left: 0;
        margin-bottom: $spacer-primary;

        span {
            display: block;
            width: 100%;
            height: 4px;
            margin: 0;
            background: $black;
        }
    }

  
}

@include dark-theme() {
    .gallery-app-border span {
        background: $offWhite;
    }
}
