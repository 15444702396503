/// <reference path="../../main.scss" />

.magazine-landing {

    &-header {
        &-content {
            flex-grow: 1;
        }

        &-footer {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;

            @include media($otc-main-breakpoint) {
                flex-direction: column;
                justify-content: flex-start;
            }
        }
    }

    &-page-title {
        @extend %h1-page-heading;
        margin-bottom: $spacer;
    }
}

.magazine-featured-article {

    &-container {
        padding-top: $spacer-body-responsive;
        padding-bottom: $spacer-body-responsive;
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: $spacer-primary;
        @extend %gutter-padding;

        @include media(small) {
            grid-template-columns: 1fr;
        }
    }

    &-image {
        aspect-ratio: 16/9;
        position: relative;

        @include media(medium) {
            aspect-ratio: 4/3;
        }

        @include media(small) {
            aspect-ratio: 16/9;
        }


        img {
            position: absolute;
            object-fit: cover;
            height: 100%;
            width: 100%;
        }
    }

    &-container .unlink {
        //win theme specificity wars
        padding: 0 !important;
        white-space: inherit !important;
    }

    &-title {
        @extend %h3-page-subheading;
        @extend %underline-link;
        margin: $spacer 0 !important;
    }

    &-content {
        display: flex;
        flex-direction: column;
        gap: $spacer;

        p {
            margin: 0;
            padding: 0;
        }

        .rich-text {
            margin: $spacer-md 0;
        }
    }

    &-categories {
        @extend %list-reset;
        display: flex;
        gap: $spacer;
        flex-wrap: wrap;

        &-tag {
            @extend %list-item-reset;
        }
    }
}

.split-article {

    @include media-range(small, medium) {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 30px;
    }

    &-container {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: $spacer-primary;

        @include media(medium) {
            .otc-card-image {
                aspect-ratio: 4/5;
            }
        }

        @include media(medium) {
            grid-template-columns: 1fr;
        }
    }
}
