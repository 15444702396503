﻿/// <reference path="../../main.scss" />

@mixin link-helper($color: $black, $contrast: $offWhite) {
    color: $color;
    padding: 0 4px 2px 4px;
    white-space: pre-wrap;
    border-bottom: 4px solid $color;
    transition: all .4s;
    outline: 0;
    -webkit-tap-highlight-color: rgba(0,0,0,0);


    &:hover, &:active {
        padding-top: 6px; //height of bottom border and bottom padding. equal space top and bottom on hover
        border-color: transparent;
        background: $color;
        color: $contrast;
    }
}
