﻿$calendar-padding-xs: 80px;
$calendar-padding-s: 100px;
$calendar-padding-m: 120px;
$calendar-padding-l: 0px;

.calendar-filters {
    padding: 0 $spacer*2 $calendar-padding-xs;

    &-labelRow {
        display: flex;
        justify-content: space-between;
        margin-top: $spacer-primary;
        margin-bottom: $spacer/2;
    }

    &-label,
    &-clear {
        margin-bottom: 0;
    }

    &-label {
        @extend %font-primary-bold;
    }

    &-clear {
        text-transform: uppercase;
        cursor: pointer;

        &:hover {
            @extend %font-primary-bold;
        }
    }

    &-buttons {
        display: flex;

        button {
         
                @extend %button-secondary;
                border-color: $offWhite;
                background: transparent;
                color: $offWhite;
                font-size: .8rem;
                padding-left: 0;
                padding-right: 0;

                &:focus {
                    border-color: transparent;
                }

                &.active {
                    background-color: $offWhite;
                    border-color: $offWhite;
                    color: $primary-color;
                }
            }
        
    }


    &-days {
        .calendar-filters-buttons {
            + .calendar-filters-buttons {
                margin-top: $spacer*2;
            }
        }

        button {
            flex-basis: 22.75%;
            width: 22.75%;

            + button {
                margin-left: 3%;
            }
        }
    }

    &-members {
        .calendar-filters-buttons {
            flex-wrap: wrap;
            justify-content: space-between;
            width: calc(100% + 10px);

            button {
                margin-bottom: $spacer*2;
                flex-grow: 1;
                min-width: calc(50% - 10px);
                margin-right: $spacer;
            }
        }
    }

    &-radio {
        display: block;
        @extend %font-primary;
        margin-top: $spacer*2;
        display: flex;
        align-items: center;
        cursor: pointer;

        input {
            margin-right: $spacer;
            width: $spacer-primary/2;
            height: $spacer-primary/2;
            position: relative;

            &:checked:after {
                content: '';
                background-color: $black;
                width: $spacer;
                height: $spacer;
                top: 2px;
                left: 2px;
                border-radius: 50%;
                display: block;
                position: absolute;
            }
        }
    }

    .select-container,
    select {
        margin: 0;
    }

    &-autocomplete {
        position: relative;

        &-item {
        }

        input {
            margin-bottom: 0;
        }

        &-panel {
            position: absolute;
            top: 100%;
            left: 0;
            width: 100%;
            background: #fff;
            border-left: 1px solid #000;
            border-right: 1px solid #000;
            z-index: 10;
            max-height: 300px;
            overflow-y: scroll;

            & > ul {
                list-style-type: none !important;
                color: #000;
                margin-left: 0;

                & > li {
                    text-indent: 10px;

                    &:hover, &:focus {
                        background-color: $lightGray;
                    }
                }

                & > li:before {
                    display: none;
                }

                & > li + li {
                    padding-top: 10px;
                }
            }

            &[aria-hidden="true"] {
                overflow: hidden;

                & > ul {
                    visibility: hidden;
                    max-height: 0;
                    opacity: 0;
                    margin: 0;
                }
            }
        }
    }
}

@include media-min(small) {
    .calendar-filters {
        padding: 0 0 $calendar-padding-s;
    }
}

@include media-min(tablet) {
    .calendar-filters {
        padding: 0 0 $calendar-padding-m;
    }
}

@include media-min(medium) {
    .calendar-filters {
        padding: 0 0 $calendar-padding-l;
    }
}
