/// <reference path="../../main.scss" />
.otc-subcategory {
    &-results {
        display: grid;
        grid-template-columns: repeat(3, minmax(0, 1fr));
        gap: $spacer-primary;
        padding-top: $spacer-md;
        @extend %list-reset;

        @include media(tablet-larger) {
            grid-template-columns: repeat(2, minmax(0, 1fr));
        }

        @include media(small) {
            grid-template-columns: 1fr;
        }

        .otc-listing-article {
            margin-top: 0;
            @extend %list-item-reset;
            border-bottom: 0;

            &-content {
                width: 100%;
                display: flex;
                flex-direction: column-reverse;
            }
            &-image {
                order: 1 !important;
            }
        }
    }
}

.otc-sort-container {
    margin-bottom: $spacer-primary;
    }