﻿body.nav-open {
    overflow: hidden;

    @include media(tablet) {
        position: fixed;
    }

    & > main {
        position: fixed;
    }
}

header {
    .mobile {
        display: none;



        &-inner {
            width: 100%;
            display: flex;
            align-content: center;
            justify-content: space-between;
            align-items: center;
        }

        .header-logo-link {
            flex-grow: 1;
        }

        .header-hamburger {
            margin: $spacer*1.8 $spacer*1.5 $spacer 0;
            cursor: pointer;
            width: 35px;
            height: 30px;
            position: relative;
            transform: rotate(0deg);
            transition: .5s ease-in-out;

            &.open {
                span {
                    &:nth-child(1) {
                        top: 18px;
                        width: 0%;
                        left: 50%;
                    }

                    &:nth-child(2) {
                        transform: rotate(45deg);
                    }

                    &:nth-child(3) {
                        transform: rotate(-45deg);
                    }

                    &:nth-child(4) {
                        top: 18px;
                        width: 0%;
                        left: 50%;
                    }
                }
            }

            span {
                transform: rotate(0deg);
                display: block;
                position: absolute;
                height: 3px;
                width: 100%;
                background: $offWhite;
                opacity: 1;
                left: 0;
                transition: .25s ease-in-out;

                &:nth-child(1) {
                    top: 0px;
                }

                &:nth-child(2),
                &:nth-child(3) {
                    top: 10px;
                }

                &:nth-child(4) {
                    top: 20px;
                }
            }
        }

        .header-nav-utility {
            &-item {
                display: flex;
                align-items: center;

                img {
                    width: $spacer*2.5;
                    height: $spacer*2.5;
                    margin-right: $spacer;
                }
            }
        }

        @include media(tablet-larger) {
            display: block;

            .header-logo-img {
                width: $spacer-primary*2;
                height: $spacer-primary*2;
                min-width: $spacer-primary*2;
            }
        }
    }

    &.nav-open .mobile {
        max-height: 100%;
        overflow-y: scroll;
    }

    .nav-mobile.open {
        justify-content: flex-start;

        .nav-main {
            flex-grow: 0;
        }
    }
}
