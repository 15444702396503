﻿.fiftyfifty-wysiwyg {
    &-heading {
        @extend %h5-primary;
    }

    + .fiftyfifty-wysiwyg {
        margin-top: $spacer*4;
    }

    p:last-child {
        margin-bottom: 0;
    }
}
