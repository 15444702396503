﻿.libfiftyfifty {
    display:flex;
    width: 100%;
    & > * {
        flex-grow: 1;
        flex-shrink: 0;
        flex-basis: 50%;
    }
    &-textright {
        flex-direction:row-reverse;
    }
}

