﻿/// <reference path="../../main">
.autocomplete {
    position: relative;
    // Repeated pattern from ace forms
    min-width: $max-width-form-ace;
    margin-bottom: $spacer-primary;

    @include media-min(tablet) {
        max-width: $max-width-form-ace;
    }

    .suggestions {
        position: absolute;
        top: 88px;
        left: 0;
        right: 0;
        margin: 0;
        width: 100%;
        z-index: 10;
        background: $offWhite;
        border-left: 2px solid $black;
        border-right: 2px solid $black;
        border-bottom: 2px solid $black;
        z-index: 10;
        max-height: 300px;
        overflow-y: scroll;

        & > li {
            padding: $spacer / 2 !important;

            &.current {
                background-color: $lightGray;
            }
        }
    }


    .searchInput {
        margin: 0;
    }

    .validation-container .errorMsg {
        top: 0;
    }


    &.no-results {
        .suggestions {
            border: none;
        }
    }
}
//override for login page
.login-update-address {
    .autocomplete {
        max-width: 560px;
    }
}
