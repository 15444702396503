/// <reference path="../../main.scss" />
.otc-filters {
    @extend %list-reset;
    display: flex;
    flex-direction: column;
    gap: $spacer;
    padding-bottom: $spacer-md;

    &-category {
        @extend %list-item-reset;
        text-indent: 0;
        padding-top: 0;
    }

    &-container {
        border-right: 1px solid $black;
        padding-right: $spacer-primary;
        margin-right: $spacer-primary;


        @include media($otc-main-breakpoint) {
            border-right: 0;
            margin: 0 0 $spacer-md;
            padding: 0;
            border-bottom: 1px solid $black;
        }
    }

    .checkbox-container {
        margin-bottom: 0;
    }

    &-desktop-header {
        @include media($otc-main-breakpoint) {
            display: none;
        }
    }

    &-mobile-trigger {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        cursor: pointer;
        margin-bottom: $spacer-md;

        @include media-min($otc-main-breakpoint) {
            display: none;
        }

        &-title {
            margin: 0;
        }

        &-btn {
            background: url('../static/images/svgs/dropdown-arrow.svg') no-repeat center;
            background-size: contain;
            background-repeat: no-repeat;
            width: $spacer-md;
            height: $spacer-md;
            transition: $transition;

            &.show {
                transform: rotate(180deg);
            }
        }
    }
}
