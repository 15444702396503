/// <reference path="../../main.scss" />
// ------------- GENERAL TAGS ------------- //

body {
    line-height: 1.5em;
    @extend %font-primary;
    color: $black;

    ::selection {
        background-color: $primary-color;
        color: $offWhite;
    }

    ::-moz-selection {
        background-color: $primary-color;
        color: $offWhite;
    }
}

a {
    cursor: pointer;
    text-decoration: none;

    &:focus,
    &:active,
    &:hover {
        text-decoration: none;
    }
}

%primary-link {
    color: $black;
    padding: 0 4px 2px 4px;
    white-space: pre-wrap;
    border-bottom: 4px solid $black;
    transition: all .4s;
    outline: 0;
    -webkit-tap-highlight-color: rgba(0,0,0,0);

    &:hover,
    &:focus,
    &:active {
        padding-top: 6px; //height of bottom border and bottom padding. equal space top and bottom on hover
        border-color: transparent;
        background: $black;
        color: $offWhite;
    }
}

%unlink, .unlink, .unlink a, unlink p {
    &:nth-child(n):nth-child(n) {
        white-space: normal;
        background: none;
        text-decoration: none;
        border: none;
        padding: 0;
        color: var(--foreground-color);

        &:active, &:focus, &:hover {
            background: none;
            text-decoration: none;
            border: none;
            padding: 0;
            color: var(--foreground-color);
        }
    }
}



main {
    a {
        @include link-helper();
    }
}


%modal-link { //used when a link opens a modal, not a new page
    color: $primary-color;
    text-transform: uppercase;
    border-bottom: 1px solid transparent;
    padding: 0;

    &:hover,
    &:focus,
    &:active {
        padding: 0; //overrides ace
        color: $primary-color; //overrides ace
        background-color: transparent;
        border-bottom: 1px solid $primary-color;
    }
}

%nav-item {
    text-transform: uppercase;
    @extend %font-primary-bold;
    letter-spacing: 1px;
}

b, strong, .bold {
    font-weight: 700;
}

em, i {
    font-style: italic;
}

.caption {
    @extend %small;
    font-style: italic;
}

// ------------- HEADING TAGS ------------- //

//headings general
h1, %h1,
h2, %h2,
h3, %h3,
h4, %h4,
h5, %h5,
h6, %h6 {
    max-width: $max-width-content;
    @extend %font-primary;
}

h1,
%h1 {
    font-size: 5.6rem;
    line-height: 5.72rem;
    margin-bottom: $spacer-text-margin;
}

h2,
%h2 {
    font-size: 4.2rem;
    line-height: 4.34rem;
    letter-spacing: 2px;
    margin-bottom: $spacer-text-margin;
}

h3,
%h3 {
    font-size: 3.2rem;
    line-height: 3.32rem;
    margin-bottom: $spacer-primary/2;
}

h4,
%h4 {
    font-size: 2.4rem;
    line-height: 2.52rem;
    margin-bottom: $spacer-primary/2;
}

h5,
%h5 {
    font-size: 2rem;
    line-height: 2.12rem;
    margin-bottom: $spacer-primary/2;
}

h6,
%h6 {
    font-size: 1.28rem;
    line-height: 1.4rem;
    margin-bottom: $spacer-primary/2;
}

//headings primary font (book)
.heading-primary,
%heading-primary {
    @extend %font-primary-bold;
    text-transform: uppercase;
    letter-spacing: 1px;
}

.h4-primary,
%h4-primary {
    @extend %h4;
    @extend %heading-primary;
    line-height: 2.9rem;
}

.h5-primary,
%h5-primary {
    @extend %h5;
    @extend %heading-primary;
    line-height: 2.5rem;
}

.h6-primary,
%h6-primary {
    @extend %h6;
    @extend %heading-primary;
    line-height: 1.78rem;
}

.p-heading,
%p-heading {
    @extend %heading-primary;
    letter-spacing: 0.5px;
}

//headings accent font (narrow)
%heading-accent {
    text-transform: uppercase;
    @extend %font-accent-bold;
}

h1.page-heading,
%h1-page-heading {
    @extend %h1;
    @extend %heading-accent;
    letter-spacing: 3px;

    @include media-min(large) {
        max-width: 1100px;
    }

    @include media-min(x-large) {
        max-width: 1300px;
    }
}

h2.section-heading,
%h2-section-heading {
    @extend %h2;
    @extend %heading-accent;
}

h3.page-subheading,
%h3-page-subheading {
    @extend %h3;
    @extend %heading-accent;
    letter-spacing: 2px;
}

h4.page-subheading,
%h4-page-subheading {
    @extend %h4;
    //@extend %heading-accent;
    letter-spacing: 2px;
}

h5.paragraph-heading,
.h5-paragraph-heading,
%h5-paragraph-heading {
    @extend %h5;
    @extend %heading-accent;
    letter-spacing: 2px;
}

h6.content-heading,
.h6-content-heading,
%h6-content-heading {
    @extend %h6;
    @extend %heading-accent;
    letter-spacing: 1px;
}

// ------------- NON-HEADING TEXT TAGS ------------- //

p {
    line-height: 1.5rem;
    margin-bottom: $spacer-primary;
    max-width: $max-width-content;
    word-break: break-word;

    + h2, + %h2,
    + h3, + %h3,
    + h4, + %h4,
    + h5, + %h5,
    + h6, + %h6 {
        margin-top: $spacer-primary*2;
    }

    &.no-space {
        margin: 0;
    }
}

small,
.small,
%small {
    font-size: 0.8rem;
    line-height: 1.3rem;
}

large,
.large,
%large {
    font-size: 2rem;
    line-height: 1.25;

    @include media(tablet) {
        font-size: 1.35rem;
        line-height: 1.1;
    }
}

blockquote,
.blockquote,
%blockquote {
    margin-bottom: $spacer*2;
    @extend %h4-primary;
    text-transform: none;

    &:before {
        content: "\201c";
        margin-right: $spacer;
    }

    &:after {
        content: "\201d";
        margin-left: $spacer;
    }

    + %attribution,
    + .attribution {
        margin-top: 0;
    }
}

.blockquote-small,
%blockquote-small {
    font-size: 24px;
    line-height: 27px; /* 112.5% */
    letter-spacing: 0.96px;
    text-transform: none;
    padding: 0 $spacer-md;
    @extend %font-accent-bold;
    position: relative;

    &:before {
        content: "\201c";
        margin-right: $spacer;
        font-size: 76px;
        position: absolute;
        left: -$spacer-md;
    }
}

%attribution,
.attribution {
    text-transform: uppercase;
    display: flex;
    max-width: $max-width-content;
    @extend %h6;
    text-transform: uppercase;

    &:before {
        content: '';
        border-top: 4px solid $black;
        width: $spacer-primary;
        margin-right: $spacer-primary/2;
        margin-top: $spacer/2;
    }
}

// ------------- LIST TAGS ------------- //

ol, ul {
    max-width: $max-width-content;
    margin: 0 0 $spacer-primary 35px;
    position: relative;

    li {
        list-style-type: none;

        + li {
            padding-top: $spacer-primary;
        }

        &:before {
            display: inline-block;
            padding-right: 1em;
        }
    }
}

ol {
    counter-reset: item;

    li {
        counter-increment: item;
        text-indent: -35px;

        &:before {
            width: 2em;
            @extend %font-primary-bold;
            text-align: right;
            content: counter(item) ".";
            user-select: none;
        }
    }
}
/*---- TBD: Create Mixin to handle Bullet Theming---- */

// Handle Bullet Styling for first and second level deep lists
%bullet-sizing {
    width: $spacer;
    height: $spacer;
    transform: translateX(-$spacer);
}

%bullet-styling {
    content: '';
    background: url('../static/images/svgs/bullet-circle.svg') no-repeat;
    @extend %bullet-sizing;
}

%bullet-styling-inverted {
    content: '';
    background: url('../static/images/svgs/bullet-circle-inverted.svg') no-repeat;
    @extend %bullet-sizing;
}

%bullet-styling-light {
    content: '';
    background: url('../static/images/svgs/bullet-circle-light.svg') no-repeat;
    @extend %bullet-sizing;
}

%bullet-styling-light-inverted {
    content: '';
    background: url('../static/images/svgs/bullet-circle-light-inverted.svg') no-repeat;
    @extend %bullet-sizing;
}

ul {
    li {
        text-indent: -$spacer*2;

        &:before {
            @extend %bullet-styling;
        }

        ul {
            margin-bottom: 0;

            li:before {
                @extend %bullet-styling-inverted;
            }

            li:first-child {
                padding-top: 30px;
            }

            ul li:before {
                @extend %bullet-styling;
            }
        }
    }
}

.list-reset, %list-reset {
    margin: 0;
    max-width: none;

    li {
        text-indent: 0;
        list-style-type: none;
        padding-top: 0 !important;
    }

    li + li {
        padding: 0;
    }
}

%list-item-reset {
    &:before {
        content: none !important;
    }

    text-indent: 0;
}

// Anchor links
.anchor-link-offset {
    scroll-margin-top: var(--nav-clearance);

    @include media(tablet) {
        scroll-margin-top: calc(var(--nav-clearance) + 25px);
    }
}



@include media(large) {
    //headings general
    h1,
    %h1 {
        font-size: 3.66rem;
        line-height: 3.78rem;
        margin-bottom: $spacer-primary/2;
    }

    h2,
    %h2 {
        font-size: 2.83rem;
        line-height: 2.95rem;
        margin-bottom: $spacer*2;
        letter-spacing: 1px;
    }

    h3,
    %h3 {
        font-size: 2.11rem;
        line-height: 2.23rem;
    }

    h4,
    %h4 {
        font-size: 1.55rem;
        line-height: 1.67rem;
    }

    h5,
    %h5 {
        font-size: 1.33rem;
        line-height: 1.45rem;
    }

    h6,
    %h6 {
        font-size: 1.16rem;
        line-height: 1.28rem;
    }
    //headings primary font (book)
    .h4-primary,
    %h4-primary {
        line-height: 2.05rem;
    }

    .h5-primary,
    %h5-primary {
        line-height: 1.83rem;
    }

    .h6-primary,
    %h6-primary {
        line-height: 1.66rem;
    }
    //headings accent font (narrow)
    %heading-accent {
        letter-spacing: 1px;
    }
}

@include media(tablet) {
    //headings general
    h1,
    %h1 {
        font-size: 3.05rem;
        line-height: 3.17rem;
    }

    h2,
    %h2 {
        font-size: 2.33rem;
        line-height: 2.45rem;
    }

    h3,
    %h3 {
        font-size: 1.83rem;
        line-height: 1.95rem;
    }

    h4,
    %h4 {
        font-size: 1.44rem;
        line-height: 1.56rem;
    }

    h5,
    %h5 {
        font-size: 1.33rem;
        line-height: 1.45rem;
    }

    h6,
    %h6 {
        font-size: 1.16rem;
        line-height: 1.28rem;
    }
    //headings primary font (book)
    .h4-primary,
    %h4-primary {
        line-height: 1.94rem;
    }
}

@include media(small) {
    //headings general
    h1,
    %h1 {
        font-size: 2.39rem;
        line-height: 2.51rem;
    }

    h2,
    %h2 {
        font-size: 2rem;
        line-height: 2.12rem;
    }

    h3,
    %h3 {
        font-size: 1.56rem;
        line-height: 1.68rem;
    }

    h4,
    %h4 {
        font-size: 1.33rem;
        line-height: 1.45rem;
    }

    h5,
    %h5 {
        font-size: 1.17rem;
        line-height: 1.29rem;
    }

    h6,
    %h6 {
        font-size: 1rem;
        line-height: 1.12rem;
    }
    //headings primary font (book)
    .h4-primary,
    %h4-primary {
        line-height: 1.83rem;
        letter-spacing: 0.5px;
    }

    .h5-primary,
    %h5-primary {
        line-height: 1.67rem;
        letter-spacing: 0.5px;
    }

    .h6-primary,
    %h6-primary {
        line-height: 1.5rem;
    }

    p {
        line-height: 1.5625rem;

        + h2, + %h2,
        + h3, + %h3,
        + h4, + %h4,
        + h5, + %h5,
        + h6, + %h6 {
            margin-top: $spacer-primary;
        }
    }

    %small,
    .small,
    small {
        font-size: 0.875rem;
        line-height: 1.4375rem;
    }

    main {
        a {
            padding: 0 2px 2px 2px;
            border-bottom: 2px solid $black;

            &:hover,
            &:focus,
            &:active {
                padding-top: 4px; //height of bottom border and bottom padding. equal space top and bottom on hover
            }
        }
    }
}

%remove-link {
    border-color: $primary-color;
    border-width: 4px;

    &:hover {
        border-width: 4px;
        border-color: transparent;
    }
}
%remove-link {
    border-color: $primary-color;
    border-width: 4px;

    &:hover {
        border-width: 4px;
        border-color: transparent;
    }
}


// For use in edge case overrides
%light-links {
    border-bottom: 4px solid $black;
    color: $black;

    &:hover,
    &:focus,
    &:active {
        border-color: transparent;
        background: $black;
        color: $offWhite;
    }
}

%dark-links {
    border-bottom: 4px solid $offWhite;
    color: $offWhite;

    &:hover,
    &:focus,
    &:active {
        border-color: transparent;
        background: $offWhite;
        color: $black;
    }
}

%otc-links {
    // override theming for OTC pages
    border-bottom: 4px solid $otc-orange !important;
    color: $otc-orange !important;

    &:hover,
    &:focus,
    &:active {
        border-color: transparent !important;
        background: $black !important;
        color: $white !important;
    }
}

%underline-link {
    border-bottom: none !important;
    text-decoration: underline !important;
    color: inherit !important;
    padding: 0 !important;

    &:hover, &:active {
        text-decoration: none !important;
        background: none !important;
    }
}
