﻿/// <reference path="../../main">

$member-form-font-size: .9em;
$font-weight-bold: 700;
$font-weight-normal: 400;

// Membership label
.membership-portal .member-label, %member-label {
    margin-bottom: 0;

    &:after {
        display: none;
    }
}

// Membership Form text
.membership-portal .member-form-text, %member-form-text {
    font-size: $member-form-font-size;
    margin-bottom: 0;
}

// Membership label bold
.membership-portal .member-label-bold, %member-label-bold {
    @extend %member-label;
    font-weight: $font-weight-bold;
}
// Membership label bold caps
.membership-portal .member-label-bold-caps, %member-label-bold-caps {
    @extend %member-label-bold;
    text-transform: uppercase;
}
// Membership label heading
.membership-portal .member-label-header, %member-label-header {
    @extend %font-accent-bold;
    @extend %h6;
    text-transform: uppercase;
    letter-spacing: 2px;
    margin-bottom: $spacer;
}

.errorMsg {
    color: $red-validation;
}

.successMsg {
    color: $green-validation;
}

.hideField {
    display: none;
}

.showField {
    display: block;
}

%smaller-check-box-radios {
    &:before {
        margin-top: 6px;
        min-width: 18px;
        height: 18px;
        margin-right: 12px;
    }
}

// ------------- Page Styles ------------- //
.membership-portal {
    display: flex;
    justify-content: space-between;
    padding-bottom: $spacer-large;

    &-item {
        flex-basis: 48%;
        width: 48%;
    }

    .membership-auto-renew {
        &-messaging {
            .defaultMsg, .errorMsg, .successMsg {
                font-size: $member-form-font-size;
                font-weight: $font-weight-bold;
                line-height: normal;
                margin-bottom: $spacer;
            }

            .member-form-text { // wysiwig styles
                > * {
                    font-size: $member-form-font-size;
                    font-weight: $font-weight-bold;
                    line-height: normal;
                    margin-bottom: 0;
                }
            }
        }

        &-opt-in {
            @extend %member-label-bold-caps;
            @extend %small;
            line-height: 2;
            margin-bottom: $spacer;
            @extend %smaller-check-box-radios;
        }

        &-link {
            display: inline-block;
            margin-bottom: $spacer-md;
        }

        &-hideLink {
            display: none;
        }
    }

    .membership-section {
        &-label {
            font-weight: $font-weight-bold;
            margin-bottom: 0;
        }

        .button-secondary {
            margin-bottom: $spacer-primary;
        }
    }

    .auto-renew-form {
        display: none;

        &-header {
            @extend %member-label-bold-caps;
            margin-bottom: $spacer-md;

            &-cancel {
                @extend %modal-link;
                margin-left: $spacer-md;
                font-weight: $font-weight-normal;
            }
        }

        &-payment-options {
            &-list {
                margin-left: 0;

                &-item {
                    list-style-type: none;
                    text-indent: 0;

                    &:before {
                        display: none;
                    }

                    .radio-container {
                        margin-bottom: 0;

                        label {
                            @extend %smaller-check-box-radios;
                            font-style: italic;
                        }

                        &.default-payment {
                            label {
                            font-weight: $font-weight-bold;
                            }
                        }
                    }

                    .invalid-card {
                        > * {
                            color: $gray;
                        }
                    }
                }
            }

            &-card-label {
                @extend %member-label-bold;
            }
        }

        &-expiration {
            .select-container {
                margin-bottom: $spacer-md;

                &:last-child {
                    margin-bottom: $spacer;
                }
            }
        }

        &-make-default {
            .radio-container {
                display: block;

                label {
                    @extend %member-label;
                    font-style: italic;
                    @extend %smaller-check-box-radios;
                }
            }
        }
    }

    .auto-renew-form.is-visible {
        display: block;
    }

    ul li:before {
        display: none;
    }
}

@include media(tablet-larger) {
    .membership-portal {
        display: block;

        &-item {
            width: 100%;
        }

        .benefits-section {
            padding-top: $spacer*2;
        }
    }
}
