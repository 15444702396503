﻿.full-carousel-item {
    box-shadow: 0px 2px 5px 1px rgba(0,0,0,0.05);
    width: 48%;
    padding-bottom: $spacer*2;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    transition: box-shadow .3s;

    &:hover {
        box-shadow: 0px 2px 10px 1px rgba(0,0,0,0.1);
    }

    * {
        margin: $spacer*2 $spacer*2 0;
    }

    &-img-container {
        margin: 0;
        position: relative;
        width: 100%;
        flex-grow: 0 !important;
    }

    &-img {
        width: 100%;
        margin: 0;

        &-date {
            position: absolute;
            bottom: 0;
        }
    }

    &-img-date,
    &-date {
        padding: $spacer $spacer*2;
        background-color: $primary-color;
        color: $offWhite;
        margin: 0;
        @extend %h6-content-heading;
    }

    &-subheading {
        color: $primary-color;
        @extend %h6-content-heading;
        font-weight: 400; //book
        + %h5-paragraph-heading {
            margin-top: $spacer;
        }

        &:empty {
            display: none;
        }
    }

    &-heading {

        @extend %h5-paragraph-heading;
        color: $black;
        border: none;
        background: none;
        padding: 0;
        padding: $spacer;
        margin-left: $spacer;
        transition: color .3s, background-color .3s;


        &:hover,
        &:focus,
        &:active {
            padding: $spacer;
            color: $offWhite;
            background-color: $black;
        }

        + * {
            margin-top: $spacer;
        }
    }

    &-time {
        @extend %p-heading;
        letter-spacing: 1px;
    }

    &-cta {

        @extend %button-secondary;
        box-sizing: border-box;


        &:empty {
            display: none;
        }
    }

    &-content {
        height: 100%;
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin: 0;

        &-inner {
            flex-grow: 1;
            margin: 0;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
        }
    }
}

.full-carousel, .season-overview {
    .full-carousel-item { //prevents meganav from getting these styles
        background-color: $offWhite;
        color: $black;


        &-container {
            margin: 0;
            max-width: 100%;
            position: relative;
        }

        &-img-date:empty,
        &-date:empty {
            display: none;
        }

        &-time {
            color: $black;
        }
    }
}

.full-carousel {
    .full-carousel-item {
        width: 100%;
    }
}

.meganav {
    .full-carousel-item {
        * {

            &:nth-last-child(2) { //second to last, keeps btn on bottom
                flex-grow: 1;
            }
        }
    }
}

@include media(large) {
    .full-carousel-item {
        &-subheading {
            position: relative;
        }
    }
}

@include media(medium) {
    .full-carousel-item {
        padding-bottom: $spacer-primary/2;

        &-time,
        &-subheading,
        &-cta {
            margin: $spacer-primary/2 $spacer-primary/2 0;
        }

        &-heading {
            margin: $spacer/2 $spacer/2 0;

            + * {
                margin-top: $spacer/2;
            }
        }

        &-img-date,
        &-date {
            padding: $spacer-primary/2;
        }
    }
}



@include media(small) {
    .full-carousel-item {
        &-cta {
            max-width: calc(100% - 30px);
        }
    }
}
