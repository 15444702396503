/* 
html5doctor.com Reset Stylesheet v1.6.1
Last Updated: 2010-09-17
Author: Richard Clark - http://richclarkdesign.com 
*/

html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i, button,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, summary,
time, mark, audio, video, input {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font-size: 18px;
    line-height: 1.5rem;
    vertical-align: baseline;
    background: transparent;
}

html, body {
    height: 100%;
}

body {
    line-height: 1;
    overflow-x: hidden;
}

style {
    display: none !important;
}

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
    display: block;
}

ol, ul {
    list-style-type: none;
}

blockquote, q {
    quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
    content: '';
    content: none;
}

a {
    margin: 0;
    padding: 0;
    vertical-align: baseline;
    background: transparent;
}

del {
    text-decoration: line-through;
}

abbr[title], dfn[title] {
    border-bottom: 1px dotted;
    cursor: help;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

input, select {
    vertical-align: middle;
}

html {
    box-sizing: border-box;
}

*, *:before, *:after {
    box-sizing: inherit;
}

img {
    max-width: 100%;
    height: auto;
    display: inline-block;
    vertical-align: middle;
    -ms-interpolation-mode: bicubic;
}

video { //added by Adage
    max-width: 100%;
}

@include media(medium) {
    body, div, span, object, iframe,
    p, blockquote, pre,
    abbr, address, cite, code,
    del, dfn, em, img, ins, kbd, q, samp,
    small, strong, sub, sup, var,
    b, i, button,
    dl, dt, dd, ol, ul, li,
    fieldset, form, label, legend,
    table, caption, tbody, tfoot, thead, tr, th, td,
    article, aside, canvas, details, figcaption, figure,
    footer, header, hgroup, menu, nav, section, summary,
    time, mark, audio, video, input {
        font-size: 16px; //helpful on ace pages
        line-height: 1.5625rem;
    }

    h1, %h1,
    h2, %h2,
    h3, %h3,
    h4, %h4,
    h5, %h5,
    h6, %h6 {
        font-size: 18px;
    }
}
