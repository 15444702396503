﻿main > .merch-detailpage { //overriding !fullwidth specificity for display block ie fix
    display: flex;
}

.merch-detailpage {
    padding-bottom: $spacer-large;

    &-col {
        &:first-of-type {
            min-width: 400px; //per design
            max-width: 400px;
            margin-right: $spacer-primary;
        }

        form {
            margin-top: $spacer*2;
        }
    }

    &-heading {
        @extend %h3-page-subheading;
        max-width: 100%;
        margin-bottom: $spacer-large;
    }

    &-image {
        margin-bottom: $spacer-primary;
    }

    &-price {
        margin-bottom: 0;
    }

    &-buyon {
        margin: $spacer*2 0;
        display: flex;
        align-items: center;

        &-label {
            margin-right: $spacer/2;
        }

        span {
            display: flex;
            align-items: center;
        }

        p {
            margin-bottom: 0;

            + p {
                margin-left: $spacer;
            }
        }
    }

    .hide-size-dropdown {
        display: none;
    }
}


@include media(medium) {
    main > .merch-detailpage {
        display: block;
    }

    .merch-detailpage {
        &-col {
            &:first-of-type {
                margin-right: 0;
                margin-bottom: $spacer-large;
            }
        }
    }
}

@include media(small) {
    main > .merch-detailpage {
        display: block;
    }

    .merch-detailpage {
        padding-bottom: $spacer-primary;

        &-col {
            &:first-of-type {
                min-width: 100%;
                margin-bottom: $spacer-primary;
            }
        }

        &-heading {
            margin-bottom: $spacer-primary;
        }
    }
}
