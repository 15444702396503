﻿//This is part of the ADAGE LIBRARY DO NOT UPDATE ON YOUR PROJECT OR IT WILL BE OVERWRITTEN
$slideshow-transition: .35s;//must be in seconds
.libsldr {
    position: relative;
    margin-bottom: 40px;
    width: 100%;
    transition: $slideshow-transition all ease $slideshow-transition;
    opacity: 0;
    visibility: hidden;
    z-index: 10;
    &-item {
        transition: all $slideshow-transition ease-in-out;
        position: absolute;
        visibility: hidden;
        opacity: 0;
        top: 0;
        left: 0;
        width: 100%;
        backface-visibility: hidden;
        &-image, &-video-iframe {
            display: block;
            width: 100%;
        }
        &-video {
            position: relative;
            height: 0;
            padding-bottom: 56.25%; //16:9 aspect ratio
            &:before, &:after {
                content: '';
                position: absolute;
                width: 100%;
                height: calc(50% - 80px);
                background-color: transparent;
            }

            &:before {
                z-index: 20;
                top: 0;
            }

            &:after {
                bottom: 40px;
            } 

            &-iframe {
                position: absolute;
                height: 100%;
                top: 0;
                left: 0;
            }
        }
        &-active {
            visibility: visible;
            opacity: 1;
        }
    }
}

.libsldr-container { 
    max-width: 1000px;
    margin: 0 auto;
    position: relative; 
    min-height: 400px;
    overflow: hidden;
    &:after {
        content: 'Loading Slideshow';
        position: absolute;
        display: block;
        z-index: -1;
        top: 50%;
        transform: translateY(-50%);
        margin: 0 auto;
        left: 0;
        right: 0;
        text-align:center;
    }
    &-ready {
        opacity: 1;
        visibility: visible;
        min-height: 0;
        border-color:transparent;
        &:after {
            display: none;
        }
        .libsldr {
            opacity: 1;
            visibility: visible;
            min-height: 0;
        }
        .libsldr-pagination, .libsldr-pagination-status, .libsldr-controls-nav {
            opacity: 1;
            visibility: visible; 
        }
    }
}
.libsldr-pagination {
    display: flex;
    width: 100%;
    justify-content: center;
    transition: $slideshow-transition all ease $slideshow-transition;
    opacity: 0;
    visibility: hidden;
    &-item {
        cursor: pointer;
        display: block;
        & + & {
            margin-left: 5px;
        }
    }
    &-status {
        opacity: 0;
        visibility: hidden;
    }
}
.libsldr-controls-nav {
    transition: $slideshow-transition all ease $slideshow-transition;
    cursor: pointer;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    transform-origin: center center;
    z-index: 80;
    opacity: 0;
    visibility: hidden;
    &:after {
      content: "";
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      position:absolute;
    }
    svg {
        pointer-events:none;
    }
    &-disabled {
        opacity: 0;
        visibility:hidden;
        pointer-events:none;
    }
    &-previous {
        left: 10px;
    }
    &-next {
        right: 10px;
    }
}
.libsldr-autoheight {
    padding-bottom: 0;
    overflow: visible;
}

@media(max-width:767px) {
    .slidr {
        margin-bottom: 10px;
        &-controls {
            display: none;
        }

        &-container {
            margin: 20px auto;
        }
    }
}
