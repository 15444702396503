﻿.merch-listingpage {
    padding-bottom: $spacer-body-responsive;

    h1.page-heading {
        margin-bottom: 0;
    }

    &-subheading {
        color: $gray;
    }

    &-list {
        width: 1160px; //per mockup
        max-width: 100%;

        &-item {
            display: flex;
            justify-content: space-between;
            padding: $spacer-primary 0;

            &-image {
                min-width: 300px; //per mockup
                max-width: 300px; //per mockup
                margin-left: $spacer-primary;
            }

            &-prices {
                display: flex;

                * + * {
                    &:before {
                        content: '|';
                        margin-left: $spacer;
                        margin-right: $spacer;
                    }
                }
            }

            &-ctas,
            &-buyon {
                display: flex;
                align-items: center;
            }

            &-buyon {
                margin-left: $spacer-primary;

                &-label {
                    margin-right: $spacer;
                }

                p {
                    margin-bottom: 0;

                    + p {
                        margin-left: $spacer*2;
                    }
                }
            }

            & + & {
                border-top: 1px solid $gray;
            }
        }
    }
}


@include media(medium) {
    .merch-listingpage {
        &-list {
            &-item {
                &-image {
                    min-width: 175px;
                    width: 175px;
                }
            }
        }
    }
}

@include media(tablet) {
    .merch-listingpage {
        &-list {
            &-item {
                flex-direction: column-reverse;

                &-image {
                    margin-left: 0;
                    margin-bottom: $spacer-primary;
                }
            }
        }
    }
}

@include media(small) {
    .merch-listingpage {
        &-list {
            &-item {
                &-image {
                    margin-bottom: $spacer*2;
                }

                &-prices,
                &-ctas {
                    display: block;
                } 

                &-desc {
                    display: none;
                }

                &-prices {
                    p {
                        margin-bottom: 0;

                        + p {
                            &:before {
                                content: none;
                            }
                        }
                    }
                }

                &-ctas {
                    margin-top: $spacer*2;
                }

                &-buyon {
                    margin-left: 0;
                    margin-top: $spacer*2;
                }
            }
        }
    }
}
