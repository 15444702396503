﻿/// <reference path="../../main">
.fiftyfifty-audio {
    display: flex;
    flex-wrap: wrap;

    p {
        margin-bottom: 0;
    }


    &-play {
        background-image: url("../static/images/svgs/play-btn.svg")
    }

    &-pause {
        background-image: url('../static/images/svgs/pause-btn.svg');
    }

    &-play,
    &-pause {
        width: $spacer-primary*2;
        height: $spacer-primary*2;
        cursor: pointer;
        transition: transform .3s;
        margin-right: $spacer;

        &:hover {
            transform: scale(1.05);
        }

        &.hide {
            display: none;
        }
    }

    &-text {
        flex-grow: 1;
        margin-bottom: $spacer;
        max-width: calc(100% - 70px); //70 is width of play button; for longer text
    }

    &-artist {
        @extend %h6;
        @extend %font-accent;
        text-transform: uppercase;
    }

    &-track {
        @extend %p-heading;
        line-height: 1.4rem;

        &:before {
            content: "\201c";
        }

        &:after {
            content: "\201d";
        }
    }

    progress {
        -webkit-appearance: none;
        width: 100%;
        height: $spacer/2;
        transition: .5s;
        border: none;

        &:hover {
            height: $spacer;
        }
    }

    progress[value]::-webkit-progress-bar {
        background-color: $black;
    }

    progress[value]::-webkit-progress-value {
        background-color: $gray;
    }

    progress {
        &:hover {
            cursor: pointer;
        }
    }

    &-time {
        width: 100%;
        text-align: right;
        font-family: $font-accent;
    }
}

@include media(tablet-larger) {
    .fiftyfifty-audio {
        &.fiftyfifty-padding {
            padding-top: $spacer-primary/2;
            padding-bottom: $spacer-primary/2;
        }

        &-play,
        &-pause {
            width: $spacer*4;
            height: $spacer*4;
        }

        &-text {
            width: 80%; //mobile safari fix
            max-width: calc(100% - 50px); //50 is width of play button; for longer text
        }
    }
}

@include dark-theme() {
    .fiftyfifty-audio {
        &-play {
            background-image: url("../static/images/svgs/play-btn-inverted.svg")
        }

        &-pause {
            background-image: url("../static/images/svgs/pause-btn-inverted.svg")
        }

        progress[value]::-webkit-progress-bar {
            background-color: $white;
        }
    }
}

