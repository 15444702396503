﻿/// <reference path="../../main.scss" />


.payment {
    &-paragraph-header h3 {
        @extend %h6-content-heading;
    }


    &-step {
        margin-bottom: $spacer-large;

        ul li label {
            margin-left: 0; //overriding custom css file for bootstrap
            display: inline-flex; //overriding custom css file for bootstrap
        }
    }

    .giftCard {
        &-input-container {
            display: flex;
            gap: $spacer-med;

            @include media(small) {
                flex-direction: column;
            }
        }

        &-input {
            margin: 0;
            height: 100%;
            line-height: 1;
            min-height: 0;
        }
    }


    &-delivery-method {
        .radio-container {
            margin-bottom: 0;
        }

        label,
        input:not([type=submit]) {
            min-width: 0;
        }
    }

    .payment-heading-alt {
        h3 {
            //override heavily nested global margin
            margin: 0 !important;
        }

        &-description {

            & > p {
                margin-bottom: $spacer-rem;
            }
        }
    }
    // TBD - remove after BED work is complete
    .paymentPlans {
        display: none;
    }

    label:after {
        content: none;
    }

    .credit-card-container {
        max-width: $max-width-content;
        padding: $spacer-primary;
        background: $lightGray;


        input, .select-container, .checkbox-container {
            margin-bottom: $spacer;
        }

        .checkbox-container label {
            @extend %font-accent-bold;
        }
    }

    .credit-card-row {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 20px;

        @include media(tablet) {
            grid-template-columns: 1fr;
        }
    }

    .cvv-errors {
        margin: $spacer;
    }

    .unstyled {
        margin: 0;
    }

    .edit-link {
        @extend %modal-link;
        margin-top: $spacer;
        display: inline-block;
        border-bottom: 4px solid $primary-color;

        &:hover {
            border-bottom: 4px solid transparent;
        }
    }
}

// Mini Cart
.payment-container {

    input, label, select, .select-container {
        // Override global settings
        min-width: 0 !important;
    }

    .cart-item-container {
        border-top: 4px solid;
    }

    .singleTicketWrapper, .packageArea {

        .perf-title-text, .donate-title-text, .merch-title-text {
            margin: 0;
            padding: 0;
        }


        .perf-title, .donate-title {
            border-bottom: none;
            padding: 15px 0;
            border-top: 2px solid;

            & > p {
                margin: 0;
            }
        }
    }

    .total {
        padding-top: $spacer !important;
        margin-top: $spacer !important;
    }

    .attestation {
        margin: $spacer 0;

        label {
            @extend %font-accent-bold;
        }
    }

    .cart-item-container .packageArea {
        display: block;
    }
}
