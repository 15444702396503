﻿/// <reference path="../../main.scss" />

.watch-blocks {

    @supports (display: grid) {
        display: grid;
        grid-template-columns: repeat(4, minmax(200px, 1fr));
        grid-gap: $block-spacer;

        @include media(large) {
            grid-template-columns: repeat(3, minmax(200px, 1fr));
            grid-template-rows: auto 1fr;
            grid-gap: $block-spacer / 3;
        }

        @include media(tablet) {
            grid-template-columns: 1fr;
            grid-gap: $spacer-rem
        }
    }

    &-container {
        padding-top: $spacer-large;
        padding-bottom: $spacer-primary * 3;
    }

    &-item {
        padding: $spacer-large / 2;
        position: relative;
        box-sizing: border-box;
        position: relative;
        align-self: start;
        color: $black;
        background: $offWhite;

        @include media(tablet) {
            min-width: 200px;
            max-width: 300px;
        }

        &::before {
            content: "";
            padding-bottom: 100%;
            display: block;
        }


        &-content {
            position: absolute;
            max-width: 100%;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
            margin: auto;
            padding: $spacer-primary;
            padding-left: $spacer-large/2;

            @include media(medium) {
                padding: $spacer-large/2;
                padding-left: $spacer-rem;
            }
        }

        &.notes {
            background: transparent;
            padding-top: 0;
            color: $black;


            &::before {
                content: none;
            }

            @include media(large) {
                grid-column: 1 / -1;
                margin-top: $spacer-rem * 2;
                margin-bottom: $spacer-rem * 2;
                padding-left: 0;
            }

            @include media(tablet) {
                max-width: none;
            }

            .rich-text {
                max-width: $max-width-form-ace;
            }
        }

        &-type {
            margin-bottom: $spacer;
        }

        &-title.h5-paragraph-heading {
            margin-top: 0;
            margin-bottom: $spacer-primary;
            @extend %heading-accent;
        }

        &-time {
            @extend %h6-content-heading;
            margin: 0;
        }
    }
}

@include dark-theme() {
    .watch-blocks {
        &-item {
            background: $offWhite;
            color: $black;

            &.notes {
                background: $black;
                color: $white;
            }
        }
    }
}
