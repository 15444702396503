﻿.nav-mobile {
    visibility: hidden;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    transition: opacity $header-transition;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: calc(100% - var(--nav-clearance));
    overflow-y: auto;
    z-index: 10;

    &.open {
        visibility: visible;
        opacity: 1;
        overflow: hidden;
        position: fixed;
        top: var(--fixed-nav-clearance);
        height: 100%;
        overflow-y: scroll;
    }

    .nav-main {
        background-color: $offWhite;
        padding: $spacer*1.5 0;
        flex-grow: 1;

        &-item {
            width: 100%;
            @extend %nav-item;
            color: $black;
            cursor: pointer;
            padding: $spacer*1.5 $spacer*2;
            font-size: 1.2rem;

            &-container {
                display: flex;
                justify-content: space-between;
                align-items: center;
            }

            &-arrow {
                width: $spacer*2;
                height: $spacer*2;
                transition: transform $header-transition;
                margin-right: $spacer*2;
                cursor: pointer;
                display: flex;
                align-items: center;

                &:hover {
                    transform: scale(1.1);
                }

                &:focus {
                    outline: 1px dotted grey;
                }

                &.open {
                    transform: rotate(180deg);
                }
            }
        }
    }

    .nav-sub {
        position: absolute;
        visibility: hidden;
        opacity: 0;
        width: 100%;
        display: flex;
        flex-direction: column;

        &-item {
            @extend %nav-item;
            padding: $spacer/2 0 $spacer/2 $spacer-primary*2;
            color: $black;
        }

        &.show {
            position: relative;
            visibility: visible;
            opacity: 1;
        }
    }

    .nav-utility {
        display: block;
        padding: $spacer*2;
        background: $black;

        &-item {
            color: $offWhite;
            display: flex;
            align-items: center;

            & + & {
                margin-top: $spacer-md;
            }

            &:not(:last-of-type) {
                margin-bottom: $spacer*2;
            }

            img {
                margin-right: $spacer;
                width: $spacer*2.5;
                height: $spacer*2.5;
            }
        }
    }
}
