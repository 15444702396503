﻿.fiftyfifty {
    display: flex;

    &.fiftyfifty-stacked {
        display: none;

        @include media(small) {
            display: block;
        }
    }

    &.fiftyfifty-callout-quarter {
        @include media-range(small, large) {
            display: block;
        }

        @include media(small) {
            display: none;
        }
    }

    &-item {
        width: 50%;
        flex-basis: 50%;

        @include media(large) {
            width: 100%;
            flex-basis: 100%;
        }
        /*@include media(641px) {
            height: 100% !important;
        }*/
        &:nth-child(odd) {
            .fiftyfifty-padding {
                padding-right: $spacer*2;
                padding-left: $spacer-body;

                &-large {
                    padding-right: $spacer-body;
                    padding-left: $spacer-body;
                }
            }
        }

        &:nth-child(even) {
            .fiftyfifty-padding {
                padding-left: $spacer*2;
                padding-right: $spacer-body;

                &-large {
                    padding-right: $spacer-body;
                    padding-left: $spacer-body;
                }
            }
        }

        & > .fiftyfifty {
            margin-top: 0;

            &:not(.fiftyfifty-stacked) {
                @include media(small) {
                    display: none;
                }
            }
        }

        .embed-block.base-block {
            padding: 0;
        }
    }

    &-padding { //all non-full-width fifty fifty items should have this class, for example each audio block in 5050 will have this class
        padding-top: $spacer*4;
        padding-bottom: $spacer*4;
    }

    &-padding-large {
        padding-top: $spacer*9;
        padding-bottom: $spacer*9;
    }

    &.fiftyfifty-single-render {
        @include media(small) {
            display: block;
        }
    }
}

@include media(x-large) {
    .fiftyfifty {
        &-item {
            &:nth-child(odd) {
                .fiftyfifty-padding {
                    padding-left: $spacer-body-responsive;

                    &-large {
                        padding: $spacer-body-responsive;
                    }
                }
            }

            &:nth-child(even) {
                .fiftyfifty-padding {
                    padding-right: $spacer-body-responsive;

                    &-large {
                        padding: $spacer-body-responsive;
                    }
                }
            }
        }
    }
}

@include media(tablet-larger) {
    .fiftyfifty {

        &-item {

            &:nth-child(odd) {
                .fiftyfifty-padding {
                    padding-right: $spacer-body-responsive;
                }
            }

            &:nth-child(even) {
                .fiftyfifty-padding {
                    padding-left: $spacer-body-responsive;
                }
            }
        }
    }
}

@include media(small) {
    .fiftyfifty {

        &-item {
            &:nth-child(odd) {
                .fiftyfifty-padding {
                    padding-left: $spacer*2;
                    padding-right: $spacer*2;

                    &-large {
                        padding: $spacer*4 $spacer*2;
                    }
                }
            }

            &:nth-child(even) {
                .fiftyfifty-padding {
                    padding-right: $spacer*2;
                    padding-left: $spacer*2;

                    &-large {
                        padding: $spacer*4 $spacer*2;
                    }
                }
            }
        }
    }

    .full-width.nospace.fiftyfifty:not(.fiftyfifty-stacked) {
        flex-wrap: wrap;
        display: none;
    }

    .full-width.nospace.fiftyfifty.fiftyfifty-single-render {
        display: block;
    }
}
