﻿/// <reference path="../../main.scss" />

.calendar-list-view {
    &-event {
        &-container {
            display: flex;
            align-items: flex-start;
        }

        a:not(.button-secondary):not(.event-info-series) {
            border: 0;
            padding: 0;
            white-space: normal;

            &:hover,
            &:focus,
            &:active {
                padding: 0;
                background: none;
                color: inherit;
            }
        }

        .button-secondary {
            margin-top: $spacer-primary/2;
        }

        p {
            margin-bottom: 0;
        }

        .event {
            &-date {
                min-width: $spacer-primary*3;
                height: $spacer-primary*3;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                color: $offWhite;
                background-color: $primary-color;

                &-day {
                    @extend %p-heading;

                    + %h5-paragraph-heading {
                        margin-top: 0;
                    }
                }

                &-month {
                    margin-right: $spacer/2;

                    &-container {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                    }
                }

                &-month,
                &-date {
                    margin-bottom: 0;
                    @extend %h5-paragraph-heading;
                    font-size: 1.5rem;
                }
            }

            &-info {
                flex: 1;
                flex-basis: $spacer-primary * 12;
                padding-left: $spacer-primary;
                padding-right: $spacer-primary;

                &-series {
                    color: $primary-color;
                    @extend %h6-content-heading;
                    font-weight: 400; //book
                    margin-bottom: $spacer-primary;
                    padding-top: $spacer-primary;
                    padding-bottom: $spacer-small;
                    border-bottom: 2px solid $gray;
                    display: block;
                    max-width: 100%;
                    transition: background .4s;

                    &:active,
                    &:focus,
                    &:hover {
                        background: none;
                        padding-left: $spacer*2;
                    }

                    &:empty {
                        padding-top: 0;
                    }
                }


                &-title {
                    & > a, & > p {
                        @extend %h5-paragraph-heading;

                        b, strong, em, i {
                            font-size: inherit;
                        }
                    }

                    & > div:not(.event-info-subtitle) {
                        a, p {
                            @extend %h5-paragraph-heading;

                            b, strong, em, i {
                                font-size: inherit;
                            }
                        }
                    }

                    .subhead-calendar {
                        font-family: "Gotham XNarrow A", "Gotham XNarrow B", 'Helvetica Neue', 'Arial', sans-serif;
                        font-size: 18px;
                        line-height: normal;
                        font-weight: 700;
                        letter-spacing: normal;
                        text-transform: none;
                    }
                }

                &-time-and-venue {
                    @extend %font-primary-bold;
                }
            }

            &-tags {
                display: flex;
                flex-wrap: wrap;

                &-item {
                    display: flex;
                    align-items: center;
                    margin-bottom: 0;
                    margin-right: $spacer-primary;
                }
            }

            &-image {
                flex-basis: $spacer-primary * 10;
                flex-grow: 1;
                min-width: $spacer-primary * 8;
            }

            &-cta-container {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
            }
        }
    }

    &-event,
    &-month-wrapper {
        & + & {
            margin-top: $spacer-large;
        }
    }

    &-loading {
        text-align: center;
        transform: translateY($spacer-large);

        p {
            margin-bottom: 0;
            max-width: 100%;
        }
    }
}

.calendar-list-view-month-wrapper + .no-events-msg {
    transform: translateY($spacer-primary*2);
}

.no-events-msg {
    max-width: 100%;
    @extend %h5-paragraph-heading;
    color: $gray;
    margin: 0;
    text-align: center;
}


@include media(medium) {
    .calendar-list-view {
        .event {
            &-info {
                padding-right: 0;
                flex-basis: 100%;
            }

            &-image {
                display: none;
            }
        }
    }
}

@include media(small) {
    .calendar-list-view {
        .event {
            &-date {
                min-width: $spacer-primary*2;
                height: $spacer-primary*2;

                &-month,
                &-date {
                    font-size: 1rem;
                }
            }

            &-info {
                padding-left: $spacer-primary/2;

                &-series {
                    &:active,
                    &:focus,
                    &:hover {
                        padding-left: 2px;
                    }
                }
            }

            &-tag {
                font-size: .8rem;
            }
        }

        &-series {
            &-container {
                margin-left: $spacer*2;
            }
        }
    }
}
