/// <reference path="../../main.scss" />

$otc-logo-size: 200px;

.otc-nav {
    padding-top: $spacer-primary;
    padding-bottom: $spacer-primary;
    margin-bottom: $spacer-large;

    @include media($otc-main-breakpoint) {
        padding-top: $spacer;
        padding-bottom: $spacer;
        margin-bottom: $spacer-primary;
    }



    &-container {
        display: grid;
        grid-template-columns: $otc-logo-size 1fr;
        width: 100%;
        gap: $spacer-large;
        border-bottom: 1px solid $black;
        padding-bottom: $spacer-primary;

        @include media(medium) {
            padding: $spacer-md 0;
        }

        @include media(tablet-larger) {
            display: flex;
            justify-content: space-between;
        }
    }

    &-logo {
        display: flex;
        max-width: $otc-logo-size;
        align-self: center;
    }

    &-links {
        display: flex;
        gap: $spacer-rem * 3;
        align-items: center;

        &-link {

            &.active {
                // sadly needed because of theming specificity
                color: $otc-orange !important;
                border-bottom-color: transparent !important;
                font-weight: bold;
            }
        }
    }

    &-links-desktop {
        @include media(tablet-larger) {
            display: none;
        }
    }

    &-links-mobile {
        position: relative;
        max-width: 250px;
        width: 100%;
        min-height: 50px;

        @include media-min(tablet-larger) {
            display: none;
        }

        &-button {
            border: 2px solid $black;
            padding-left: $spacer;
            display: flex;
            justify-content: space-between;
            width: 100%;
            position: relative;
            align-items: center;
            height: 100%;
            cursor: pointer;
        }

        &-trigger {
            width: $spacer-large;
            border-left: 2px solid $black;
            height: 100%;
            display: flex;
            align-items: center;
            background: $gray-lighter;

            &::after {
                content: '';
                background: url('../static/images/svgs/dropdown-arrow.svg') no-repeat center;
                position: absolute;
                right: $spacer;
                font-size: 3rem;
                transition: transform .3s;
                background-size: contain;
                background-repeat: no-repeat;
                width: $spacer-primary;
                height: $spacer-primary;

                @include media(small) {
                    width: $spacer-primary/2;
                    height: $spacer-primary/2;
                }
            }
        }

        &.show .otc-nav-links-mobile-trigger::after {
            transform: rotate(180deg);
        }
    }

    &-panel {
        @extend %list-reset;
        max-width: none;
        margin: 0;
        background: $white;
        padding: $spacer-rem;
        position: absolute;
        border: 2px solid $black;
        border-top: none;
        z-index: 999;

        &-link {
            @extend %list-item-reset;

            & + & {
                padding-top: $spacer !important;
            }
        }
    }
}

@include dark-theme() {
    .otc-nav {
        border-top: 1px solid $offWhite;
        padding-bottom: 0;

        &-panel {
            background: $black;
            border: 1px solid $offWhite;
        }

        &-container {
            border-color: $offWhite;
        }
    }
}
