// ------------- HEADER - PAYMENT PATH AKA FUNNEL HEADER ------------- //
header.funnel {
    background-color: $gray;

    .funnel-logobar {
        background-color: $black;
        display: flex;
        justify-content: space-between;
        align-items: center;

        &-link {
            &-img {
                height: $spacer*9;
                width: $spacer*9;
            }
        }

        &-close {
            margin-right: $spacer*2;

            &-img {
                height: $spacer-primary;
                width: $spacer-primary;
                transition: transform .1s;

                &:hover {
                    transform: rotate(180deg);
                }
            }
        }
    }

    .funnel-timeout {
        display: flex;
        justify-content: center;
        align-items: center;
        height: $spacer-large;
        padding: 0 $spacer*2;

        &.hide {
            display: none;
        }

        &-time {
            margin-bottom: 0;
            margin-right: $spacer;
            padding-right: $spacer;
            border-right: 2px solid $offWhite;
            color: $offWhite;
            @extend %p-heading;

            &.expired {
                color: $red-validation;
                margin-right: 0;
                padding-right: 0;
                border-right: none;
            }
        }

        &-add {
            color: $offWhite;
            padding: 0 4px 0 4px;
            border-bottom: 4px solid $offWhite;
            transition: all .3s;

            &:focus,
            &:active,
            &:hover {
                border-bottom: 0;
                background: none;
                padding-top: 2px; //height of bottom border and bottom padding. equal space top and bottom on hover
            }
        }
    }

    ~ main {
        @include media(tablet-larger) {
            padding-top: $mobile-header-height;
        }

        .ace-page .page-heading {
            padding-top: $mobile-header-height;

            @include media(tablet-larger) {
                &:not(.cart-heading-timer) {
                    padding-top: $mobile-header-height*.667; //40px
                }
            }
        }
    }
}

@include media(tablet-larger) {
    .funnel-page {
        height: calc(100% - 137.5px); //make up space on page due to fixed header
    }

    header.funnel {
        position: fixed;
        width: 100%;
        top: 0;

        .funnel-logobar {
            &-link {
                &-img {
                    width: $spacer-primary*2;
                    height: $spacer-primary*2;
                }
            }

            &-close {
                margin-right: $spacer;

                &-img {
                    height: $spacer*2.5;
                    width: $spacer*2.5;
                }
            }
        }

        .funnel-timeout {
            height: $spacer*4;
        }
    }
}

@include media(tablet-larger) {
    header.funnel {
        .funnel-timeout {
            &-add {
                border-bottom: 2px solid $offWhite;
                line-height: 1rem;
            }

            &-time,
            &-add {
                font-size: .7rem;
            }
        }
    }
}
