﻿.full-carousel {
    position: relative;
    padding: $spacer-body-responsive 0;
    background-color: $black;
    color: $offWhite;
    overflow: hidden;

    @include media(small) {
        padding: $spacer-large 0;
    }

    &-heading {
        @extend %h1-page-heading;
        @extend %h2;
        margin-bottom: $spacer-primary;
    }

    &:before {
        content: '';
        background-image: url('../../Static/images/line-pattern-b-w-transparent_16x16.png');
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }

    &-swiper {
        @extend %gutter-padding;

        .swiper-wrapper {
            align-items: stretch !important;
        }

        .swiper-slide {
            height: auto !important;
        }
    }

    &-item {

        &-cta:nth-child(n) {

            @include media(small) {
                width: auto;
            }
        }
    }

    &.carousel-light {
        background-color: $offWhite;
        color: $black;
    }
}

// Navigation and Arrows
.full-carousel {

    &-nav {
        position: absolute;
        top: 90px;
        right: $spacer-md;

        @include media(tablet-larger) {
            display: none;
        }
    }

    &-arrow {
        &-next, &-prev {
            background-image: url('../static/images/svgs/arrow-square-light.svg');
            width: $spacer-primary*2;
            height: $spacer-primary*2;
            background-repeat: no-repeat;
            background-size: contain;
            transition: $transition;
            cursor: pointer;

            &.swiper-button-disabled {
                pointer-events: none;
                position: relative;

                &:after {
                    content: '';
                    background-image: url('../static/images/line-pattern-b-w-transparent_16x16.png');
                    background-color: rgba(0,0,0,.7);
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                }
            }
        }

        &-prev {
            transform: rotate(180deg);
        }

        &-next {
            margin-left: $spacer-primary;
        }
    }

    &.carousel-light {

        .full-carousel-arrow-next,
        .full-carousel-arrow-prev {
            background-image: url('../static/images/svgs/arrow-square-dark.svg');

            &.swiper-button-disabled {
                &:after {
                    background-color: rgba(255,255,255,.7);
                }
            }

            &:hover {
                background-image: url('../static/images/svgs/arrow-square.svg');
            }
        }
    }
}

.digital-page .full-carousel {
    overflow: visible;

    &-swiper {
        overflow: hidden;
        margin-right: -$spacer-body-responsive;

        @include media(large) {
            margin-right: -$spacer-primary;
        }

        @include media(small) {
            margin-right: -$spacer-rem;
        }
    }

    &-swiper, &-title {
        padding-left: 0;
    }
}
