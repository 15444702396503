﻿.isIE11 {
    .callout-quarter {
        &-text-container {
            > * {
                max-width: 100%; //bug fix for ie
            }
        }

        &-image {
            &-container {
                height: 0; //fix for ie
                padding-bottom: 55.555%; //fix for ie
				overflow-y: hidden;
            }
        }
    }

    .full-carousel-item {
        display: block;

        &-img-container,
        &-subheading {
            margin-bottom: $spacer*2;
        }
    }
}
