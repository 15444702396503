﻿/// <reference path="../../main.scss" />

.ace-page {
    #confirmationContainer {
        .span4 {
            width: 100%;
        }

        .step {
            h3 {
                @extend %h5-paragraph-heading;
            }

            p:last-child {
                margin-bottom: 0;
            }
        }
    }
}

.merch-spacer {
    margin: $spacer-med 0;

    &-button {
        color: $black !important;
        padding: 0 4px 2px 4px !important;
        white-space: pre-wrap;
        border-bottom: 4px solid #000 !important;
        transition: all .4s;
        outline: 0;

        &:hover {
            padding-top: 6px !important;
            border-color: transparent !important;
            background: #000 !important;
            color: #FEFEFE !important;
        }
    }
}

.confirmation-page {

    .cart-item-container {
        border-top: 2px solid black;
    }

    .member-discount {
        display: none;
    }

    .confirmation-container {
        margin-top: $spacer-large;
    }

    .step {
        margin-bottom: $spacer-med;
    }

    .cart-actions-wrapper {
        width: 100% !important;
        margin-top: $spacer !important;
        float: none !important;

        .total {
            padding-top: 10px;
        }
    }

    .singleTicketWrapper {

        .perf-title-text, .donate-title-text, .merch-title-text {
            margin: 0;
            padding: 20px 0;
        }

        .perf-title {
            border-bottom: 2px solid #000;
        }
    }

    .unstyled.cart-totals {
        border: none;
    }

    .packageArea {
        display: block !important;
    }

    .orderNumber-container {
        display: flex;
        flex-direction: column;
    }
}


.next-steps {
    margin: $spacer-body-responsive 0;
    padding: $spacer-md * 2;

    @include media(tablet) {
        padding: $spacer-md;
    }

    &-content {
        display: grid;
        width: 100%;
        grid-template-columns: 1fr 1fr;
        grid-gap: $spacer-med;
        color: $offWhite;

        @include media(tablet) {
            grid-gap: $spacer;
            grid-template-columns: 1fr;
        }

        p {
            margin-bottom: $spacer;
        }

        a {
            color: $offWhite !important;
            border-bottom-color: $offWhite !important;

            &:hover, &:active, &:focus {
                background: $offWhite !important;
                color: $blue !important;
            }
        }
    }

    &-links {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: $spacer;

        @include media(tablet) {
            grid-gap: 0;
            grid-template-columns: 1fr;
        }
    }
}
