﻿//This file contains the calendar page layout styles - padding, widths, positioning, show/hide styles, and display styles
//Note that this does not contain 'decorative' styles, or styles for items within the sidebar or listview containers

$calendar-padding-m: 0px;

#calendar-page {
    .calendar {
        display: flex;

        &-filter-col { //sidebar
            position: relative;
            overflow-y: scroll;

            &.fixed {
                position: fixed;
                top: var(--nav-clearance);
                height: 100%;
            }

            &-inner {
                padding-top: $spacer-text-margin*2;
                padding-bottom: $spacer-body-responsive;
                max-width: 380px; //per mockup
            }
        }

        &-navbar-mobile {
            display: none;
            position: fixed;
            width: 100%;
            height: $spacer*8;
            top: var(--nav-clearance);
        }

        &-list-view {
            padding-top: $spacer-body-responsive;
            padding-left: $spacer-primary*2;
            padding-bottom: $spacer-body;
        }
    }
}

@media (min-width: $x-large-media-query-size) {
    #calendar-page {
        .calendar {
            &-filter-col {
                min-width: 600px;
                max-width: 600px;
                padding-right: $spacer*8;

                &.fixed {
                    + .calendar-list-view {
                        margin-left: 600px; //width of sidenav
                    }
                }

                &:before {
                    width: 583px; //width minus scrollbar, so minus 17px
                }
            }

            &-list-view {
                width: calc(100% - 600px); //100% - width of sidenav
            }
        }
    }
}

@media (min-width: $large-media-query-size) and (max-width: $x-large-media-query-size) {
    #calendar-page {
        .calendar {
            &-list-view {
                padding-left: $spacer-primary;
                padding-right: $spacer-primary;
            }

            &-filter-col {
                min-width: 530px;
                max-width: 530px;
                padding-right: $spacer-body-responsive;

                &.fixed {
                    + .calendar-list-view {
                        margin-left: 530px; //width of sidenav
                    }
                }

                &:before {
                    width: 513px;
                }
            }

            &-list-view {
                width: calc(100% - 530px); //100% - width of sidenav
            }
        }
    }
}




@media (min-width: $tablet-larger-media-query-size) and (max-width: $large-media-query-size) {
    #calendar-page {
        .calendar {
            &-list-view {
                padding-left: $spacer*2;
                padding-right: $spacer*2;
            }

            &-filter-col {
                min-width: 400px;
                max-width: 400px;
                padding-right: $spacer;
                padding-left: $spacer;

                &.fixed {
                    + .calendar-list-view {
                        margin-left: 400px; //width of sidenav
                    }
                }

                &-inner {
                    padding-bottom: $calendar-padding-m;
                }

                &:before {
                    width: 387px;
                }
            }

            &-list-view {
                width: calc(100% - 400px); //100% - width of sidenav
            }
        }
    }
}

@include media(tablet-larger) {
    header.collapsed ~ main #calendar-page {
        .calendar-filter-col {
            top: 80px;
        }
    }

    #calendar-page {
        .calendar {
            display: block;

            &-filter-col {
                visibility: hidden;
                transform: translateX(-100%);
                transition: 1s;
                position: fixed;
                top: 140px; //height of top fixed elements
                bottom: 0;
                width: 75%;

                &-inner {
                    max-width: 100%;
                    padding-top: $spacer-primary*2;
                }

                .mini-calendar {
                    display: none;
                }

                &.mobileFilterShow {
                    visibility: visible;
                    transform: translateX(0);
                    z-index: 100;
                }
            }

            &-navbar-mobile {
                display: flex;
            }

            &-list-view {
                padding-top: 105px; //height of filters bar plus some padding, 80 + 75
                padding-left: $spacer-primary;
                padding-right: $spacer-primary;
                width: 100%;
            }
        }
    }
}

@include media(tablet) {
    #calendar-page {
        .calendar {
            &-filter-col {

                &-inner {
                    padding-bottom: $calendar-padding-m;
                }
            }
        }
    }
}

@include media(small) {
    #calendar-page {
        .calendar {
            &-filter-col {
                width: 100%;

                &-inner {
                    padding-top: 0;
                    padding-bottom: $calendar-padding-m;
                }
            }

            &-navbar-mobile {
                justify-content: space-between;
            }

            &-list-view {
                padding-top: 105px; //height of filters bar plus some padding, 80 + 25
                padding-left: 0;
                padding-right: $spacer*2;
            }
        }
    }
}

@media (min-width: $small-media-query-size) and (max-width: $medium-media-query-size) {
    body.isiOS {
        #calendar-page {
            .calendar {
                &-filter-col {
                    transition: none !important;

                    &.mobileFilterShow {
                        transition: none !important;
                    }
                }
            }
        }
    }
}
