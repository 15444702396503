// ------------- FOOTER ------------- //

footer {
    color: $offWhite;

    .footer {
        &-main,
        &-sub {
            display: flex;
            justify-content: space-between;
            padding-left: $spacer-body;
            padding-right: $spacer-body;
        }

        &-main {
            padding-top: $spacer-primary*2;
            padding-bottom: $spacer-primary*2;
            align-items: flex-start;
            background-repeat: no-repeat;
            background-size: cover;
            position: relative;

            &:before {
                content: '';
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                background-color: rgba(0,0,0,.8);
            }

            &-col {
                z-index: 1;
                position: relative;

                &.wysiwyg {
                    width: 20%;
                    padding-right: $spacer*2;
                    flex-grow: 1;

                    p {
                        margin-bottom: 0;
                    }

                    a {
                        border-bottom: none !important;
                        @extend %nav-item;
                        color: $offWhite;
                        transition: color .3s, background-color .3s;
                        padding: $spacer;

                        &:focus,
                        &:active,
                        &:hover {
                            padding: $spacer !important;
                            color: $black;
                            background-color: $offWhite;
                        }
                    }
                }

                &.footer-social .footer-social-text,
                &.footer-email .footer-email-text {
                    text-transform: capitalize;

                    &:empty {
                        display: none;
                    }
                }

                &.footer-social {
                    max-width: 20%;
                    display: flex;
                    flex-wrap: wrap;
                    padding-right: $spacer*2;

                    .footer-social {
                        &-text {
                            width: 100%;
                            @extend %font-primary-bold;
                        }

                        &-link {
                            margin-right: $spacer;
                            margin-bottom: $spacer;
                        }

                        &-icon {
                            width: $spacer-primary;
                            height: $spacer-primary;
                            cursor: pointer;

                            &:hover {
                                transform: scale(1.2);
                                transition: .2s;
                            }
                        }
                    }
                }

                &.footer-email {
                    width: 40%;

                    .footer-email {
                        &-text {
                            @extend %font-primary-bold;
                        }

                        &-submit {
                            color: $offWhite;
                            border: 4px solid $offWhite;
                            @extend %font-primary-bold;
                            padding: $spacer-primary/2 $spacer-primary;
                            cursor: pointer;
                            transition: .2s;
                            min-width: 113px; //fix ie bug
                            display: inline-block;

                            &:hover {
                                background-color: $offWhite;
                                color: $black;
                            }
                        }
                    }
                }
            }
        }

        &-sub {
            background-color: $black;
            padding-top: $spacer;
            padding-bottom: $spacer;
            align-items: center;

            &-text,
            &-links {
                @extend %small;
                margin-bottom: 0;
            }

            &-text {
                max-width: 100%;
            }

            &-links {
                display: flex;
                justify-content: flex-end;
                flex-wrap: wrap;
                max-width: 35%;
                padding-left: $spacer-primary;
                position: relative;

                &:after { //cover right hanging | items
                    content: '';
                    background-color: $black;
                    height: 100%;
                    width: $spacer*1.5;
                    position: absolute;
                }

                &-item {
                    color: $offWhite;

                    &:after {
                        content: '|';
                        padding: 0 $spacer;
                    }
                }
            }
        }
    }
    // ------------- MOBILE PHONE NUMBER STYLING FOR APPLE ------------- //
    a[href^="tel"] {
        display: inline-block;
    }
}

@include media(x-large) {
    footer {
        .footer {
            &-main,
            &-sub {
                padding-left: $spacer-body-responsive;
                padding-right: $spacer-body-responsive;
            }
        }
    }
}

@include media(large) {
    footer {
        .footer {
            &-main {
                &-col {
                    max-width: 25%;

                    &.footer-social {
                        max-width: 25%;
                    }
                }
            }
        }
    }
}

@include media(medium-larger) {
    footer {
        .footer {
            &-main {
                flex-wrap: wrap;

                &-col {
                    min-width: 50%;

                    &.footer-social,
                    &.footer-email {
                        margin-top: $spacer-primary*2;
                    }
                }
            }
        }
    }
}

@include media(tablet-larger) {
    footer {
        .footer {
            &-sub {
                padding-top: $spacer-primary;
                padding-bottom: $spacer-primary;
                flex-direction: column-reverse;
                align-items: flex-start;

                &-text {
                    + .footer-sub-text {
                        margin-top: $spacer*2;

                        &:before {
                            content: none;
                        }
                    }
                }

                &-texts,
                &-links {
                    max-width: 100%;
                }

                &-links {
                    flex-direction: column;
                    padding-left: 0;

                    &-item {
                        margin-bottom: $spacer*2;

                        &:after {
                            content: none;
                        }
                    }

                    &:after {
                        content: none;
                    }
                }
            }
        }
    }
}

@include media(700px) {
    footer {
        .footer {
            &-main,
            &-sub {
                padding-left: $spacer*2;
                padding-right: $spacer*2;
            }

            &-main {
                display: block;

                &-col {
                    min-width: 100%;

                    &.footer-social,
                    &.wysiwyg {
                        padding-right: 0;
                    }

                    &.wysiwyg {
                        a {
                            margin-left: -$spacer;
                        }
                    }

                    &.footer-email {
                        .footer-email-submit {
                            display: block;
                            text-align: center;
                        }
                    }

                    + .footer-main-col {
                        margin-top: $spacer-primary*2;
                    }
                }
            }
        }
    }
}
