﻿//Note that this file does NOT include styles for the filter flyout

.calendar-navbar-mobile {
    background-color: $black;
    color: $offWhite;
    z-index: 100;

    &-btn button,
    .current-month {
        @extend %h6-primary;
        margin-bottom: 0;
    }

    &-btn {
        display: flex;
        max-width: 20%;
        flex-basis: 20%;
        padding-left: $spacer-primary;

        button {
            color: $offWhite;
            cursor: pointer;
        }
    }

    .mini-calendar-nav {
        justify-content: center;
        max-width: 60%;
        flex-basis: 60%;

        .current-month {
            margin: 0 $spacer*2;
            z-index: 1;
        }

        .ng-hide:not(.ng-hide-animate) {
            display: inherit !important;
            visibility: hidden;
            pointer-events: none;
        }
    }
}

@include media(tablet-larger) {
	header.collapsed ~ main .calendar-navbar-mobile {
		top: 0;
	}
}


@include media(small) {
    .calendar-navbar-mobile {
        &-btn {
            max-width: 39%;
            flex-basis: 39%;
            padding-left: $spacer*2;
        }

        .mini-calendar-nav {
            max-width: 59%;
            flex-basis: 59%;
            padding: 0 $spacer*2 0 0;
            justify-content: flex-end;

            .current-month {
                margin: 0 $spacer;
            }
        }
    }
}
