﻿
//--------------FOUND AT THE TOP OF PAGES, INCLUDES HEADING AND SUBHEADING----------------//
//designed below so that user can include both, or either, and spacing will be appropriate//

.page-headings {
    margin-bottom: 90px;

    .page-heading {
        margin-bottom: 0;

        + .page-subheading {
            margin-top: $spacer-primary/2;;
        }
    }

    .page-subheading {
        margin-bottom: 0;
        color: $gray;
    }
}

@include media(small) {
    .page-headings {
        margin-bottom: $spacer-large;
    }
}
