﻿//This is part of the ADAGE LIBRARY DO NOT UPDATE ON YOUR PROJECT OR IT WILL BE OVERWRITTEN
.libsldr-transition-slide {
  .libsldr-item {
    &-active {
    transform: translateX(0);
    }
    &-ondeck {
      &-next {
        opacity: 1;
        transform: translateX(100%);
      }
      &-previous {
        opacity: 1;
        transform: translateX(-100%);
      }
    }
  }
}