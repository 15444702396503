﻿/// <reference path="../main">

.sponsors {
    background-color: $lightGray;
    padding: $spacer-large 0;

    @include media(mobile) {
        padding-bottom: $spacer-primary;
    }


    &-heading {
        color: $gray;
        text-align: center;
        min-width: 100%;
    }
    // link inside carousel block
    .sponsor-carousel-item > a {
        &:hover, &:focus {
            border-color: transparent;
        }
    }

    .sponsors-carousel-wrapper {
        display: flex;
        align-items: center;
        margin: $spacer-large 0;
        
        @include media(tablet) {
            flex-wrap: wrap;
        }
        .sponsor-carousel-item {
            @include media(tablet) {
                width: 33%;
                flex-basis: 33%;
                margin-top: $spacer-primary;
            }

            @include media(small) {
                width: 50%;
                flex-basis: 50%;
            }
        }
    }
}
// Carousel

.sponsor-carousel {
    &-item {
        align-items: center;
        flex-direction: column;
        display: flex;

        &-img {
            min-width: $spacer-body;
            max-width: $spacer-body;
        }

        &-text {
            text-align: center;
            @extend %small;
            color: $gray;
            margin-top: $spacer*2;
        }

        &-link {
            border-bottom: none;
            transition: none;
            white-space: normal;
            padding: 4px;

            &:hover,
            &:focus,
            &:active {
                padding: 0;
                border: 4px solid $black;
                background: none;
            }
        }
    }
}

@include media(large) {
    .sponsor-carousel {
        &-item {
            &-img {
                min-width: $spacer-body-responsive;
                max-width: $spacer-body-responsive;
            }

            &-text {
                margin-top: $spacer;
            }
        }
    }
}