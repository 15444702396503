﻿//Structure
.libaccordion {
  &-content {
    position:relative;
    height: 0;  
    overflow:hidden;
    visibility: hidden;//prevents user from being able to tab into any links in content
  }
  &-open {
      .libaccordion-content {
          visibility: visible;
      }
  }
}
 