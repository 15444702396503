﻿/// <reference path="../../main.scss" />
$season-overview-spacer: 1.5rem;

.season-overview {
    .full-carousel-item {
        width: calc(25% - 1.5rem);
        margin-right: $season-overview-spacer;



        &:last-child {
            margin-right: 0;
        }

        &:nth-child(n+5) {
            margin-top: $season-overview-spacer;
        }

        @include media(medium) {
            width: calc(50% - 1.5rem);
            margin-bottom: $season-overview-spacer;
        }

        @include media(small) {
            width: 100%;
            margin-bottom: $season-overview-spacer;
        }
        // Override for PDP Secondary Heading Styling
        &-time p,
        &-time > *,
        &-time em,
        &-time .h5-paragraph-heading {
            margin-left: 0;
            margin-top: 0;
            padding-bottom: $spacer-med * 2;
        }



        .h5-paragraph-heading {
            font-size: 1.125rem;
            line-height: 1.333;
            letter-spacing: 1px;
            margin-top: 0;
            margin-bottom: 0;
            padding-bottom: $spacer-med * 2;
        }

        &-container {
            margin-left: 0;
            justify-content: flex-start;
            display: flex;
            flex-wrap: wrap;

            @include media-min(medium) {
                margin-right: -$season-overview-spacer;
            }
        }
    }
}
