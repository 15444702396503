﻿.title-block {
    &-heading {
        @extend %h1-page-heading;
        @extend %h2;
        margin-bottom: 0;
        margin-top: $spacer-large;

        @include media-min(large) {
            max-width: 1100px;
        }
    }
}
