/// <reference path="../../main.scss" />
[data-festival-overview-page] {
    .season-overview-navigation {
        &-container {
            margin-left: 0;
            margin-right: 0;
        }

        &-item {
            @include media-min(large) {
                flex: 0 0 auto;
                min-width: calc(100% / 8);
            }
        }
    }

    .full-carousel-item-heading {
        &:hover, &:focus, &:active {
            background: transparent;
            color: $black;
        }
    }
}
