﻿/// <reference path="../../main.scss" />
$meganav-spacer: 40px;

.meganav {
    //desktop only
    padding: $meganav-spacer;
    display: flex;
    align-items: flex-start;
    background-color: $offWhite;
    box-shadow: -1px 7px 5px 0px rgba(0,0,0,0.24);
    width: 100%;
    max-width: 1100px;
    margin: 0;
    opacity: 0;
    position: absolute;
    top: 100%;
    left: $logo-var;
    z-index: 10;
    visibility: hidden;
    margin-top: -1px;

    &-container {
        width: 100%;
        display: flex;
        align-self: stretch;

        @include media(x-large) {
            flex-direction: column-reverse;
            align-items: flex-end;
        }
    }

    li {
        &:before {
            content: none;
        }

        & + li {
            padding-top: 0;
        }
    }

    &-inner {

        &-heading {
            @extend %h4-page-subheading;
            @extend %heading-accent;
            width: 100%;
            color: $black;
        }
    }

    &-subnav {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        min-width: 330px; //per mockup
        margin: 0;
        padding: 0;

        ul {
            margin: 0;
            padding: 0;
        }
        // Reset Globals
        .nav-main-li1 {
            padding: $spacer;
            transition: color .3s, background-color .3s;
            text-indent: 0;

            &:before {
                content: none;
            }

            &:focus,
            &:active,
            &:hover {
                background-color: $black;

                .meganav-subnav-link {
                    color: $offWhite;
                }
            }

            &-cta {
                &:hover {
                    background: transparent;
                }
            }
        }

        &-link {
            color: $black;
            @extend %nav-item;
            border-bottom: 0;
            display: block;
        }

        &-cta {
            margin-top: $spacer*2;
            @extend %button;
        }
    }

    .full-carousel-item {
        width: calc(48% - 30px);
        flex-grow: 1;
        text-indent: 0;

        & + .full-carousel-item {
            margin-left: $spacer-primary;
        }

        &-container {
            display: flex;
            justify-content: flex-start;
            margin-left: 2rem;
            flex-wrap: wrap;
        }

        &-heading {
            font-size: 1.5rem;
        }

        &-img-date, &-subheading {
            font-size: 1rem;
        }

        &-time {
            color: $black;
        }
    }
}

@include media(x-large) {
    .meganav {
        &-inner {
            padding: $meganav-spacer;
        }

        &-subnav {
            min-width: 300px;
        }

        .full-carousel-item {
            width: calc(38% - 30px);
        }
    }
}

@include media(large) {

    .meganav {
        &-subnav {
            min-width: 250px;
        }
    }
}
