﻿.ace-page {
    #considerDonation {

        #donationCallout {
            margin-right: $spacer-large;

            img {
                margin-bottom: $spacer*2;
            }
        }

        #donationEffect {
            max-width: $max-width-form-ace;

            .cur-order-total {
                display: flex;
                justify-content: space-between;
                margin-bottom: $spacer-primary;
                padding-right: 16px;

                label {
                    pointer-events: none;
                }
            }

            .sug-contrib {
                display: flex;
                justify-content: space-between;

                .label-optional:after {
                    content: none;
                }

                > *:nth-child(1) {
                    margin-right: $spacer-primary/2;
                }
            }

            .suggestedDonation {
                width: 115px;
                margin-top: 0;
                text-align: right;
            }

            .total {
                display: flex;
                justify-content: space-between;
                border-top: 4px solid $black;
                padding-top: $spacer-primary;
                margin-bottom: $spacer-primary;
                padding-right: 16px;

                label {
                    pointer-events: none;
                }
            }
        }

        #donationDecision {
            display: flex;
            align-items: flex-end;

            .textLink {
                margin-left: $spacer-primary;
            }
        }
    }
}


@include media(medium) {
    .ace-page {
        #considerDonation {
            display: block;

            #donationCallout {
                margin-right: 0;
            }

            #donationEffect {
                max-width: 100%;
            }
        }
    }
}

@include media(small) {
    .ace-page {
        #considerDonation {
            #donationDecision {
                display: block;

                .btnStandOut {
                    margin-bottom: $spacer*2;
                }

                .textLink {
                    margin-left: 0;
                }
            }
        }
    }
}
