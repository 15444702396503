﻿/// <reference path="../../main.scss" />
$mebership-aside-width: 440px;
$mebership-aside-width-tablet: 300px;

.membership-aside {
    margin: $spacer-body-responsive;
    width: $mebership-aside-width;


    @include media(large) {
        margin: $spacer-primary;
        width: $mebership-aside-width-tablet;
    }

    @include media(tablet) {
        width: auto;
        margin: 0;
    }

    &-content {
        background: $offWhite;
        padding: $spacer-primary;
    }

    &-description {
        p:last-child {
            margin-bottom: $spacer-rem;
        }
    }

    &-title {
        @extend %h6;
        @extend %heading-accent;
    }


    [aria-hidden=true] {
        display: none;
    }

    label {
        @extend %heading-accent;
        @extend %font-accent-bold;
    }
}
