﻿/// <reference path="../main">
$progress-indicator-size: 32px;
$progress-indicator-size-mobile: 24px;
$progress-checkmark: 18px;
$progress-checkmark-mobile: 14px;

.progress-bar {
    margin-top: $spacer-large;
    display: grid;
    grid-template-columns: repeat(4, minmax(150px, 1fr));
    grid-gap: $spacer;
    max-width: $max-width-content;

    @include media(tablet) {
        grid-template-columns: repeat(2, minmax(150px, 1fr));
    }

    &-step {
        display: flex;
        flex-direction: column;


        &-container {
            display: flex;
            align-items: center;
        }

        .indicator {
            height: $progress-indicator-size;
            width: $progress-indicator-size;
            border-radius: 50%;
            border: 1px solid grey;
            margin-right: $spacer;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;

            @include media(tablet) {
                height: $progress-indicator-size-mobile;
                width: $progress-indicator-size-mobile;
            }
        }

        .track {
            flex-grow: 1;
            height: 4px;
            background: $gray;
        }

        &:last-child {
            .track {
                display: none;
            }
        }
    }
}

// Active State
.progress-bar-step.active {
    color: $primary-color;

    .track {
        background: $primary-color;
    }

    .indicator {
        background: $primary-color;
        border-color: $primary-color;

        &:after {
            content: '';
            width: $progress-checkmark;
            height: $progress-checkmark;
            text-align: center;
            background-image: url("../static/images/svgs/checkmark-light.svg");

            @include media(tablet) {
                height: $progress-checkmark-mobile;
                width: $progress-checkmark-mobile;
            }
        }
    }
}
