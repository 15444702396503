/// <reference path="../../main.scss" />
.otc-listing-article {
    @extend %list-item-reset;
    border-bottom: 1px solid $black;
    padding: 0 0 $spacer-primary;

    & + & {
        margin-top: $spacer-primary;
    }

    &:last-child {
        margin-bottom: $spacer-primary;
    }

    &-content {
        display: grid;
        grid-template-columns: 1fr 25%;
        gap: $spacer-primary;
        width: 100%;

        @include media($otc-main-breakpoint) {
            grid-template-columns: 1fr;
        }

        &-header {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
        }

        &-info {
            display: flex;
            flex-direction: column;
            gap: $spacer;
            align-items: flex-start;
        }

        &-date {
            margin-bottom: 0;
        }
    }

    &-title {
        // Deep sigh. Needed to override theming specificity
        @extend %h5-paragraph-heading;
        @extend %underline-link;
        margin: 0 !important;

        &:hover, &:active {
            color: $otc-orange !important;
        }
    }

    &-image {
        aspect-ratio: 16/9;
        position: relative;

        @include media($otc-main-breakpoint) {
            order: -1;
        }

        img {
            position: absolute;
            object-fit: cover;
            height: 100%;
            width: 100%;
        }
    }
}

.infinite-scroll-component__outerdiv {
    overflow-x: hidden;
}