﻿.ace-page {
    .ticketHistoryHeading > p {
        @extend %h1-page-heading;
    }

    .ticketHistoryPerfHeading > p {
        @extend %h5-paragraph-heading;
    }

    .ticketHistoryPerf {
        margin-top: $spacer;

        + .ticketHistoryPerf {
            margin-top: $spacer*6;
        }
    }

    .ticketHistoryPerfTitle {
        @extend %h6-content-heading;
        margin-bottom: 0;
        margin-top: .625rem;
    }

    .ticketHistoryPerfDate {
        display: block;
    }

    #ticketHistoryDisplay {
        min-width: 50%;

        ul {
            margin-left: 0;

            span { //same as p tag
                line-height: 1.5rem;
            }
        }

        .button {
            margin-top: $spacer-primary;
        }
    }

    .ticketHistoryContainer {
        display: flex;
        flex-direction: column-reverse;

        @include media-min(medium) {
            flex-direction: row;
        }

        .returnsColumn {
            margin-bottom: $spacer-primary;

            p { // edit global p
                margin-bottom: $spacer;
           }
        }
    }
}

.upcoming-performances {
    margin-bottom: 4rem;
}
