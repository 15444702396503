/// <reference path="../../main.scss" />

.otc-card {
    &-image {
        aspect-ratio: 16/9;
        position: relative;

        img {
            position: absolute;
            object-fit: cover;
            height: 100%;
            width: 100%;
        }

        &.has-mobile-image {
            .otc-card-image-desktop {
                @include media(medium) {
                    display: none;
                }
            }

            .otc-card-image-mobile {
                @include media-min(medium) {
                    display: none;
                }
            }
        }
    }

    &-title {
        @extend %h5-paragraph-heading;
        @extend %underline-link;
        margin: 0 !important;

        &:hover, &:active {
            color: $otc-orange !important;
        }
    }

    &-content {
        display: flex;
        flex-direction: column;
        gap: $spacer;
        margin-top: $spacer-md;

        p {
            margin: 0;
            padding: 0;
        }
    }

    &-categories {
        @extend %list-reset;
        display: flex;
        gap: $spacer;
        flex-wrap: wrap;

        &-tag {
            @extend %list-item-reset;

            a {
                @extend %otc-links;
            }
        }
    }

    &-cta {
        margin: $spacer-primary 0;
    }
}
