﻿.callout-quarter {
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;

    &:not([class*="color-"]) {
        background: $offWhite;
        color: $black;
    }


    &-image {
        width: 100%;

        .callout-quarter-text-container {
            flex-grow: 1;
            padding: $spacer-primary $spacer-primary $spacer-body-responsive;

            @include media-min(large) {
                justify-content: flex-start;
            }
        }

        &-container {
            position: relative;
            /*overflow-y: hidden;*/
            .callout-quarter-subheading {
                position: absolute;
                background-color: $offWhite;
                color: $black;
                padding: $spacer*2 $spacer-primary 0;
                left: 0;
                bottom: 0;
                margin-bottom: 0;
                line-height: 1.4rem;
            }
        }
    }

    &:not(&-image) {
        .callout-quarter-heading {
            @extend %h4;
            @extend %font-accent-bold;
        }

        .callout-quarter-subheading {
            @extend %h6-primary;
            text-transform: none;
            font-weight: 400; //book
        }
    }

    &-text-container {
        padding: $spacer-body $spacer-primary;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;

        p:empty {
            display: none;
        }
    }

    &-heading {
        @extend %h5-paragraph-heading;
        margin-bottom: $spacer-primary;
        max-width: 100%;
        width: 100%;
    }

    .button,
    .button-secondary {
        position: absolute;
        bottom: $spacer-primary;
        left: $spacer-primary;
        align-self: flex-start;
        white-space: nowrap;
    }
}

.fiftyfifty-callout-quarter {
    .fiftyfifty-item {
        position: relative;
    }
}

@include media(large) {
    .callout-quarter {
        &-image {
            .callout-quarter-text-container {
                padding-bottom: $spacer-large + $spacer-primary;
            }

            .callout-quarter-heading {
                margin-bottom: $spacer-primary;
            }
        }

        &-text-container {
            padding: $spacer-large $spacer-primary;
        }
    }
}

@include media(small) {

    .callout-quarter {
        display: block;

        &-text-container {
            padding: $spacer-large $spacer*2;
        }

		&-image .callout-quarter-text-container {
            padding: $spacer-primary $spacer*2;
        }

        &:not(&-image) {
            .callout-quarter-text-container {
                > * {
                    &:last-child {
                        margin-bottom: 0;
                    }
                }

                p:empty {
                    display: block;
                    margin-top: -$spacer-primary/2;
                }
            }

            .button,
            .button-secondary {
                margin-top: $spacer-primary;
            }
        }

        &-image {
            &-container {
                .callout-quarter-heading {
                    padding: $spacer*2 $spacer*2 0;
                }

                .callout-quarter-subheading {
                    padding: $spacer*2 $spacer*2 0;
                }
            }
        }

        .button,
        .button-secondary {
            position: relative;
            left: $spacer*2;
            bottom: $spacer-large;
            width: calc(100% - 40px);
        }
    }
}
