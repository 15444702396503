﻿.giftCertificateDesigns {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 15px;
    @extend %list-reset;
    margin: $spacer-md 0;

    @include media(medium-larger) {
        grid-template-columns: repeat(3, 1fr);
    }

    @include media(tablet) {
        grid-template-columns: repeat(2, 1fr);
    }


    .giftCertificateDesignsItem {
        border-bottom: 4px solid transparent;
        padding-bottom: $spacer;
    }
}


.giftCerts {
    .errorBox {
        margin-top: 0;
    }

    .formSection {
        .errorMsg {
            position: absolute;
        }
    }

    .errorMsg[style='visibility: visible;'] {
        position: relative;
    }

    .giftCertificateDesignsItem {
        cursor: pointer;

        p {
            display: none;
        }

        &:before {
            content: none;
        }

        &.selected {
            border-bottom: 4px solid $black;
            padding-bottom: $spacer;
        }
    }

    .owl-nav {
        position: absolute;
        left: calc((100vw - 100%) / -2);
        transform: translateY(-50%);
        top: 50%;
        width: 100vw;
        height: $spacer-primary*2;
        z-index: -10;

        .disabled {
            display: none;
        }

        .owl-prev,
        .owl-next {
            background-image: url('../static/images/svgs/arrow-square-dark.svg');
            position: absolute;
            margin-top: -$spacer/2;

            &:hover {
                background-image: url('../static/images/svgs/arrow-square.svg');
            }
        }

        .owl-prev {
            left: 0;
        }

        .owl-next {
            right: 0;
        }
    }

    #imgPreview {
        width: 1045px; //per mockup, //1045x480
        max-width: 100%;
    }

    .btn {
        margin-top: $spacer-large;
    }
}

.giftCertificateDesigns {
    li + li {
        padding-top: 0;
    }
}

@include media(tablet-larger) {
    .giftCerts {
        .owl-nav {
            display: none;
        }
    }
}
