﻿//This file contains non-layout styles for the desktop sidebar; colors, background pattern, opacity, etc
//Note that this does not include mini calendar or filters - those are in separate partials

#calendar-page {
    .calendar {
        &-filter-col {
            background-color: $black;
            opacity: .95;
            color: $offWhite;

            &:before {
                content: '';
                background-image: url('../static/images/line-pattern-b-w-transparent_16x16.png');
                position: fixed;
                top: 0;
                bottom: 0;
                left: 0;
                z-index: -1;
                height: inherit;
            }
        }
    }
}

@include media(tablet-larger) {
    #calendar-page {
        .calendar {
            &-filter-col {
                opacity: 1;
            }
        }
    }
}
