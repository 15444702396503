﻿/// <reference path="../main">

// This block is highly leveraged from Gallery Shelf
// Below are overrides to functionality in gallery-shelf.scss

.more-events {
    background: $black;
    padding-right: 0 !important;
    padding-top: $spacer-body-responsive;
    padding-bottom: $spacer-body-responsive;
    overflow: hidden;

    &-title {
        @extend %h5;
        text-transform: uppercase;
        margin-bottom: $spacer-rem;
    }


    .btn-arrow {
        top: -$spacer-med * 3.5;
        right: 0;

        @include media(mobile) {
            top: -$spacer-large;
            right: 0;
        }
    }

    &-carousel-container {
        position: relative;
    }
}
