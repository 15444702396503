﻿.fiftyfifty-headingcontent {
    &:only-of-type {
        height: 100%;
    }

    &-heading {
        @extend %h1-page-heading;
        @extend %h2;
        margin-bottom: $spacer-primary;
    }
}
