﻿/// <reference path="../main">

.upcoming-perfs {
    @extend %vertical-block-padding;

    &-header {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    &-grid {
        margin-top: $spacer-primary;
        display: grid;
        grid-template-columns: repeat(3, minmax(0, 1fr));
        gap: $spacer-primary;

        @include media(tablet-larger) {
            grid-template-columns: 1fr;
        }
    }

    &-perf {
        &-title {
            @extend %heading-accent;
        }

        &-content {
            margin-bottom: $spacer-primary;
            display: flex;
            flex-direction: column;
        }

        &-image {
            margin-bottom: $spacer;
        }
    }
}
