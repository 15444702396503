/// <reference path="../main">
$modal-ui-icon-size: 0.75rem;


$_modal-max-width: 64rem;
$_modal-overlay-color: rgba($black, 0.6);
$_modal-background-color: $white;
$_modal-close-top-offset: $spacer-primary;
$_modal-horizontal-spacing: $spacer-primary;
$_modal-vertical-spacing: $spacer-primary;

// STRUCTURE & LAYOUT
.modal {
    &-container {
        &[aria-hidden="true"] {
            visibility: hidden;
            opacity: 0;
            pointer-events: none;
            display: none;
        }

        z-index: 9999;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        height: 100vh;
        opacity: 1;
        display: flex;
        justify-content: center;
    }

    &-overlay {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;

        &:focus {
            outline: none;
            box-shadow: none;
            border: none;
        }

        &::after {
            content: '';
            position: fixed;
            width: 100%;
            height: 100vh;
        }
    }

    &-dialog {
        position: relative;
        width: 100%;
        max-width: $max-width-content;
        margin-left: auto;
        margin-right: auto;
        pointer-events: none;
        top: 25vh;

        @include media(tablet) {
        max-width: 95%;
        }
    }

    &-inner {
        position: relative;
        max-height: 100%;
        overflow: auto;
        height: 50vh;
    }
}

// STYLING
.modal {
    &-container {
        background-color: $_modal-overlay-color;
        transition: 0.3s opacity;
    }

    &-inner {
        padding: $spacer-large;
        background-color: $_modal-background-color;
        pointer-events: auto;

        &-full-bleed {
            padding: 0;
        }
    }
}

// CLOSE BUTTON
.modal {
    &-close {
        top: $spacer-small;
        right: $spacer-small * 2;
        pointer-events: auto;
        position: absolute;
        cursor: pointer;
        z-index: 1000;
    }
}

