﻿$input-text-offset: 35px;

.returns {

    &-container {
        display: flex;

        @include media(medium) {
            flex-wrap: wrap;
        }
    }

    &-page {
        padding-left: $spacer-body;
        padding-right: $spacer-body;

        p {
            margin-bottom: $spacer;
        }

        .errorMsg {
            display: block;
            color: $red;
        }
    }

    &-col {
        margin-bottom: $spacer-primary;
    }
}

.returns-performances {
    margin-left: 0;
    margin-bottom: 0;

    input[type=checkbox] {
        margin-right: $spacer;
    }

    li + li {
        padding-top: $spacer-med;
    }

    .ticketHistoryPerf {
        text-indent: 0;
        padding-bottom: $spacer-med;
        border-bottom: 1px solid $gray;

        &[data-unavailable] {
            .ticketHistoryPerf {
                &-headline, &-title, &-date, &-amount, &-venue, &-seat {
                    opacity: 0.65;
                }

                &-title .h6-content-heading:before {
                    display: none !important;
                }

                &-headline label {
                    margin-left: $input-text-offset;
                }
            }
        }

        &:before {
            display: none;
        }

        &:last-child {
            border-bottom: none;
        }

        &-headline {
            display: flex;
            justify-content: space-between;
            align-content: center;

            label {
                letter-spacing: normal;
                @extend %font-primary-bold;
                margin-bottom: $spacer-small;
            }
        }

        &-amount {
            &-sm {
                display: block;

                @include media-min(small) {
                    display: none;
                }
            }

            &-lg {
                display: none;

                @include media-min(small) {
                    display: block;
                }
            }

            &-sm {
                margin-left: $input-text-offset;
            }
        }

        &-date, &-venue, &-seat {
            margin-left: $input-text-offset;
        }

        &-donation-only {
            margin-top: $spacer;
            padding-left: $input-text-offset;

            &[aria-hidden="true"] {
                display: none;
            }

            &[aria-hidden="false"] {
                display: block;
                color: $red;
            }
        }
    }
}

.returns-detail {
    &-list {
        margin: 0 0 $spacer-primary 15px;

        .radio-container {
            padding-top: 0;
            margin-bottom: $spacer;

            &:before {
                display: none;
            }

            label {
                margin-bottom: $spacer / 2;
                text-indent: 0;
                font-weight: 700;

                &:before {
                    margin-right: $column-gutter-small;
                }
            }
        }
    }

    .checkbox-container-basic {
        margin-bottom: 0;
    }

    .acknowledgement-text p {
        padding-left: $input-text-offset;
        margin-bottom: $spacer * 2;
    }
}

.returns-info {
    width: 100%;
    margin-left: 0;

    @include media-min(medium) {
        width: 60%;
        margin-left: 10%;
    }
}

.returns-detail {
    width: 100%;

    @include media-min(medium) {
        width: 40%;
    }
}

.returns-totals {
    display: flex;
    justify-content: space-between;
    margin: $spacer 0;

    &.exchange {
        color: $red;
        margin-bottom: $spacer-med;

        span {
            &[aria-hidden="true"] {
                display: none;
            }
        }

        span {
            &[aria-hidden="false"] {
                display: block;
                color: $red;
            }
        }

        .waived {
            text-decoration: line-through;
        }
    }

    &.total-credit {
        padding-top: $spacer-med;
        border-top: 3px solid $black;
    }
}
