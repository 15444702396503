﻿// ------------- FOOTER - PAYMENT PATH AKA FUNNEL FOOTER ------------- //
footer.funnel {
    background-color: $black;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: $spacer*6 0;

    .funnel-link {
        margin-bottom: $spacer;
        @extend %nav-item;
        color: $offWhite;
        transition: color .3s, background-color .3s;
        padding: $spacer;

        &:focus,
        &:active,
        &:hover {
            color: $black;
            background-color: $offWhite;
        }
    }

    p {
        margin-bottom: 0;
    }

    @include media(medium) {
        padding: $spacer-primary 0;
    }
}
