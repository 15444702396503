﻿/// <reference path="../../main.scss" />

main { //all body content besides header and footer tags
    > *:not(.full-width),
    .full-width-inner {
        padding-left: $spacer-body;
        padding-right: $spacer-body;
        display: block; //for IE11
    }

    > *:last-child:not(.nospace) {
        margin-bottom: $spacer-body;
    }

    .block + .block {
        margin-top: $spacer-body-responsive;
        margin-bottom: $spacer-body-responsive;
    }

}

@include media(x-large) {
    main {
        > *:not(.full-width),
        .full-width-inner {
            padding-left: $spacer-body-responsive;
            padding-right: $spacer-body-responsive;
        }

        > *:last-child:not(.nospace) {
            margin-bottom: $spacer-body-responsive;
        }
    }
}

@include media(700px) {
    main {
        > *:last-child:not(.nospace) {
            margin-bottom: $spacer-body-responsive/2;
        }

        .block + .block {
            margin-top: $spacer-primary;
            margin-bottom: $spacer-primary;
        }
    }
}

@include media(small) {
    main {
        > *:not(.full-width),
        .full-width-inner {
            padding-left: $spacer*2;
            padding-right: $spacer*2;
        }
    }
}
