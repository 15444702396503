﻿/// <reference path="../../main.scss" />
$_panel-padding: $spacer-md * 2;
$_tab-padding: $spacer-md;
$_tab-selected-color: $red;
$_tab-selected-overline-width: 5px;

.membership {
    margin-top: $spacer-primary;
    margin-bottom: $spacer-body-responsive;
    color: $black;

    .membership-header {
        max-width: none;
        margin-bottom: $spacer-primary;
        @extend %gutter-padding;
        @extend %h3;
        @extend %heading-accent;
    }
}

.membership-tabs {

    &-container {

        @include media-min(large) {
            padding-left: $spacer-body-responsive;
            padding-right: $spacer-body-responsive;
        }
    }

    &-list {
        display: flex;
        margin: 0;
        padding: 0;
        background: $black;
        max-width: none;
        overflow-x: auto;
        position: relative;


        &-decorator {
            height: 5px;
            background: white;
            width: 100%;
            z-index: 6;
            position: absolute;
            overflow: hidden;

        }

        &-item {
            flex: 0 1 auto;
            text-indent: 0;
            width: calc(100%/6);

            @include media(tablet-larger) {
                min-width: 160px;
            }

            &:before {
                content: none;
            }

            & + & {
                padding-top: 0;
            }
        }

        &-tab {
            padding: $spacer-primary $_tab-padding;
            border-top: $_tab-selected-overline-width solid;
            position: relative;
            width: 100%;
            height: 100%;
            min-height: 100%;
            display: flex;
            flex-direction: column;
            text-align: center;
            justify-content: center;
            align-items: center;
            color: $offWhite;
            border: $offWhite;
            @extend %un-button;

            &:hover,
            &:focus,
            &[aria-selected="true"] {
                background: $_tab-selected-color;
                border-color: $_tab-selected-color;
                z-index: 6;
            }

            &-title {
                @extend %navigation-font;
                @extend %h6;
                margin: 0;
            }

            span {
                font-size: $spacer-rem;
                @extend %heading-accent;
                display: block;
            }

            & > * {
                pointer-events: none;
            }
        }
    }

    &-inner {
        background: $lightGray;
        align-items: flex-start;

        @include media(tablet) {
            grid-template-columns: 1fr;
        }
    }


    &-panel {
        display: grid;
        grid-template-columns: 1fr auto;
        padding: $spacer-large;
        gap: $spacer-primary;

        @include media(large) {
            padding: $_panel-padding $spacer-primary;
        }

        @include media(tablet) {
            grid-template-columns: 1fr;
        }

        @include media(small) {
            padding: $spacer-primary $spacer-md;
        }

        &[hidden] {
            display: none;
        }
    }
}

@include dark-theme() {
    .membership-tabs-list {
        background: $offWhite;

        &-decorator {
            background: $black;
        }

        &-tab {
            color: $black;
            border: $black;
        }
    }
}

// Cart releated level message
.orderSubTotal .membership-level-details-message {
    margin-bottom: $spacer-med;
}
