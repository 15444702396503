﻿/// <reference path="../../main.scss" />

.ace-page .register {

    &-container {
        flex-direction: column;
    }

    &-header {
        @extend %h2-section-heading;
    }

    &-subheader {
        @extend %h4-page-subheading;
        @extend %heading-accent;
        display: block;
        margin-bottom: $spacer-primary;
    }

    &-password {
        display: flex;

        @include media(small) {
            flex-direction: column;
        }

        &-container.validation-container {
            max-width: none;
        }

        &-show {
            display: flex;
            align-items: center;
            align-content: center;
            margin-top: -$spacer-rem;


            @include media-min(small) {
                margin: 0 0 0 $spacer-rem;
            }

            & > * {
                // Override page specific nonsense
                min-width: 0 !important;
                @extend %heading-accent;
                font-weight: 700;
            }
        }
    }

    &-update-address .unstyled {
        margin: 0;
    }

    &-input-message {
        margin-top: -$spacer-rem;
    }
}
