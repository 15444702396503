/// <reference path="../main">

.reveal-panel {
    overflow: hidden;
    height: auto;
    width: 100%;
    transition: ($transition * 1.5) ease-in-out height, ($transition * 1.5) ease-in-out margin;
    opacity: 1;

    // Fallback version if the JS is not implemented
    &:not([aria-expanded]) {
        height: auto;
    }

    &.opened {
        height: auto !important;
    }

    &[aria-expanded="false"] {
        height: 0 !important;
        margin: 0 !important;
        opacity: 0;
    }

    &-container {
        width: 100%;
        position: relative;
    }


    [aria-expanded="true"] [data-panel-open-text] {
        display: none;
    }

    [aria-expanded="false"] [data-panel-close-text] {
        display: none;
    }

    [data-panel-open-text],
    [data-panel-close-text] {
        &:nth-child(n):nth-child(n):nth-child(n) { // Override spacing between child elements of btns
            margin-left: 0;
        }
    }
}
