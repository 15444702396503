/// <reference path="../../main.scss" />

// -------------SECONDARY NAV ------------- //
$logo-var: 90px;

header .secondary-nav {
    width: 100%;
    background: $black;
    z-index: 10;
    position: fixed;
    left: 0;
    right: 0;
    transition: all .1s ease-in-out;

    &-inner {
        display: flex;
        justify-content: space-between;
        padding: $spacer $logo-var;
        align-items: flex-start;
        background: $black;
        min-height: calc(var(--nav-clearance) - var(--fixed-header-clearance));

        @include media(tablet-larger) {
            padding: $spacer;
        }
    }

    &-btns {
        display: flex;
        gap: $spacer;
        width: 100%;

        @include media(tablet-larger) {
            justify-content: center;
        }

        .button-outline:hover {
            color: $primary-color;
        }

        .header-nav-utility-item {
            @include media(small) {
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }

    &-search {
        margin: 0;
        min-height: 0;
        border: none;
        height: 100%;
        line-height: normal;
        max-width: none;

        @include media-min(small) {
            min-width: 300px;
        }

        &-container {
            display: block;

            @include media(tablet-larger) {
                display: none;
                position: relative;
                width: 100%;
                background: $black;
            }
        }
        // Navigation icons
        &-trigger {
            cursor: pointer;

            &-active {
                display: none;
            }

            &-inactive {
                display: block;
            }
        }

        &-submit {
            background: $primary-color;
            padding: $spacer;
            border: 2px solid $primary-color;
            cursor: pointer;
            position: absolute;
            right: 0;
            top: 0;
            height: 100%;

            img {
                margin: 0;
            }
        }

        &-form {
            display: flex;
            align-items: center;
            position: relative;
        }
    }
}
// Body Classes
.search-active {
    @include media(tablet-larger) {
        .secondary-nav-search-container,
        .secondary-nav-search-trigger-active {
            display: block;
        }

        .secondary-nav-search-trigger-inactive,
        .secondary-nav-btns {
            display: none;
        }
    }
}
