﻿/// <reference path="../../main.scss" />

%full-cover {
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
}

%gutter-padding {
    padding-left: $spacer-body;
    padding-right: $spacer-body;

    @include media(x-large) {
        padding-left: $spacer-body-responsive;
        padding-right: $spacer-body-responsive;
    }

    @include media(small) {
        padding-right: $spacer*2;
        padding-left: $spacer*2;
    }
}

%vertical-block-padding, .base-block {
    padding-top: $spacer-body-responsive;
    padding-bottom: $spacer-body-responsive;

    @include media(large) {
        padding-bottom: $spacer-primary;
        padding-top: $spacer-primary;
    }

    @include media(small) {
        padding-top: $spacer-md;
        padding-bottom: $spacer-md;
    }
}


%one-third-column, .one-third-column {
    width: 33%;

    @include media(tablet-larger) {
        width: 100%;
    }
}

.flex-grow {
    flex-grow: 1;
}
