﻿.isSafari {
    .callout-half {
        display: block;

        &-text-container {
            display: block;
        }

        img {
            width: 100%;
        }
    }

    .callout-quarter {
        display: block;

        &-image-container {
            + .callout-quarter-text-container {
                display: block;
            }

            .callout-quarter-subheading {
                position: absolute;
            }
        }
    }

    .fiftyfifty-item {
        > .fiftyfifty {
            height: 100%;
        }
    }

    .fiftyfifty-callout-quarter {
        .fiftyfifty-item {
            > .fiftyfifty {
                height: initial;
            }
        }
    }
}

@include media(small) {
    .isSafari {
        .callout-quarter {
            &-image-container {
                + .callout-quarter-text-container {
                    height: initial;
                }
            }

            &-text-container {
                height: initial;
            }
        }
    }
}
