﻿/// <reference path="../main">

.card {
    padding: $spacer-body-responsive 0;
    background: $offWhite;
    color: $black;

    &-container {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: $spacer;


        @include media(medium) {
            grid-template-columns: repeat(2, 1fr);
        }

        @include media(small) {
            grid-template-columns: 1fr
        }
    }

    &-description {
        flex-grow: 1;
    }

    &-item {
        box-shadow: 4px 4px 6px rgba(0, 0, 0, 0.25);
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: $spacer-primary;

        &-featured {
            color: $offWhite;
            background: $black;

            .card-description a {
                @extend %dark-links;
            }
        }
    }

    &-header {
        @extend %h6;
        @extend %heading-accent;
    }

    &-link a {
        &:not(.button) {
            // Override base link styles to prevent card weirdness
            padding-top: 6px;
        }
    }
}

@include dark-theme() {
    .card {
        background: $black;
        color: $offWhite;

        &-item {
            box-shadow: 4px 4px 6px rgba(255, 255, 255, 0.1);

            &-featured {
                background: $offWhite;
                color: $black;

                .card-description a {
                    @extend %light-links;
                }
            }
        }
    }
}
