﻿/// <reference path="../../main.scss" />

.ace-page .login-page {
    .login {
        &-container {
            display: grid;
            grid-template-columns: repeat(2, minmax(auto, 700px));
            grid-gap: $spacer-med;

            @include media(medium) {
                grid-template-columns: 1fr;
            }
        }

        &-form {
            margin: 0;
            padding: 0;

            li {
                span {
                    display: none;
                }
            }
        }

        &-column + .login-column {
            @include media(medium) {
                margin-top: $spacer-primary;
                padding-top: $spacer-primary;
                border-top: 2px solid $black;
            }
        }

        &-password-show {
            margin-top: -$spacer-med;

            label {
                @extend %heading-accent;
                font-weight: 700;
            }
        }

        .rememberMe {
            margin-top: $spacer-med;
        }
    }
}

// Order Summary -- this will be moved to a module soon
.order-summary {
    background: $lightGray;
    padding: $spacer-med;
    display: flex;
    flex-direction: column;
    max-width: $max-width-form-ace;
    margin-bottom: $spacer-large;

    &-header {
        border-bottom: 1px solid $black;
        padding-bottom: $spacer-rem;
        margin-bottom: $spacer-rem;
        @extend %h6;
        @extend %heading-accent;
    }

    &-details {
        display: flex;
        justify-content: space-between;
    }

    &-total {
        @extend %heading-accent;
    }
}
