﻿/// <reference path="../../main.scss" />

$cards-spacer: $spacer-primary;
$cards-vertical-offset: $spacer-body / 2;

.at-home {
    @extend %vertical-block-padding;

    &-cards-container {
        margin-top: $cards-vertical-offset;
        display: flex;
        align-items: flex-start;
        gap: $spacer-large;

        @include media(medium) {
            flex-direction: column;
            gap: $spacer-md;
        }
    }

    &-cards {
        display: flex;
        gap: $cards-spacer;

        @include media(medium) {
            width: 100%;
        }

        @include media(tablet) {
            flex-direction: column;
        }
    }

    &-card {
        position: relative;
        flex: 1 1 auto;
        min-width: 350px;

        @include media(tablet) {
            min-width: 0;
        }

        &-highlight {
            @extend %h6;
            @extend %heading-accent;
            background: $lightGray;
            color: $black;
            width: 100%;
            margin: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            left: 0;
            top: -$cards-vertical-offset;
            height: $cards-vertical-offset;
        }


        &-inner {
            padding: $spacer-med;
            height: 100%;
            display: flex;
            flex-direction: column;
            background: $black;
            color: $white;
       

            p {
                margin: 0 0 $spacer-rem;
            }
            /*.rich-text a {
                color: $offWhite;
                background: $black;
                border-color: $offWhite;

                &:hover, &:focus, &:active {
                    color: $black;
                    background: $offWhite;
                    border-color: $black;
                }
            }*/
        }


        &-price {
            @extend %h5;
            @extend %heading-accent;
        }

        &-cta {
            display: flex;
            align-items: flex-end;
            flex-grow: 1;
            margin-top: $spacer;

            button {
                width: 100%;
            }
        }
    }
}
@include dark-theme() {
    .at-home-card-inner {
        background: $white;
        color: $black;
    }

}
