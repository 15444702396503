﻿@mixin button-helper($_config: $default-button-config) {
    $_foreground: map-get($_config, foreground);
    $_background: map-get($_config, background);
    $_border: map-get($_config, border);
    $_disabled: map-get($_config, disabled);

    background-color: nth($_background, 1);
    color: nth($_foreground, 1);
    border-color: nth($_border, 1);


    &:hover,
    &:focus,
    &:active {
        background-color: nth($_background, 2);
        color: nth($_foreground, 2);
        border-color: nth($_border, 2);
    }

    &[disabled] {
        background-color: nth($_disabled, 1);
        color: nth($_disabled, 2);
        box-shadow: none;

        &:hover {
            background-color: nth($_disabled, 1);
            color: nth($_disabled, 2);
            cursor: not-allowed;
        }
    }
}
