﻿/// <reference path="../../main.scss" />

.season-overview {
  position: relative;
  min-height: 100vh;
  padding-bottom: 5rem;
  padding-top: 5rem;
  padding-left: $spacer-body-responsive;
  padding-right: $spacer-body-responsive;

  @include media(medium) {
    padding: $spacer-primary;
  }

  &-list {
    margin: 0;
    padding: 0 0 $spacer-body-responsive;
    max-width: 100%;

    li {
      list-style-type: none;
      text-indent: 0;

      &:before {
        content: none;
      }
    }

    &-item {
      padding: $spacer-primary 0;
      border-top: 4px solid $black;

      @include media(medium) {
        padding-bottom: $spacer-primary / 2;
      }

      &:last-child {
        border-bottom: 4px solid $black;
      }

      &-title {
        color: $primary-color;
        font-family: $font-accent;
        text-transform: uppercase;
        font-size: 1.785rem;
        line-height: 1.75;
        max-width: none;
        
        @include media(tablet-larger) {
          font-size: 1.35rem;
          line-height: 1.2;
        }
        @include media(small) {
          line-height: 1.1;
          font-size: 1.2rem;
        }
      }
    }
  }

  &-month-title {
    margin-bottom: $spacer-md / 2;
    @extend %navigation-font;
    font-size: 3.25rem;

    @include media(medium) {
      font-size: 1.85rem;
    }
    @include media(small) {
      font-size: 1.575rem;
    }
  }
}

.season-overview-list-item {
    &.venue-theme-orange {
        .season-overview-list-item-title, .full-carousel-item-subheading {
            color: $venue-theme-orange;
        }

        .full-carousel-item-img-date {
            background: $venue-theme-orange;
        }
    }

    &.venue-theme-blue {
        .season-overview-list-item-title, .full-carousel-item-subheading {
            color: $venue-theme-blue;
        }

        .full-carousel-item-img-date {
            background: $venue-theme-blue;
        }
    }

    &.venue-theme-green {
        .season-overview-list-item-title, .full-carousel-item-subheading {
            color: $venue-theme-green;
        }

        .full-carousel-item-img-date {
            background: $venue-theme-green;
        }
    }
}