﻿/// <reference path="../../main.scss" />

.gift-membership {
    &-level {
        padding: $spacer-primary;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        background: $offWhite;
        color: $black;


        &-name, &-price {
            @extend %heading-accent;
            margin: 0;
        }

        &-name {
            @extend %h4;
        }

        &-price {
            @extend %h6;
        }
    }

    form {
        margin-top: $spacer-large;
    }

    .label-required {
        &:after {
            content: '*';
            color: $red;
        }
    }
}
