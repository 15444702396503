﻿/// <reference path="../main">
.toggleContainer {
    position: relative;

    &-trigger {
        cursor: pointer;

        &:after {
            content: '';
            background-image: url('../static/images/svgs/plus.svg');
            position: absolute;
            right: 0;
            font-size: 3rem;
            transition: transform .3s;
            width: $spacer-primary;
            height: $spacer-primary;
            background-size: contain;
            background-repeat: no-repeat;
            top: 0;
            cursor: pointer;
 

            @include media(small) {
                height: $spacer * 2;
                width: $spacer * 2;
            }
        }
    }


    &-panel {
        display: none;
    }

    &.show {
        .toggleContainer-panel {
            display: block;
        }

        .toggleContainer-trigger:after {
            content: '';
            background-image: url('../static/images/svgs/minus.svg');
            transform: none;
            height: $spacer;
            top: 10px;
        }
    }

    &:not(.show) {
        .toggleContainer-trigger:hover {
            &:after {
                transform: rotate(180deg);
            }
        }
    }
}
