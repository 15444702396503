﻿/// <reference path="../../main.scss" />
.supportUsSection .donation {
    .funds,
    .giftOptions {
        margin-top: $spacer-primary;
    }

    .supportUs {
        margin-top: $spacer-large;
    }

    ul {
        margin: 0;

        li {
            text-indent: 0;
            padding-left: 0; //bootstrap override
            + li {
                padding-top: 0;
            }

            &:before {
                content: none;
            }
        }
    }

    .errorMsg {
        position: absolute;
        visibility: hidden;
    }

    .errorMsg[style='visibility: visible;'] {
        position: relative;
    }

    .select-container.letter-required { //required select
        margin-bottom: 0;
    }

    .commemorativeInstruction {
        margin-top: $spacer-large;
    }

    .commemorativeGiftContainer {
        margin-left: $spacer-primary;

        .label-optional,
        .select-container,
        .errorMsg,
        input {
            max-width: 530px; //initial ace input width minus margin left to move this section in
        }
    }
}

.donations {
    &-container {
        background: $purple;
        padding: $spacer-primary;
        margin-bottom: $spacer-large;
        // Value from Figma
        max-width: 875px;

        .donations-inputs-input {
            width: 100%;
            height: 100%;
            min-height: $spacer-large;
            padding: $spacer;
            margin: 0;
            // Override hard defaults
            min-width: 0 !important;
            max-width: none !important;

            @include media(small) {
                height: auto;
            }
        }
    }

    &-buttons {
        display: flex;
        grid-gap: $spacer;

        @include media(small) {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
        }

        &-container {
            display: flex;
            grid-gap: 10px;

            @include media(medium) {
                flex-direction: column;
            }
        }
    }

    &-inputs {
        display: grid;
        grid-gap: $spacer;
        grid-template-columns: 1fr auto;
        align-items: center;

        @include media(small) {
            grid-template-columns: 1fr;
        }
    }
}
