﻿.search-container {
    /////////////////////////////////////SEARCH BAR / BUTTON
    input.gsc-input {
        @extend %input;
        background: #EBECEC !important; //overriding inline google styles
        border: 2px solid $black !important;
        margin-bottom: 0;
        margin-top: 0;
        padding-right: 0 !important;
        padding-left: $spacer/2 !important;

        &:hover,
        &:focus {
            background-color: $offWhite !important; //overriding inline google styles
        }

        &:focus {
            background-color: $offWhite !important;
            box-shadow: none;
            border-color: inherit;
        }

        &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
            color: $black;
            font-size: 1rem !important; //overriding google styles
        }

        &::-moz-placeholder { /* Firefox 19+ */
            color: $black;
            font-size: 1rem !important; //overriding google styles
        }

        &:-ms-input-placeholder { /* IE 10+ */
            color: $black;
            font-size: 1rem !important; //overriding google styles
        }

        &:-moz-placeholder { /* Firefox 18- */
            color: $black;
            font-size: 1rem !important; //overriding google styles
        }
    }

    .gsc-search-box tr {
        display: flex;
    }

    .gsc-input {
        max-width: $max-width-form;
        width: 100%;
        padding-right: 0 !important;
    }

    .cse .gsc-control-cse, .gsc-control-cse,
    .cse form.gsc-search-box, form.gsc-search-box {
        padding: 0;
    }

    .gsib_b {
        display: flex;
        height: 100%;
        align-items: center;
        position: relative;
    }

    .gsst_b {
        position: absolute;
        right: 10px;
        top: 16px;

        span {
            color: $black !important;
        }
    }

    .gsst_a:hover {
        padding: 0 !important;
        background: transparent !important;
    }

    .gsst_a {
        border: none !important;
        padding: 0 !important;
    }

    td.gsc-search-button {
        width: auto;
        padding: 0 !important;

        input[type=button] {
            @extend %button;
            border-radius: 0;
            height: 60px; //match input
            font-size: 1rem; //overriding google
        }
    }

    .gsc-search-button-v2 {
        padding: $spacer !important;
        width: $spacer-large;
        height: 100%;
        margin: 0;

        svg {
            width: 100%;
            height: 100%;
        }
    }
    /////////////////////////////////////RESULTS ITEM
    .gsc-webResult.gsc-result {
        padding-left: 0;
        border-left: 0;
        width: $max-width-content;
        max-width: 100%;
        margin-bottom: $spacer*5;

        &:hover {
            padding-left: 0;
            border-left: 0;
        }
    }

    .gsib_a {
        padding: 0;
    }
    //heading / link
    .gsc-result .gs-title {
        overflow: visible;
        height: auto;
        text-decoration: none;
    }

    .gsc-table-result {
        b {
            transition: color .3s;
            text-decoration: none;
        }

        a.gs-title, //search result title
        a.gs-title > b {
            color: $black !important; //overriding google styles
            @extend %h5-paragraph-heading;
            border-bottom: 0;
            margin-bottom: 0;
            padding-top: 6px;
            height: auto;
            transition: 0s;

            &:active,
            &:focus,
            &:hover,
            &:hover > b {
                color: $offWhite !important; //overriding google styles
            }
        }
    }
    //description text
    .gs-bidi-start-align.gs-snippet {
        font-size: 1rem; //overriding google
        font-family: $font-primary;
        line-height: 1.5em;
        margin-top: $spacer;
        margin-bottom: $spacer;
    }
    //url
    .gs-webResult div.gs-visibleUrl {
        color: $black;
        font-style: italic;
        font-family: $font-primary;
        font-weight: 400; //overriding google
    }
    //image, clear btn
    .gsc-table-cell-thumbnail.gsc-thumbnail,
    .gsc-clear-button {
        display: none;
    }
    /////////////////////////////////////NO RESULTS
    .gs-no-results-result {
        .gs-snippet {
            background-color: transparent;
            border: none;
            @extend %h5-paragraph-heading;
        }
    }
    /////////////////////////////////////PAGINATION
    .gsc-results {
        .gsc-cursor-box {
            padding: 0;

            .gsc-cursor-page {
                color: $black;
                border: none;
                text-decoration: none;
                font-family: $font-primary;
                padding: 0 8px;
                transition: font-weight .3s;

                &:hover {
                    font-weight: 700;
                }
            }

            .gsc-cursor-current-page {
                background: transparent;
                font-weight: 700;
                text-shadow: none;
            }
        }
    }
}

@include media(small) {
    .search-container {
        /////////////////////////////////////SEARCH BAR / BUTTON
        table.gsc-search-box {
     

            td.gsc-input {
                padding-right: 0;
                
            }
        }

        td.gsc-search-button {
            input[type=button] {
                margin-left: 0;
            }
        }
        /////////////////////////////////////RESULTS ITEM
        .gsc-webResult.gsc-result {
            margin-bottom: $spacer-primary;
        }
        /////////////////////////////////////PAGINATION
        .gsc-results {
            .gsc-cursor-box {
                margin-bottom: 0;
            }

            .gsc-cursor {
                display: flex;
                flex-wrap: wrap;
                justify-content: center;

                .gsc-cursor-page {
                    margin: 8px;
                }
            }
        }
    }
}
