// ------------- MEDIA QUERIES ------------- //

//@media (max-width:$x-large-media-query-size)
$x-large-media-query-size: 1600px;

//@media (max-width:$large-max-media-query-size)
$large-max-media-query-size: 1400px;

//@media (max-width:$large-media-query-size)
$large-media-query-size: 1280px;

//@media (max-width:$medium-larger-media-query-size)
$medium-larger-media-query-size: 1130px;

//@media (max-width:$medium-media-query-size)
$medium-media-query-size: 1025px;

//@media (max-width:$tablet-larger-media-query-size)
$tablet-larger-media-query-size: 970px;

//@media (max-width:$tablet-media-query-size)
$tablet-media-query-size: 800px;

//@media (max-width:$small-media-query-size)
$small-media-query-size: 640px;

//@media (max-width:$mobile-larger-media-query-size)
$mobile-larger-media-query-size: 390px;

//@media (max-width:$mobile-media-query-size)
$mobile-media-query-size: 340px;


// ------------- BREAKPOINTS MAP ------------- //

$breakpoints: ( 
    x-large: $x-large-media-query-size,
    large-max : $large-max-media-query-size, 
    large : $large-media-query-size, 
    medium-larger: $medium-larger-media-query-size, 
    medium: $medium-media-query-size, 
    tablet-larger: $tablet-larger-media-query-size, 
    tablet: $tablet-media-query-size, 
    small : $small-media-query-size, 
    mobile-larger : $mobile-larger-media-query-size, 
    mobile : $mobile-media-query-size 
);


// ------------- COLORS ------------- //

//brand book
$offWhite: #FEFEFE;
$black: #000;
$gray: #7A7D81;
$red: #C8102E;
$blue: #005EB8;
$purple: #5C068C;
$orange: #E87722;
$otc-orange: #CE4725;
$yellow: #FFC600;
$aqua: #00A3AD;
$green: #43B02A;
$fuschia: #A20067;

//Venues
$venue-theme-orange: #ca6627;
$venue-theme-green: #6c8a6a;
$venue-theme-blue: #73b8bf;

//validation
$red-validation: #E00000;
$green-validation: #307E1F;


//neutral
$lightGray: #EBECEC;
$mediumGray: #2c2c2c;
$darkGray: #333232;

//defaults 
$default-text: $black;
$secondary-text: $offWhite;

//config
$primary-color: $red;
.primary-color {
    color: $primary-color;
}


// MISC
$transition: 0.3s;
$header-transition: 0.3s;
$card-box-shadow: 4px 4px 6px rgba(0, 0, 0, 0.1);


// ------------- FONTS ------------- //

$font-primary: "Gotham A", "Gotham B", 'Helvetica', 'Arial', sans-serif;
$font-accent: "Gotham XNarrow A", "Gotham XNarrow B", 'Helvetica Neue', 'Arial', sans-serif;

//font weights are 400 and 700 - 'Book' & 'Bold' styles respectively
%font-primary,
.font-primary {
    font-family: $font-primary;
    font-style: normal;
    font-weight: 400;
}

%font-primary-bold,
.font-primary-bold {
    font-family: $font-primary;
    font-style: normal;
    font-weight: 700;
}

%font-accent,
.font-accent {
    font-family: $font-accent;
    font-style: normal;
    font-weight: 400;
}

%font-accent-bold,
.font-accent-bold {
    font-family: $font-accent;
    font-style: normal;
    font-weight: 700;
}

// ------------- WHITESPACE/WIDTH ------------- //

$max-width-content: 800px;
$max-width-form: 560px;
$max-width-form-ace: 460px;

$spacer-body: 120px; //between header and content / footer and content
$spacer-body-responsive: 75px;
$spacer-large: 50px;
$spacer-text-margin: 45px;
$spacer-primary: 30px;
$spacer-md: 20px;
$spacer-med: 20px;
$spacer: 10px;
$spacer-small: 5px;
$spacer-rem: 16px;

$column-gutter-x-small: 10px;
$column-gutter-small: 20px;
$column-gutter: 30px;
$column-gutter-l: 48px;
$column-gutter-xl: 62px;



// ------------- Z-INDEX MAP ------------- //

$zindex: ( z-index-0 : -1, z-index-1 : 10, z-index-2 : 20, z-index-3 : 30, z-index-4 : 40, z-index-5 : 50, z-index-6 : 60, z-index-7 : 70, z-index-8 : 80, z-index-9 : 90 );

/* z-index: map-get($zindex, z-index-#); */


// ------------- CONSTANTS ------------------- //
$desktop-header-height: 90px;
$mobile-header-height: 60px;
$subnav-header-height: 49px;

// Common color config


$gray-lighter: mix($black, $offWhite, 8%);
$gray-light: mix($black, $offWhite, 16%);
$gray-dark: mix($black, $offWhite, 72%);
$gray-medium: mix($black, $offWhite, 48%);
$gray-neutral: mix($black, $offWhite, 30%);

$default-text: $black;
$default-secondary: $gray-dark;
$default-focus-ring: $blue;

// Button config for Primary and Secondary styles, declared so it's easy to merge
$default-button-config: (
        background: ($offWhite, $black),
        foreground: ($black, $offWhite),
        border: ($black, $black),
        disabled: ($gray-medium, $offWhite)
    );

// ------------- CSS VARIABLES ------------------- //
:root {

    // Header defaults, will be overriden by JS
    --nav-clearance: $desktop-header-height + $subnav-header-height;
    --fixed-header-clearance: $desktop-header-height;
}