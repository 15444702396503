/// <reference path="../../main.scss" />

// ------------- HEADER SHARED (across funnel, mobile, desktop) ------------- //
$header-inline-overlap: 6px;
$logo-var: 90px;

header {
    position: relative;
    transition: all $header-transition ease-in-out;


    @include media(small) {
        margin-bottom: 0;
    }

    &:not(.funnel) ~ main {
        display: block;
        flex: 1 0 auto;
        padding-top: var(--nav-clearance);

        & > *:first-child:not(.nospace):not(.ace-page) {
            padding-top: $mobile-header-height; //60px
            @include media(tablet-larger) {
                padding-top: $mobile-header-height*.667; //40px
            }
        }

        & > *:first-child.ace-page .page-heading {
            padding-top: $mobile-header-height;

            @include media(tablet-larger) {
                padding-top: $mobile-header-height*.667; //40px
            }
        }
    }


    .header-logo {
        &-img {
            height: $logo-var;
            min-width: $logo-var;
            max-width: $logo-var;
        }
    }

    .header-nav-utility-item {
        font-size: .9rem;
        color: $offWhite;
        position: relative;

        @include media(small) {
            display: flex;
            justify-content: center;
            align-items: center;
        }

        &:hover {
            img {
                transform: scale(1.2);
                transition: transform .2s;
            }
        }

        img {
            width: $spacer*2;
            height: $spacer*2;
            margin-right: $spacer;
            position: relative;
        }


        &.utility-cart[data-cartcount] {
            &:after {
                content: attr(data-cartcount);
                position: absolute;
                width: $spacer-rem;
                height: $spacer-rem;
                font-size: 12px;
                top: -5px;
                left: $spacer;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: $spacer;
                border: 1px solid $black;
                background: $offWhite;
                color: $black;

                @include media(tablet-larger) {
                    left: $spacer-rem;
                }
            }
        }

        &.utility-cart[data-cartcount="0"] {
            &:after {
                content: none;
            }
        }
    }

    .utility-cart span {
        padding-left: $spacer/2;
    }
}
// ------------- HEADER DESKTOP ------------- //
header {
    .desktop {
        display: flex;
        align-items: flex-end;
        border-bottom: 1px solid $gray;

        .header-nav-utility {
            display: flex;
            justify-content: flex-end;
            align-items: flex-end;
            padding-bottom: $spacer * 1.7;

            @include media(x-large) {
                padding-bottom: $spacer/2;
            }
        }
        // PRIMARY NAV
        .header-nav-main {
            display: flex;
            flex-grow: 1;
            max-width: none;
            margin: 0;
            padding: 0;
            // Offset site UL styles
            position: static;

            .nav-item-li0 {
                // top level nav ITEMS
                text-indent: 0;
                @extend %nav-item;
                color: $offWhite;
                padding-left: 1.25%;
                padding-right: 1.25%;
                padding-bottom: $spacer*1.7;
                transition: color $header-transition, background-color $header-transition;
                display: inline;
                cursor: pointer;
                display: flex;
                align-items: flex-end;

                .nav-item-link {
                    color: $offWhite
                }

                & + .nav-item-li0 {
                    padding-top: 0;
                }


                &.open, &.focused,
                &:hover {
                    background-color: $offWhite;

                    .nav-item-link {
                        color: $black;
                    }
                }

                @include media(1180px) {
                    font-size: .8rem;
                }

                &:before {
                    content: none;
                }

                &:hover,
                &:focus, &.focused {
                    > .meganav {
                        max-height: none;
                        opacity: 1;
                        visibility: visible;
                    }
                }
            }

            @include media(x-large) {
                justify-content: flex-end;
                flex-grow: 0;
                width: 100%;

                .meganav {
                    right: -$spacer*2;
                    left: auto;
                }

                .nav-item-li0 {
                    padding-top: $spacer/2;
                }
            }
        }



        .header-nav-utility {
            display: flex;

            &-item {
                display: flex;
                align-items: center;
                margin-right: $spacer*2;

                img {
                    margin-right: $spacer;
                }
            }
        }
    }
}

@include media(tablet-larger) {

    header:not(.funnel) {
        .desktop {
            display: none;
        }
    }
}
// STICKY HEADER CLASSES
header {
    & > [data-main-header] {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        background-color: $black;
        width: 100%;
        z-index: 11;
        background-color: $black;
    }

    & > [data-sticky-header] {
        top: var(--fixed-header-clearance);
    }


    &.visible {
        margin-bottom: 0;

        > [data-sticky-header] {
            transform: translateY(0);
        }
    }

    &.collapsed > [data-sticky-header] {
        transform: translateY(-100%);
    }

    &.search-open {
        .secondary-nav-search-container {
            display: block;
        }
    }
}
