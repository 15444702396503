﻿/// <reference path="../../main.scss" />
.benefits {
    &-header {
        @extend %h4;
        margin-bottom: $spacer-primary;
    }

    &-title {
        @extend %h5;
        @extend %heading-accent;
    }

    &-group {
        & + & {
            margin-top: $spacer-primary;
        }
    }

    &-benefit {
        display: flex;

        p {
            margin: 0;
        }
    }

    &-description {
        padding: 0;
        color: $black;


        &:before {
            content: '';
            background-image: url('../static/images/svgs/checkmark-dark.svg');
            background-repeat: no-repeat;
            width: 14px;
            height: 14px;
            position: relative;
            display: inline-block;
            margin: 0 10px -1px 0;
        }

        &[data-enabled=false] {
            opacity: 0.5;

            &:before {
                content: '';
                background-image: url('../static/images/svgs/x-error-dark.svg');
                background-repeat: no-repeat;
            }
        }
    }
}
