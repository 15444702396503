﻿/// <reference path="../../main.scss" />

$watch-page-breakpoint: 'tablet-larger';

.watch-video {

    &-container {
        display: flex;
        margin-bottom: $spacer-primary;

        @include media($watch-page-breakpoint) {
            flex-direction: column;
        }
    }

    &-player {
        flex-grow: 1;
        flex: 1 0 auto;
        max-width: calc(75% - #{$spacer-primary});
        margin-right: $spacer-primary;
        position: relative;

        &.bordered {
            border: 1px solid $darkGray;
        }

        @include media($watch-page-breakpoint) {
            max-width: none;
            width: 100%;
        }

        &-wrapper {
            width: 100%;
            padding-bottom: 56.25%;
            position: relative;
            background-size: cover;

            iframe, & > div[id], & > iframe {
                position: absolute;
                top: 0;
                left: 0;
                width: 100% !important;
                height: 100% !important;
            }
        }

        &-overlay {
            width: 100%;
            height: 100%;
            background: rgba($primary-color, 0.75);
            position: absolute;
        }

        &-inner {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            z-index: 2;
            position: relative;
            height: 100%;
            text-align: center;
            width: 100%;
            position: absolute;

            & > *, form > * {
                margin: 0;

                &.spaced {
                    margin-bottom: $spacer-primary;

                    @include media($watch-page-breakpoint) {
                        margin-bottom: $spacer / 2;
                    }
                }
            }


            a {
                color: $offWhite;
                border-color: $offWhite;
            }

            .event-tag {
                background-repeat: no-repeat;
                color: $offWhite;
            }
        }

        &-label {
            @extend %h6;
        }
    }

    &-chat {
        flex: 0 1 25%;

        iframe {
            // Need to override potential embed inline styles
            height: 100% !important;
            min-height: 100%;
            width: 100% !important;
        }

        @include media($watch-page-breakpoint) {
            width: 100%;
            margin: $spacer-primary 0;

            iframe {
                min-height: 400px;
            }
        }
    }
}


.watch-cta-container {
    &-desktop {
        display: block;

        @include media($watch-page-breakpoint) {
            display: none;
            visibility: hidden;
        }
    }

    &-mobile {
        text-align: center;
        margin: 2rem 0;

        @include media-min($watch-page-breakpoint) {
            display: none;
            visibility: hidden;
        }

        button + button {
            margin-top: $spacer;
        }

        button {
            border-color: $offWhite;
            background: transparent;
            color: $offWhite;

            &:hover {
                border-color: $offWhite;
                color: $black;
                background: $offWhite;
            }
        }
    }
}
